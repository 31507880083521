import { IconButton, styled, Typography, Box } from "@mui/material";

namespace S {
  export const IBtnContainer = styled(IconButton, { shouldForwardProp: (prop) => prop !== "isMyTicket" })(
    ({ isMyTicket }: { isMyTicket: boolean }) =>
      ({ theme }) => ({
        background: theme.palette.custom.primary.main,
        maxHeight: "1rem",
        maxWidth: "1rem",
        height: "1rem",
        pointerEvents: "auto",
        fontSize: "12px",
        marginLeft: "10px",
        alignSelf: "center",
        position: "absolute",
        top: "10%",
        right: isMyTicket ? "57%" : "30%",
        "&:hover": {
          backgroundColor: "#1846dd",
        },
        p: {
          lineHeight: "0.5",
          fontSize: "11.8px",
        },
      }),
  );

  export const InfoPopoverContainer = styled(Box)(() => ({
    padding: "0.125rem 0.5rem",
    textAlign: "justify",
    background: "rgba(22, 21, 26, 0.4)",
    border: "1px solid rgba(255,255,255,0.4)",
  }));

  export const PopoverRow = styled(Box)(() => ({
    display: "flex",
    gap: "0.5rem",
    justifyContent: "space-between",
    padding: "6px 5px",
  }));

  export const DrawType = styled(Typography)(() => ({
    display: "flex",
    gap: "0.5rem",
    justifyContent: "space-between",
    lineHeight: "1",
    fontWeight: "600",
    fontSize: "12px",
  }));

  export const OneKDraw = styled(Typography)(() => ({
    background: "linear-gradient(92.48deg, #1967FC 1.43%, #19FCB8 75%)",
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    lineHeight: "1",
    fontWeight: "600",
    fontSize: "12px",
  }));

  export const TenKDraw = styled(OneKDraw)(() => ({
    background: "linear-gradient(268.66deg, #FFE600 5.41%, #24FF00 93.94%)",
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
  }));

  export const HundredKDraw = styled(OneKDraw)(() => ({
    background: "linear-gradient(337.35deg, #EB00FF 7.34%, #E9B80C 91.96%)",
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
  }));
}
export default S;
