import { forwardRef } from "react";
import Input from "../inputs";

interface CustomProps {
  searchHandler: (e: string) => void;
  value: string;
  placeholder?: string;
  getInputProps?: () => React.InputHTMLAttributes<HTMLInputElement>;
}

type Ref = HTMLInputElement | string;

const SearchBar = forwardRef<Ref, CustomProps>(({ searchHandler, value, placeholder = "Search Address", getInputProps }, ref) => {
  return (
    <Input
      value={value}
      readOnly={false}
      placeholder={placeholder}
      onChange={(e) => searchHandler(e)}
      customStyle={{
        height: "2.1875rem",
        fontSize: "0.875rem",
        backgroundColor: "#2D2C31",
        "&.Mui-focused": {
          outline: "0",
          boxShadow: "0 0 0 2px #1967fc",
        },
      }}
      getInputProps={getInputProps}
    />
  );
});
export default SearchBar;
