export const CURRENT_CHAIN_ID: number = Number(process.env.REACT_APP_CURRENT_CHAINID);

export const BACKEND_URL: {
  [key: number]: string;
} = {
  56: `${process.env.REACT_APP_BSC_BACKEND_URL}`,
  80001: `${process.env.REACT_APP_BACKEND_URL}`,
};

//referral path
export const REFERRAL_PATH_NAME = "myreferral";
export const NULL_ADDRESS = "0x0000000000000000000000000000000000000000";

//Button type.
export const PRIMARY = "primary";
export const SECONDARY = "secondary";
export const GRADIENT = "gradient";

//Draw counts
export const ONE_K_DRAW = "ONE_K_DRAW";
export const TEN_K_DRAW = "TEN_K_DRAW";
export const HUNDRED_K_DRAW = "HUNDRED_K_DRAW";
export const ONE_K_DRAW_PROGRESS_COUNT = 25;

//Gradients for progress bar.
export const GRADIENT1 = "gradient1";
export const GRADIENT2 = "gradient2";
export const GRADIENT3 = "gradient3";

//Amounts
export const oneKDrawWinningAmount = 5000;
export const tenKDrawWinningAmount = 10000;
export const hundredKDrawWinningAmount = 200000;

export const ONEK_POOL_SHARE = 90.9;
export const TENK_POOL_SHARE = 181.81;
export const HUNDREDK_POOL_SHARE = 3636.36;

export const joinModalDataObj = {
  0: {
    title: "WITHDRAW",
    btnTxt: "Withdraw",
  },
  1: {
    title: "POOLING",
    btnTxt: "Buy",
  },
};

export const EXPLORER_URL: {
  [key: string | number]: string;
} = {
  80001: `${process.env.REACT_APP_MUMBAI_POLYGON_EXPLORER}`,
  56: `${process.env.REACT_APP_BSC_EXPLORER}`,
};

//Guide
export const participationGuide = [
  { id: "1", label: "Capturing the Winning Moments", link: "" },
  { id: "2", label: "Lifetime Access to YearnFamily NFT", link: "" },
  { id: "3", label: "Instant Earnings Withdrawal", link: "" },
  { id: "4", label: "Claiming Your YEARN Token", link: "" },
];
