import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAccount, useNetwork, useSigner } from "wagmi";
import Web3 from "web3";
import { iercContract } from "../../services/ethereum/contract/iercContract";
import { poolContract } from "../../services/ethereum/contract/poolContract";
import { poolToEarnContract } from "../../services/ethereum/contract/poolToEarnContract";
import { predictionContract } from "../../services/ethereum/contract/predictionContract";
import { lottoContract } from "../../services/ethereum/contract/web3Contract";
import { DrawState, setDrawState } from "../../store/slices/draw";
import { setContractIsReady } from "../../store/slices/user";
import { connect, onAccountChange } from "../../store/slices/wallet.actions";
import { CURRENT_CHAIN_ID } from "../constants";
import Toast from "../widgets/toast";
import { useIsReady } from "./selector";
import { useAppDispatch } from "./state";

let walletAddress: string | null = null;
const useWalletConnection = () => {
  //constructor
  const dispatch = useAppDispatch();

  //constants
  const { isConnected: isWalletConnected, address } = useAccount();
  const { data: signer } = useSigner();
  const { chain } = useNetwork();

  //state values
  const isReady = useSelector(useIsReady);

  //Connect and reconnect
  useEffect(() => {
    if (isWalletConnected) {
      if (isReady) dispatch(connect(`${address}`));
    }
  }, [isWalletConnected, isReady]);

  //On network change
  useEffect(() => {
    if (isWalletConnected) {
      if (CURRENT_CHAIN_ID === 56 && chain?.id !== 56) Toast({ message: "Kindly switch to BNB Smart Chain!" });
      if (CURRENT_CHAIN_ID === 80001 && chain?.id !== 80001) Toast({ message: "Kindly switch to Matic Network!" });
    }
  }, [isWalletConnected, chain]);

  //On account change
  useEffect(() => {
    if (address && isWalletConnected && isReady) {
      dispatch(onAccountChange(`${address}`, Boolean(walletAddress)));
      //setting wallet address after connect
      walletAddress = address;
    } else if (!address) walletAddress = null; //removing wallet address after disconnect

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, isReady]);

  //To setup contracts.
  useEffect(() => {
    const deploy = async () => {
      let lottoDeployed = await lottoContract.setup(signer?.provider);
      let iercDeployed = await iercContract.setup(signer?.provider);
      let poolContractDeployed = await poolContract.setup(signer?.provider);
      let predictDeployed = await predictionContract.setup(signer?.provider);
      if (lottoDeployed && iercDeployed && predictDeployed && poolContractDeployed) {
        dispatch(setContractIsReady(true));
        let draw1kNumber = await lottoContract.getDraw1k();
        let draw10kNumber = await lottoContract.getDraw10k();
        let draw100kNumber = await lottoContract.getDraw100k();
        let drawAmount = await lottoContract.getDrawAmount();
        let drawFee = await lottoContract.getDrawFee();
        let maxTickets = await lottoContract.getMaxTickets();

        let drawstate: DrawState = {
          draw1kNumber: Number(draw1kNumber),
          draw10kNumber: Number(draw10kNumber),
          draw100kNumber: Number(draw100kNumber),
          drawAmount: Number(Web3.utils.fromWei(drawAmount.toString(), "ether")),
          drawFee: Number(Web3.utils.fromWei(drawFee.toString(), "ether")),
          maxTickets: Number(maxTickets),
          isDrawReady: true,
        };
        dispatch(setDrawState(drawstate));
      }
    };
    if (address === undefined || (address && chain?.id === CURRENT_CHAIN_ID)) {
      deploy();
    }
  }, [signer?.provider, chain?.id]);

  useEffect(() => {
    if (isReady && signer && address) poolToEarnContract.subscribeJoinedDraw(signer?.provider);
  }, [isReady, signer?.provider]);
};

export default useWalletConnection;
