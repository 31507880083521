import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserSlicetypes } from "../../utils/types/state";

const initialState: UserSlicetypes.initialState = {
  userDetails: {
    id: "",
    address: "",
    balance: "0",
    isFreeUser: false,
    ticketNumber: "",
    participatedOneKDrawNumber: "0",
  },
  isReady: false,
  enteredDraw: false,
  isAppLoading: false,
  invitedBy: null,
  currentDraw: "0",
  ticketsSold: 0,
  appStatus: "",
  isSomethingWrong: false,
  isListeningEventstream: true,
  walletBalance: 0,
  myEarnings: {
    affiliate: 0,
    winning: 0,
    winnerReferrerBonuses: 0,
    winnerReferralPayouts: 0,
    referralPayouts: 0,
  },
  //pools
  isPoolSucceed: false,
  dashboardPools: {
    isRequesting: false,
    payload: [],
  },
  activePool: "",
  participantsByPool: {},
  completedPoolTickets: {
    isRequesting: false,
    payload: [],
  },
  selectedPool: { poolAddress: "", ticketNumber: "", blockTimestamp: "", userId: "", elitePass: "", drawNumber: 0 },
  selectedMyPool: { poolAddress: "", ticketNumber: "", blockTimestamp: "", userId: "", elitePass: "", drawNumber: 0 },
  myPoolTickets: [],
  ticketHeader: "History",
  winnersByTicketId: {
    isRequesting: false,
    payload: {},
  },
  poolInfo: {
    total: "0",
    activePool: null,
  },
};

// Use the PayloadAction type to declare the contents of `action.payload`
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    onEnteringDraw: (state, action: PayloadAction<UserSlicetypes.EnteringDraw>) => {
      const payload = action.payload;
      if (payload) {
        state.enteredDraw = true;
        state.userDetails = payload.userDetails;
        state.currentDraw = payload.currentDraw;
      } else {
        state.enteredDraw = false;
        state.userDetails.balance = initialState.userDetails.balance;
        state.userDetails.id = initialState.userDetails.id;
      }
    },
    setInvitedBy: (state, action: PayloadAction<UserSlicetypes.InvitedBy>) => {
      state.invitedBy = action.payload;
    },
    updateBalance: (state, action: PayloadAction<string>) => {
      state.userDetails.balance = action.payload;
    },
    updateWalletBalance: (state, action: PayloadAction<number>) => {
      state.walletBalance = action.payload;
    },
    updateUserAddress: (state, action: PayloadAction<string>) => {
      state.userDetails.address = action.payload;
    },
    setIsAppLoading: (state, action: PayloadAction<boolean>) => {
      state.isAppLoading = action.payload;
    },
    onSwitchAccount: (state) => {
      state.myPoolTickets = initialState.myPoolTickets;
      state.selectedMyPool = initialState.selectedMyPool;
    },
    setContractIsReady: (state, action: PayloadAction<boolean>) => {
      state.isReady = action.payload;
    },
    setIsSomethingWrong: (state, action: PayloadAction<boolean>) => {
      state.isSomethingWrong = action.payload;
    },
    setIsListeningEventstream: (state, action) => {
      state.isListeningEventstream = action.payload;
    },
    setCurrentDraw: (state, action: PayloadAction<string>) => {
      state.currentDraw = action.payload;
    },
    setAppStatus: (state, action: PayloadAction<string>) => {
      state.appStatus = action.payload;
    },
    //pools
    setIsPoolSucceed: (state, action: PayloadAction<boolean>) => {
      state.isPoolSucceed = action.payload;
    },
    setIsRequestingDashboardPools: (state, action: PayloadAction<boolean>) => {
      state.dashboardPools.isRequesting = state.dashboardPools.payload ? false : action.payload;
    },
    setDashboardPools: (state, action: PayloadAction<UserSlicetypes.Pool[]>) => {
      state.dashboardPools.isRequesting = false;
      state.dashboardPools.payload = action.payload;
    },
    setIsRequestingParticipants: (state, action: PayloadAction<{ poolAddress: string; isRequesting: boolean }>) => {
      const { poolAddress, isRequesting } = action.payload;
      state.participantsByPool = {
        ...state.participantsByPool,
        [poolAddress]: {
          isRequesting,
          participants: state.participantsByPool[poolAddress]?.participants?.length ? state.participantsByPool[poolAddress].participants : [],
        },
      };
    },
    setParticipantsByPool: (state, action: PayloadAction<{ poolAddress: string; participants: UserSlicetypes.Participants[] }>) => {
      const { poolAddress, participants } = action.payload;
      state.participantsByPool[poolAddress].isRequesting = false;
      state.participantsByPool[poolAddress].participants = participants;
    },
    setIsRequestingCompletedPoolTickets: (state, action: PayloadAction<boolean>) => {
      state.completedPoolTickets.isRequesting = action.payload;
    },
    setCompletedPoolTickets: (state, action: PayloadAction<UserSlicetypes.CompletedPool[]>) => {
      state.completedPoolTickets.isRequesting = false;
      state.completedPoolTickets.payload = action.payload;
      state.selectedPool = action.payload.length ? action.payload[0] : initialState.selectedPool;
    },
    setSelectedPool: (state, action: PayloadAction<UserSlicetypes.CompletedPool | undefined>) => {
      const payload = action.payload ? action.payload : initialState.selectedPool;
      state.selectedPool = payload;
    },
    setSelectedMyPool: (state, action: PayloadAction<UserSlicetypes.CompletedPool | undefined>) => {
      const payload = action.payload ? action.payload : initialState.selectedMyPool;
      state.selectedMyPool = payload;
    },
    setEventData: (state, action: PayloadAction<{ ticketsSold: number; poolInfo: { total: string; activePool: null | string } }>) => {
      const { ticketsSold, poolInfo } = action.payload;
      state.ticketsSold = ticketsSold;
      state.poolInfo = poolInfo;
    },
    setMyPoolTickets: (state, action: PayloadAction<UserSlicetypes.CompletedPool[]>) => {
      state.myPoolTickets = action.payload;
      if (action.payload.length) state.selectedMyPool = action.payload[0];
    },
    setTicketHeading: (state, action: PayloadAction<string>) => {
      state.ticketHeader = action.payload;
    },
    setRequestingWinners: (state, action: PayloadAction<boolean>) => {
      state.winnersByTicketId.isRequesting = action.payload;
    },
    setWinnersByTicketId: (state, action) => {
      state.winnersByTicketId.isRequesting = false;
      state.winnersByTicketId.payload = action.payload;
    },
    onDisconnect: (state) => {
      state.userDetails = initialState.userDetails;
      state.invitedBy = initialState.invitedBy;
      state.enteredDraw = initialState.enteredDraw;
      state.myEarnings = initialState.myEarnings;
      state.walletBalance = initialState.walletBalance;
      state.myPoolTickets = initialState.myPoolTickets;
      state.selectedMyPool = initialState.selectedMyPool;
    },
  },
});

export const {
  onEnteringDraw,
  setInvitedBy,
  updateBalance,
  updateWalletBalance,
  updateUserAddress,
  setIsAppLoading,
  onDisconnect,
  onSwitchAccount,
  setContractIsReady,
  setIsSomethingWrong,
  setIsListeningEventstream,
  setEventData,
  setCurrentDraw,
  setAppStatus,
  //pools
  setIsPoolSucceed,
  setIsRequestingDashboardPools,
  setDashboardPools,
  setIsRequestingParticipants,
  setParticipantsByPool,
  setIsRequestingCompletedPoolTickets,
  setCompletedPoolTickets,
  setSelectedPool,
  setSelectedMyPool,
  setMyPoolTickets,
  setTicketHeading,
  setWinnersByTicketId,
  setRequestingWinners,
} = userSlice.actions;

export const userReducer = userSlice.reducer;
