import { FC } from "react";
import { useSelector } from "react-redux";
import S from "./ticketSold.styled";
import "../../assets/css/flipCounter.css";
import { useIsListeningEventstream, useTicketsSold } from "../../utils/hooks/selector";
import FlipCounter from "../../utils/widgets/counter/FlipCounter";

const TicketSold: FC = () => {
  //local-storage
  const cachedTicketNumber = localStorage.getItem("ticketNumber");

  //state values
  const ticketsSold = useSelector(useTicketsSold);
  const isListeningEventstream = useSelector(useIsListeningEventstream);

  return (
    <S.CardWrapper>
      <S.TicketSold>
        <S.HeaderAlignment>
          {!isListeningEventstream && (
            <S.LiveContainer>
              <S.LiveText>LIVE</S.LiveText>
              <S.CustomSensorsIcon />
            </S.LiveContainer>
          )}
          <S.HeadingText>ELITE PASSES SOLD</S.HeadingText>
        </S.HeaderAlignment>
        <S.FlipCounterWrapper
          className="flip"
          countLength={isListeningEventstream && cachedTicketNumber ? cachedTicketNumber.length : ticketsSold.toString().length}
        >
          <FlipCounter flipNumber={isListeningEventstream && cachedTicketNumber ? cachedTicketNumber : ticketsSold.toString()} />
        </S.FlipCounterWrapper>
      </S.TicketSold>
    </S.CardWrapper>
  );
};

export default TicketSold;
