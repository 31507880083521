import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAccount } from "wagmi";
import S from "./tickets.styled";
import { fetchCompletedPoolTickets, fetchOwnerPools } from "../../../store/slices/pools.actions";
import { setTicketHeading } from "../../../store/slices/user";
import { useCompletedPoolTickets, useDashboardPools, usePoolInfo, useTicketHeader, useUserDetails } from "../../../utils/hooks/selector";
import { useAppDispatch } from "../../../utils/hooks/state";
import { TicketHeaderType } from "../../../utils/types";
import TicketDetails from "./ticket-details";

const Tickets: FC = () => {
  //constructors
  const dispatch = useAppDispatch();

  //state values
  const { address } = useSelector(useUserDetails);
  const completedPoolTickets = useSelector(useCompletedPoolTickets);
  const ticketHeader = useSelector(useTicketHeader);
  const dashboardPools = useSelector(useDashboardPools);
  const poolInfo = useSelector(usePoolInfo);

  const { isConnected: isWalletConnected } = useAccount();

  //Fetching all completed pool tickets
  useEffect(() => {
    dispatch(fetchCompletedPoolTickets());
  }, [dashboardPools, poolInfo.activePool]);

  useEffect(() => {
    if (isWalletConnected && address && Boolean(completedPoolTickets.payload.length)) dispatch(fetchOwnerPools(address));
  }, [isWalletConnected, address, completedPoolTickets.payload.length]);

  return (
    <S.TicketsWrapper>
      <S.TicketHeader>
        <S.HeaderTab
          active={ticketHeader === TicketHeaderType.history}
          disable={false}
          onClick={() => dispatch(setTicketHeading(TicketHeaderType.history))}
        >
          {TicketHeaderType.history}
        </S.HeaderTab>
        <S.HeaderTab
          active={ticketHeader === TicketHeaderType.myElitepass}
          disable={!isWalletConnected}
          onClick={() => (isWalletConnected ? dispatch(setTicketHeading(TicketHeaderType.myElitepass)) : {})}
        >
          {TicketHeaderType.myElitepass}
        </S.HeaderTab>
      </S.TicketHeader>
      <TicketDetails />
    </S.TicketsWrapper>
  );
};

export default Tickets;
