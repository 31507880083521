import { iercContract } from "../../services/ethereum/contract/iercContract";
import { web3 } from "../../services/ethereum/contract/web3";
import { lottoContract } from "../../services/ethereum/contract/web3Contract";
import { NULL_ADDRESS } from "../../utils/constants";
import { getWalletBalance } from "../../utils/helpers";
import { AppDispatch } from "../index";
import { onDisconnect, onEnteringDraw, onSwitchAccount, setIsAppLoading, updateUserAddress, updateWalletBalance, updateBalance } from "./user";
import { getInvitedBy } from "./user.actions";

export const connect = (address: string) => async (dispatch: AppDispatch) => {
  // dispatch(setIsAppLoading(true));
  dispatch(updateUserAddress(address));
  dispatch(
    fetchUser(address, () => {
      // dispatch(setIsAppLoading(false));
    }),
  );
};

const fetchUser = (account: string, onClose?: () => void) => async (dispatch: AppDispatch) => {
  try {
    let participant = await lottoContract.participantByAddress(account);
    let tokenBalance = await iercContract.getBalance(account);
    if (participant.amount === "0" && !participant.is_free_user && participant.addr === NULL_ADDRESS) {
      if (onClose) onClose();
      dispatch(onEnteringDraw());
      dispatch(updateBalance(tokenBalance));
      return;
    }

    let referrer = await lottoContract.participantByAddress(participant.referrer);
    let currentDraw = await lottoContract.currentDraw();
    // dispatch(fetchPredictedDetails(participant.addr, currentDraw));
    let walletBalance = await getWalletBalance(account);
    dispatch(updateWalletBalance(Number(walletBalance)));

    dispatch(
      postConnect(
        {
          id: participant.id,
          address: participant.addr,
          balance: tokenBalance,
          isFreeUser: participant.is_free_user,
          ticketNumber: participant.ticket_number,
          participatedOneKDrawNumber: participant.draw_number,
        },
        currentDraw,
        referrer.id,
      ),
    );
    if (onClose) onClose();
  } catch (e) {
    console.log("Fetching user has failed!", e);
    if (onClose) onClose();
  }
};

export const getCurrentChainId = async () => {
  let currentChainId = await web3.eth.getChainId().then((res) => res);
  return currentChainId;
};

export const onAccountChange = (account: string, isWalletSwitch?: boolean) => async (dispatch: AppDispatch) => {
  isWalletSwitch && dispatch(setIsAppLoading(true));
  dispatch(updateUserAddress(account));
  dispatch(onSwitchAccount());
  dispatch(
    fetchUser(account, () => {
      isWalletSwitch && dispatch(setIsAppLoading(false));
    }),
  );
};

export const disconnectWallet = () => (dispatch: AppDispatch, getState: any) => {
  localStorage.clear();
  dispatch(onDisconnect());
};

const postConnect =
  (
    userDetails: {
      id: string;
      address: string;
      balance: string;
      isFreeUser: boolean;
      ticketNumber: string;
      participatedOneKDrawNumber: string;
    },
    currentDraw: string,
    referrerId?: string,
  ) =>
  (dispatch: AppDispatch) => {
    dispatch(onEnteringDraw({ userDetails, currentDraw }));
    dispatch(getInvitedBy(referrerId));
  };
