import { AppDispatch } from "..";
import { lottoContract } from "../../services/ethereum/contract/web3Contract";
import { getDrawWinnersAddress, getUsersByAddress, getDraw10KWinnersAddress } from "../../services/ethereum/subgraph/query";
import { graphQlClient } from "../../utils/providers";
import { NULL_ADDRESS } from "./../../utils/constants/index";
import { setInvitedBy } from "./user";

export const getOneKDrawWinner = async (drawNumber: string) => {
  try {
    const winner: any = await graphQlClient.request(getDrawWinnersAddress, {
      where: {
        drawNumber,
      },
    });
    return winner.drawWinners;
  } catch (e) {
    console.log("Fetching OneKDrawWinners by drawNumber failed! try after sometime.", e);
    return [];
  }
};

export const getTenKDrawWinner = async (drawNumber: string) => {
  try {
    const winner: any = await graphQlClient.request(getDraw10KWinnersAddress, {
      where: { draw10KNumber: drawNumber },
    });
    return winner.draw10KWinners;
  } catch (e) {
    console.log("Fetching OneKDrawWinners by drawNumber failed! try after sometime.", e);
    return [];
  }
};

export const getOneKDrawWinnerDetails = async (drawNumber: string) => {
  let drawWinner = await getOneKDrawWinner(drawNumber);
  let winner = {
    userId: "",
    drawNumber: 0,
    ticketNumber: "",
  };
  if (drawWinner.length) {
    winner = {
      userId: drawWinner[0].userId,
      drawNumber: drawWinner[0].drawNumber,
      ticketNumber: drawWinner[0].ticketNumber,
    };
  }
  return winner;
};

export const getTenKDrawWinnerDetails = async (drawNumber: string) => {
  let drawWinner = await getTenKDrawWinner(drawNumber);
  let winner = {
    userId: "",
    drawNumber: 0,
    ticketNumber: "",
  };

  if (drawWinner.length) {
    winner = {
      userId: drawWinner[0].userId,
      drawNumber: drawWinner[0].draw10KNumber,
      ticketNumber: drawWinner[0].ticketNumber,
    };
  }
  return winner;
};

export const getInvitedBy = (referrerId: string | undefined) => async (dispatch: AppDispatch, getState: any) => {
  const referrer = getState().user.invitedBy;
  try {
    let invitedBy = null;

    if (referrerId) {
      let participant = await lottoContract.participantById(referrerId);
      if (participant.addr !== NULL_ADDRESS && participant.id !== "0") {
        invitedBy = {
          referrerId: participant.id,
          address: participant.addr,
        };
      }
    }
    if (referrer?.referrerId) {
      let participant = await lottoContract.participantById(referrer.referrerId);
      if (participant.addr !== NULL_ADDRESS && participant.id !== "0") {
        invitedBy = {
          referrerId: participant.id,
          address: participant.addr,
        };
      }
    }
    dispatch(setInvitedBy(invitedBy));
  } catch (e) {
    console.log(`Fetching invitedby details for the user has failed! try after sometime.`);
  }
};

export const getReferrerIdByAddress = async (address: string) => {
  const referrer: any = await graphQlClient.request(getUsersByAddress, {
    where: {
      addr: address,
    },
  });
  const { userId } = referrer.newDeposits[0];
  return userId;
};
