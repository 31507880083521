import { AppDispatch, RootState } from "..";
import { iercContract } from "../../services/ethereum/contract/iercContract";
import { poolContract } from "../../services/ethereum/contract/poolContract";
import { poolToEarnContract } from "../../services/ethereum/contract/poolToEarnContract";
import {
  getOwners,
  getPoolByAddress,
  poolCreated,
  getDeposit,
  getAllCompletedPoolAddress,
  getMultipleDrawWinners,
} from "../../services/ethereum/subgraph/query";
import { ONE_K_DRAW_PROGRESS_COUNT, HUNDRED_K_DRAW, ONE_K_DRAW, TEN_K_DRAW } from "../../utils/constants";
import { convertWeiToEth, getWalletBalance, getWholeNumber, verifyOwners } from "../../utils/helpers";
import { poolClient } from "../../utils/providers";
import { ApiTypes } from "../../utils/types/query";
import Toast from "../../utils/widgets/toast";
import { getOwnerPools } from "./../../services/ethereum/subgraph/query";
import { getChecksumAddress } from "./../../utils/helpers/index";
import { graphQlClient } from "./../../utils/providers/index";
import {
  setCompletedPoolTickets,
  setDashboardPools,
  setIsRequestingCompletedPoolTickets,
  setIsRequestingDashboardPools,
  setIsRequestingParticipants,
  setMyPoolTickets,
  setParticipantsByPool,
  setRequestingWinners,
  setWinnersByTicketId,
} from "./user";

export const getCreatedPools = async () => {
  try {
    const response: ApiTypes.PoolsCreatedResponse = await poolClient.request(poolCreated);
    return response.poolCreateds;
  } catch (e) {
    console.log("Fetching created pools failed");
    return [];
  }
};

export const getDashboardPools = () => async (dispatch: AppDispatch) => {
  dispatch(setIsRequestingDashboardPools(true));
  try {
    const poolsCreated = await getCreatedPools();
    if (poolsCreated.length) {
      const dashboardPools = [];
      for (const pool of poolsCreated) {
        const poolDetail: any = await poolClient.request(getPoolByAddress, {
          where: {
            id: pool.poolAddress,
          },
        });

        const dashboardPool = {
          ...pool,
          ...(poolDetail.pools.length && poolDetail.pools[0]),
          ticketNumber: "",
        };
        if (dashboardPool.joinedDraw) {
          const depositResponse: any = await graphQlClient.request(getDeposit, { where: { addr: pool.poolAddress } });
          dashboardPool.ticketNumber = depositResponse.newDeposits[0]?.ticketNumber;
        }
        dashboardPools.push(dashboardPool);
      }
      dispatch(setDashboardPools(dashboardPools));
    } else dispatch(setIsRequestingDashboardPools(false));
  } catch (e) {
    console.log("Fetching dashboard pools failed");
    dispatch(setIsRequestingDashboardPools(false));
  }
};

export const fetchParticipantsByPool = (poolAddress: string) => async (dispatch: AppDispatch) => {
  dispatch(setIsRequestingParticipants({ poolAddress, isRequesting: true }));
  try {
    const participantsResponse: any = await poolClient.request(getOwners, {
      where: {
        pools_: {
          pool_contains: poolAddress,
        },
      },
      poolsWhere2: {
        pool_contains: poolAddress,
        total_not: "0",
      },
    });

    const owners = participantsResponse.owners.map((o: any) => o.pools).flat();
    dispatch(setParticipantsByPool({ poolAddress, participants: owners }));
  } catch (e) {
    console.log("Fetching participants by pool has failed");
    dispatch(setIsRequestingParticipants({ poolAddress, isRequesting: false }));
  }
};

export const joinPool =
  (amount: number, library: any, isMax: boolean, resetLoading: () => void, onSuccess: () => void) => async (dispatch: AppDispatch, getState: any) => {
    const user = getState().user;
    let account = user.userDetails.address;
    const isOwner = await verifyOwners(account);
    if (isOwner) Toast({ message: "Restricted account! try to use different account" });
    else {
      let activePoolAddress = await poolContract.getActivePool();
      let walletBalance = await getWalletBalance(`${account}`);
      let tokenBalance = await iercContract.getBalance(`${account}`);
      if (isMax) {
        if (Number(tokenBalance) < Number(convertWeiToEth(amount.toString()))) {
          resetLoading();
          Toast({ message: "Your token balance is insufficient! Kindly recharge your token to continue.", type: "error" });
          return;
        }
      } else {
        if (Number(tokenBalance) < amount) {
          resetLoading();
          Toast({ message: "Your token balance is insufficient! Kindly recharge your token to continue.", type: "error" });
          return;
        }
      }
      if (Number(walletBalance) < 0.001) {
        resetLoading();
        Toast({ message: "You don't have enough gas fee! Kindly recharge your wallet to continue.", type: "error" });
        return;
      }
      let allowance = await iercContract.getAllowance(`${account}`, activePoolAddress);
      if (Number(allowance) < amount) {
        iercContract.approve(
          `${account}`,
          activePoolAddress,
          () => resetLoading(),
          () => {
            poolToEarnContract.deposit(
              amount.toString(),
              `${account}`,
              activePoolAddress,
              library,
              isMax,
              () => resetLoading(),
              () => onSuccess(),
            );
          },
          "55000000000000000000",
        );
      } else
        poolToEarnContract.deposit(
          amount.toString(),
          `${account}`,
          activePoolAddress,
          library,
          isMax,
          () => resetLoading(),
          () => onSuccess(),
        );
    }
  };

export const withdrawFromPool =
  (amount: number, library: any, isMax: boolean, resetLoading: () => void, onSuccess: () => void) => async (dispatch: AppDispatch, getState: any) => {
    const user = getState().user;
    let account = user.userDetails.address;
    const isOwner = await verifyOwners(account);
    if (isOwner) Toast({ message: "Restricted account! try to use different account" });
    else {
      let activePoolAddress = await poolContract.getActivePool();

      poolToEarnContract.withdrawAmount(
        amount.toString(),
        `${account}`,
        activePoolAddress,
        library,
        isMax,
        () => resetLoading(),
        () => onSuccess(),
      );
    }
  };

export const fetchCompletedPoolTickets = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  dispatch(setIsRequestingCompletedPoolTickets(true));
  const participantsByPool = getState().user.participantsByPool;

  try {
    const completedPools: any = await poolClient.request(getAllCompletedPoolAddress, {
      where: {
        joinedDraw: true,
      },
    });
    if (completedPools.pools.length) {
      let filterInput = completedPools.pools.map((pool: any) => pool.id);
      const deposits: any = await graphQlClient.request(getDeposit, { where: { addr_in: filterInput } });
      const completedPoolTickets = [];
      for (const pool of deposits.newDeposits) {
        const ticket = {
          ticketNumber: pool.ticketNumber,
          poolAddress: pool.addr,
          blockTimestamp: pool.blockTimestamp,
          userId: pool.userId,
          elitePass: `YT${getWholeNumber(pool.ticketNumber)}`,
          drawNumber: pool.drawNumber,
        };
        completedPoolTickets.push(ticket);
      }
      const sortedPoolTickets = completedPoolTickets.sort((a, b) => b.blockTimestamp - a.blockTimestamp);
      dispatch(setCompletedPoolTickets(sortedPoolTickets));
      if (!participantsByPool[sortedPoolTickets[0]?.poolAddress]) dispatch(fetchParticipantsByPool(sortedPoolTickets[0]?.poolAddress));
    } else dispatch(setIsRequestingCompletedPoolTickets(false));
  } catch (e) {
    console.log("Fetching completed pool passes has failed.");
    dispatch(setIsRequestingCompletedPoolTickets(false));
  }
};

export const fetchOwnerPools = (owner: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const completedPoolTickets = getState().user.completedPoolTickets.payload;
  const participantsByPool = getState().user.participantsByPool;
  try {
    const ownerPoolResponse: any = await poolClient.request(getOwnerPools, {
      where: {
        owner: owner.toLowerCase(),
        total_not: "0",
      },
    });
    if (ownerPoolResponse.ownerPools.length) {
      const ownerPoolAddresses = ownerPoolResponse.ownerPools.map((op: ApiTypes.OwnerPoolResponse) => getChecksumAddress(op.pool.id));
      const myPoolTickets = completedPoolTickets.filter((cp) => ownerPoolAddresses.includes(getChecksumAddress(cp.poolAddress)));
      if (myPoolTickets.length) {
        dispatch(setMyPoolTickets(myPoolTickets));
        if (!participantsByPool[myPoolTickets[0].poolAddress]) dispatch(fetchParticipantsByPool(myPoolTickets[0].poolAddress));
      }
    }
  } catch (e) {
    console.log("Fetching owner pools failed.");
  }
};

export const fetchWinners = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  dispatch(setRequestingWinners(true));
  const completedPoolTickets = getState().user.completedPoolTickets.payload;
  const currentDraw = +getState().user.currentDraw;
  const filteredCompletedTickets = completedPoolTickets.filter((p) => p.drawNumber !== currentDraw);
  let winnerData: any = {};
  for (let i = 0; i < filteredCompletedTickets.length; i++) {
    winnerData = {
      ...winnerData,
      [filteredCompletedTickets[i].ticketNumber]: {
        [ONE_K_DRAW]: false,
        [TEN_K_DRAW]: false,
        [HUNDRED_K_DRAW]: false,
      },
    };
  }
  let filterInput = filteredCompletedTickets.map((pool) => Number(pool.ticketNumber));
  const multiWinners: any = await graphQlClient.request(getMultipleDrawWinners, {
    where: {
      ticketNumber_in: filterInput,
    },
  });
  if (multiWinners.drawWinners.length) {
    multiWinners.drawWinners.forEach((winner: any) => {
      winnerData[winner.ticketNumber].ONE_K_DRAW = true;
    });
  }
  if (multiWinners.draw10KWinners.length) {
    multiWinners.draw10KWinners.forEach((winner: any) => {
      winnerData[winner.ticketNumber].TEN_K_DRAW = true;
    });
  }
  if (multiWinners.draw100KWinners.length) {
    multiWinners.draw100KWinners.forEach((winner: any) => {
      winnerData[winner.ticketNumber].HUNDRED_K_DRAW = true;
    });
  }
  dispatch(setWinnersByTicketId(winnerData));
};

export const verifyTicketInDraw = (ticketNumber: string, currentDraw: string) => {
  let ticketInOneKDraw =
    +ticketNumber > (Boolean(+currentDraw) ? +currentDraw : 1) * ONE_K_DRAW_PROGRESS_COUNT - ONE_K_DRAW_PROGRESS_COUNT &&
    +ticketNumber < (Boolean(+currentDraw) ? +currentDraw : 1) * ONE_K_DRAW_PROGRESS_COUNT + 1;

  return ticketInOneKDraw;
};
