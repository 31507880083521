import { CSSProperties, FC } from "react";
import { S } from "./loader.styled";

const DotLoader: FC<{ customStyle?: CSSProperties }> = ({ customStyle }) => {
  return (
    <S.DotLoader customStyle={customStyle}>
      <span></span>
      <span></span>
      <span></span>
    </S.DotLoader>
  );
};

export default DotLoader;
