import { FC } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import Layout from "../components/layout";
import SomethingWrong from "../components/something-wrong";
import { useIsSomethingWrong } from "../utils/hooks/selector";
import { ToastContainer } from "../utils/widgets/toast";

const AppRouters: FC = () => {
  //state values
  const isSomethingWrong = useSelector(useIsSomethingWrong);

  //TODO: Use Suspence here
  return (
    <Routes>
      <Route
        path={"*"}
        element={
          isSomethingWrong ? (
            <SomethingWrong />
          ) : (
            <>
              <Layout />
              <ToastContainer newestOnTop hideProgressBar={true} pauseOnFocusLoss={false} />
            </>
          )
        }
      />
    </Routes>
  );
};

export default AppRouters;
