import { FC, useState } from "react";
import S from "./pool-participants-modal.styled";
import SEARCH_ICON from "../../../assets/images/search.svg";
import { UserSlicetypes } from "../../../utils/types/state";
import { CustomModal, ModalBody, ModalFooter, ModalHeader } from "../../../utils/widgets/custom-modal";
import SearchBar from "../../../utils/widgets/search-bar";
import PoolParticipantsModalBody from "./pool-participants-modal-body";

type PoolParticipantsModalType = {
  open: boolean;
  handleClose: () => void;
  ticketId?: string;
  poolParticipants: UserSlicetypes.Participants[] | [] | undefined;
};

const PoolParticipantsModal: FC<PoolParticipantsModalType> = ({ open, handleClose, ticketId, poolParticipants }) => {
  //state values
  const [searchValue, setSearchValue] = useState("");

  //functions
  const handleSearchFilter = (e: string) => {
    setSearchValue(e);
  };
  return (
    <CustomModal open={open} onClose={handleClose}>
      <ModalHeader handleClose={handleClose}>
        <S.PoolParticipantsModalHeader>
          <S.HeaderLeftContainer>
            <S.HeaderText>
              Participants
              <S.HeaderTextUnderLine />
            </S.HeaderText>
          </S.HeaderLeftContainer>
          <S.HeaderRightContainer>
            {ticketId && (
              <S.TicketTextBox>
                <S.TicketText>ELITE PASS : </S.TicketText>&nbsp;&nbsp;
                <S.GradientText>{ticketId}</S.GradientText>
              </S.TicketTextBox>
            )}
            <S.SearchContainer>
              <S.SearchIcon src={SEARCH_ICON} alt="search-icon" />
              <SearchBar placeholder="Search Address" searchHandler={handleSearchFilter} value={searchValue} />
            </S.SearchContainer>
          </S.HeaderRightContainer>
        </S.PoolParticipantsModalHeader>
      </ModalHeader>
      <ModalBody>
        <PoolParticipantsModalBody searchValue={searchValue} participants={poolParticipants} />
      </ModalBody>
      <ModalFooter>
        <S.PoolParticipantsModalFooter></S.PoolParticipantsModalFooter>
      </ModalFooter>
    </CustomModal>
  );
};
export default PoolParticipantsModal;
