import { Box, Stack, styled, Typography } from "@mui/material";


namespace S {
  export const PoolCardWrapper = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    maxWidth: "565px",
    borderRadius: "50px",
    overflow: "hidden",
    backgroundColor: theme.palette.custom.secondary.secondary_3,
    boxShadow: "-10px 0px 4px 0px #00000033, 10px 0px 4px 0px #00000033",
  }));

  export const PoolCardHeader = styled(Stack, {
    shouldForwardProp: (prop) => prop !== "type",
  })(({ type }: { type?: string }) => ({ theme }) => ({
    boxSizing: "border-box",
    width: "100%",
    minHeight: "60px",
    maxHeight: "60px",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `0 ${theme.spacing(10)}`,
    background: type === "Live" ? "linear-gradient(92.48deg, #1967FC 1.43%, #19FCB8 75%)" : theme.palette.custom.secondary.secondary_2,
  }));

  export const StatusContainer = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    gap: "5px",
  }));

  export const CustomPigIcon = styled("img")(() => ({
    height: "40px",
    width: "42.04px",
  }));

  export const CustomLiveIcon = styled("img")(() => ({
    height: "33px",
    width: "33px",
  }));

  export const CurrentStatusText = styled(Typography)(({ theme }) => ({
    fontSize: "22px",
    fontWeight: "700",
    color: theme.palette.custom.secondary.secondary_1,
  }));

  export const PoolCardBody = styled(Stack, {
    shouldForwardProp: (prop) => prop !== "type",
  })(({ type }: { type?: string }) => ({ theme }) => ({
    boxSizing: "border-box",
    width: "100%",
    minHeight: "402px",
    gap: theme.spacing(4),
    padding: theme.spacing(8),
    border: type === "Live" ? "2px solid #4fb0d9" : "",
    borderRadius: "0 0 50px 50px",
    borderTop: "0",
    justifyContent: "space-between",
    position: "relative",
    ":before": {
      zIndex: "1",
      display: type === "Waiting" ? "none" : "",
      content: '""',
      width: "1.4375rem",
      height: "1.3125rem",
      borderRadius: "50%",
      backgroundColor: theme.palette.custom.secondary.secondary_3,
      transform: "translate(-50%,-50%)",
      position: "absolute",
      top: "260px",
      left: "44px",
    },
    ":after": {
      content: '""',
      display: type === "Waiting" ? "none" : "",
      width: "1.4375rem",
      height: "1.3125rem",
      borderRadius: "50%",
      backgroundColor: theme.palette.custom.secondary.secondary_3,
      position: "absolute",
      transform: "translate(50%,-50%)",
      top: "260px",
      right: "44px",
    },

    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      minHeight: "30.25rem",
      ":before": {
        top: "255px",
        left: "24.5px",
      },
      ":after": {
        top: "255px",
        right: "24.5px",
      },
    },
  }));

  export const PoolTitle = styled(Box)(() => ({
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    lineHeight: "1",
  }));

  export const TitleText = styled(Box)(({ theme }) => ({
    fontSize: "26px",
    fontWeight: "600",
    color: theme.palette.custom.secondary.secondary_1,
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  }));

  export const HeadingGradientText = styled(Typography)(() => ({
    fontSize: "0.875rem",
    fontWeight: "500",
    background: " linear-gradient(92.48deg, #1967FC 1.43%, #19FCB8 75%)",
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    backgroundSize: "185% auto",
    animation: "shine 3.5s linear infinite reverse",
    "@keyframes shine": {
      to: {
        backgroundPosition: "200% center",
      },
    },
  }));

  export const CustomWaitingIconContainer = styled(Box)(() => ({
    width: "189.32px",
    height: "200px",
    alignSelf: "center",
    margin: "auto 0",
    position: "relative",
  }));

  export const CustomWaitingIcon = styled("img")(() => ({
    width: "100%",
    height: "100%",
  }));

  export const CustomSandIconContainer = styled(Box)(() => ({
    width: "80px",
    height: "auto",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  export const CustomSandTimeIcon = styled("img")(() => ({
    transform: "rotate(0deg)",
    width: "80px",
    height: "auto",
    animation: `rotateSandTime 2s ease-in-out infinite`,
    "@keyframes rotateSandTime": {
      "0%": {
        transfrom: "rotate(0deg)",
      },
      "70%": {
        transform: "rotate(360deg)",
      },
      "100%": {
        transform: "rotate(360deg)",
      },
    },
  }));
}

export default S;