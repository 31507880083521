import { Stack, styled, Typography } from "@mui/material";

namespace S {
  export const TicketIdContainer = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    minWidth: "200px",
    maxWidth: "200px",
    minHeight: "100%",
    maxHeight: "100%",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
      maxWidth: "100%",
      minHeight: "fit-content",
      maxHeight: "fit-content",
    },
  }));

  export const TicketIdHeader = styled(Stack)(({ theme }) => ({
    width: "100%",
    minHeight: "71px",
    maxHeight: "71px",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      gap: theme.spacing(0.5),
    },
  }));

  export const TicketIdHeaderText = styled(Typography)(({ theme }) => ({
    fontSize: "14px",
    color: "rgba(255, 255, 255, 0.4)",
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      whiteSpace: "nowrap",
    },
  }));

  export const SearchIcon = styled("img")({
    width: "1.2rem",
    height: "1.2rem",
  });

  export const SearchContainer = styled(Stack)({
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "0.5rem",
    ".MuiInput-root": {
      width: "80%",
    },
  });

  export const TicketIdContent = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    width: "100%",
    minHeight: "279px",
    maxHeight: "279px",
    alignItems: "center",
    padding: "15px",
    border: `1px solid ${theme.palette.custom.strokes.primary}`,
    backgroundColor: "rgba(22, 21, 26, 0.14)",
    overflow: "hidden",
  }));

  export const LoaderContainer = styled("span")(() => ({
    margin: "auto",
  }));

  export const TicketIdInnerContent = styled(Stack)(() => ({
    boxSizing: "border-box",
    minWidth: "100%",
    maxWidth: "100%",
    minHeight: "100%",
    maxHeight: "100%",
    alignItems: "center",
    overflowY: "auto",
    "&::-webkit-scrollbar-track-piece:start": {
      backgroundColor: "#3E3E3E",
      marginTop: "10px",
    },

    "&::-webkit-scrollbar-track-piece:end": {
      backgroundColor: "#3E3E3E",
      marginBottom: "10px",
    },
  }));

  export const TicketNo = styled(Stack, {
    shouldForwardProp: (prop) => prop !== "yourTicket",
  })(({ yourTicket }: { yourTicket: Boolean }) => ({ theme }) => ({
    boxSizing: "border-box",
    fontSize: "0.875rem",
    paddingLeft: "10px",
    fontWeight: "600",
    width: "100%",
    minHeight: "40px",
    justifyContent: "center",
    cursor: "pointer",
    letterSpacing: "0.0625rem",
    color: yourTicket ? theme.palette.custom.primary.main : theme.palette.custom.secondary.secondary_1,
  }));
}

export default S;
