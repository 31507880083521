import { Box, IconButton, Stack, styled, Typography } from "@mui/material";

namespace S {
  export const GradientTicketLoaderWrapper = styled(Box)(() => ({
    position: "absolute",
    top: "50%",
    left: "45%",
  }));

  export const NoDataText = styled(Typography)(({ theme }) => ({
    fontSize: "1.5rem",
    position: "absolute",
    top: "50%",
    left: "40%",
    color: theme.palette.custom.secondary.secondary_1,
    fontWeight: 600,
  }));

  export const GradientTicketContainer = styled(Stack)(() => ({
    boxSizing: "border-box",
    minHeight: "265px",
    borderRadius: "8px",
    zIndex: "2",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    overflow: "hidden",
  }));

  export const TopContainer = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    justifyContent: "space-between",
    minHeight: "155px",
    maxHeight: "155px",
    width: "100%",
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    backgroundColor: theme.palette.custom.secondary.secondary_3,
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  }));

  export const PoolProgressContainer = styled(Stack)(({ theme }) => ({
    gap: theme.spacing(2.5),
    width: "100%",
  }));

  export const PoolProgressRow = styled(Stack)(() => ({
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
  }));

  export const ProgressText = styled(Typography)(({ theme }) => ({
    fontSize: "14px",
    fontWeight: "600",
    color: theme.palette.custom.secondary.secondary_1,
  }));

  export const PoolProgress = styled(Stack)(() => ({
    width: "100%",
    height: "10px",
    borderRadius: "30px",
    background: "#3E3E3E",
  }));

  export const ProgressBar = styled(Stack, {
    shouldForwardProp: (prop) => prop !== "progress",
  })(({ progress }: { progress: number }) => () => ({
    width: `${progress}%`,
    height: "100%",
    borderRadius: "30px",
    background: "linear-gradient(92.48deg, #1967FC 1.43%, #19FCB8 75%)",
  }));

  export const TicketInfo = styled(Stack)(() => ({
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
  }));

  export const InfoColumn = styled(Stack)(() => ({
    justifyContent: "flex-start",
    gap: "0.6rem",
  }));

  export const ParticipantsImg = styled("img")(({ theme }) => ({
    height: "37px",
    width: "37px",
    marginRight: "5px",
    [theme.breakpoints.down("sm")]: {
      height: "25px",
      width: "25px",
    },
  }));

  export const InfoText = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "type",
  })(({ type }: { type: string }) => ({ theme }) => ({
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "1",
    color: type === "Over" ? theme.palette.custom.secondary.secondary_2 : theme.palette.custom.secondary.secondary_1,
  }));

  export const IBtnContainer = styled(IconButton)(({ theme }) => ({
    background: theme.palette.custom.primary.main,
    maxHeight: "1rem",
    maxWidth: "1rem",
    height: "1rem",
    pointerEvents: "auto",
    fontSize: "12px",
    marginLeft: "10px",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: "#1846dd",
    },
    p: {
      lineHeight: "0.5",
      fontSize: "11.8px",
    },
  }));

  export const YellowText = styled(Typography)(({ theme }) => ({
    fontSize: "34px",
    fontWeight: "600",
    color: theme.palette.custom.accent.accent_2,
    lineHeight: "1",
    display: "flex",
    alignItem: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
  }));

  export const InfoGradientText = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "type",
  })(({ type }: { type: string }) => ({ theme }) => ({
    fontSize: "34px",
    fontWeight: "600",
    background: type === "Over" ? theme.palette.custom.secondary.secondary_2 : "linear-gradient(337.35deg, #EB00FF 7.34%, #E9B80C 91.96%)",
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    lineHeight: "1",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
  }));

  export const InfoGreenText = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "type",
  })(({ type }: { type: string }) => ({ theme }) => ({
    fontSize: "34px",
    fontWeight: "600",
    color: type === "Over" ? theme.palette.custom.secondary.secondary_2 : theme.palette.custom.accent.accent_1,
    lineHeight: "1",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
  }));

  export const MiddleContainer = styled(Stack)(({ theme }) => ({
    minHeight: "30px",
    maxHeight: "30px",
    width: "calc(100% - 34px)",
    bottom: "80px",
    backgroundColor: theme.palette.custom.secondary.secondary_3,
    boxShadow: "0px -12px 0px 0px #2D2C31, 0px 12px 0px 0px #2D2C31 ",
  }));

  export const MiddleInnerContainer = styled(Stack)(() => ({
    position: "relative",
    marginTop: "0px",
    minHeight: "30px",
    maxHeight: "30px",
    fontSize: "20px",
    fontWeight: "600",
    background: "linear-gradient(296.38deg, #EB00FF 33.15%, #E9B80C 100%)",
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    backgroundSize: "185% auto",
    animation: "shine 6s linear infinite reverse",
    "@keyframes shine": {
      to: {
        backgroundPosition: "110% center",
      },
    },
  }));

  export const LeftCircle = styled("span")(({ theme }) => ({
    display: "block",
    minWidth: "32px",
    minHeight: "32px",
    left: "-30px",
    top: "0",
    overflow: "hidden",
    position: "absolute",
    transform: "rotate(270deg)",

    "::after": {
      content: "''",
      minWidth: "26px",
      minHeight: "26px",
      borderRadius: "100px",
      background: "rgba(0, 0, 0, 0)",
      position: "absolute",
      top: "-7px",
      left: "-5px",
      border: `9px solid ${theme.palette.custom.secondary.secondary_3}`,
    },
  }));

  export const RightCircle = styled("span", {
    shouldForwardProp: (prop) => prop !== "type",
  })(({ type }: { type: string }) => ({ theme }) => ({
    display: "block",
    minWidth: "32px",
    minHeight: "32px",
    right: "-30px",
    bottom: "0px",
    overflow: "hidden",
    position: "absolute",
    transform: "rotate(90deg)",

    "::after": {
      content: "''",
      minWidth: "26px",
      minHeight: "26px",
      borderRadius: "100px",
      background: "rgba(0, 0, 0, 0)",
      position: "absolute",
      top: "-7px",
      left: "-5px",
      border: `9px solid ${theme.palette.custom.secondary.secondary_3}`,
    },
  }));

  export const BottomContainer = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    minHeight: "83px",
    maxHeight: "83px",
    width: "100%",
    padding: `0 ${theme.spacing(6)}`,
    paddingBottom: theme.spacing(1),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.palette.custom.secondary.secondary_3,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      minHeight: "170px",
      maxHeight: "170px",
      justifyContent: "space-evenly",
    },
  }));

  export const ButtonContainer = styled(Stack)(({ theme }) => ({
    position: "relative",
    flexDirection: "row",
    gap: theme.spacing(4),
    width: "100%",
    justifyContent: "space-between",
  }));

  export const CancellationNote = styled(Typography)(() => ({
    position: "absolute",
    top: "36px",
    left: "5px",
    fontSize: "8px",
    color: "rgba(255, 255, 255, 0.4)",
  }));

  export const TicketIdContainer = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    margin: "0 auto",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      margin: "unset",
      height: "fit-content",
      flexDirection: "column",
    },
  }));

  export const TicketText = styled(Typography)(({ theme }) => ({
    fontSize: "32px",
    fontWeight: "600",
    color: theme.palette.custom.secondary.secondary_1,
  }));

  export const TicketTextGradient = styled(TicketText)(({ theme }) => ({
    background: "linear-gradient( #E9B80C 9.43%, #EB00FF 75%)",
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    [theme.breakpoints.down("sm")]: {
      fontSize: "44px",
    },
  }));
}

export default S;
