import { Promise } from "bluebird";
import PredictionABI from "../build/prediction_abi.json";
import { PREDICT_CONTRACT, web3 } from "./web3";

export namespace predictionContract {
  let deployed, predictContract: any;
  const CURRENT_CHAIN_ID: number = Number(process.env.REACT_APP_CURRENT_CHAINID);

  export const setup = async (library?: any) => {
    deployed = false;
    try {
      if (library) web3.setProvider(library.provider);
      let predictionAbi = PredictionABI.abi as any;
      predictContract = new web3.eth.Contract(predictionAbi, PREDICT_CONTRACT[CURRENT_CHAIN_ID]);
      Promise.promisifyAll(predictContract, { suffix: "Promise" });
      deployed = true;
    } catch (e) {
      console.log(e);
    }
    return deployed;
  };

  export const getPredictionOwner = async () => await predictContract.methods.owner().call();
}
