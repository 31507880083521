import { BACKEND_URL } from "../../utils/constants";
import Toast from "../../utils/widgets/toast";

const CURRENT_CHAIN_ID: number = Number(process.env.REACT_APP_CURRENT_CHAINID);

export const getPoolStatus = async () => {
  let status = await fetch(`${BACKEND_URL[CURRENT_CHAIN_ID]}/poolStatus`);
  if (status.status === 200) {
    let response = await status.json();
    return response.status;
  }
};

export const getDrawInfo = async (resetLoader: () => void) => {
  try {
    let status = await fetch(`${BACKEND_URL[CURRENT_CHAIN_ID]}/drawInfo`);
    if (status.status === 200) {
      let response = await status.json();
      return response;
    }
  } catch (e) {
    resetLoader();
    console.log(e);
    Toast({ message: "Failed to fetch draw info!", type: "error" });
  }
};
