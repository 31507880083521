import { Stack, styled, Typography } from "@mui/material";

namespace S {
  export const PoolParticipantsModalBodyContainer = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    padding: theme.spacing(8),
    paddingTop: theme.spacing(6),
    width: "64.375rem",
    maxHeight: "30rem",
    overflowY: "auto",
    [theme.breakpoints.down("lg")]: {
      width: "90vw",
    },
    [theme.breakpoints.down("md")]: {
      width: "95vw",
      padding: `${theme.spacing(6)} ${theme.spacing(4)}`,
    },
  }));

  export const ContentTitle = styled(Stack)(() => ({
    width: "100%",
    height: "3.125rem",
    flexDirection: "row",
  }));

  export const Contents = styled(Stack)(() => ({
    boxSizing: "border-box",
    width: "100%",
    height: "fit-content",
    minHeight: "10rem",
  }));

  export const AddressHeadingText = styled(Typography)(({ theme }) => ({
    width: "54%",
    color: "rgba(255, 255, 255, 0.4)",
    fontWeight: "600",
    fontSize: "0.875rem",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "45%",
    },
  }));

  export const AmountHeadingText = styled(AddressHeadingText)(({ theme }) => ({
    width: "24%",
    [theme.breakpoints.down("md")]: {
      width: "25%",
    },
  }));

  export const ContentRow = styled(Stack)(() => ({
    width: "100%",
    minHeight: "2.5rem",
    flexDirection: "row",
    alignItems: "center",
  }));

  export const AddressText = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "yourTicket",
  })(({ yourTicket }: { yourTicket?: Boolean }) => ({ theme }) => ({
    width: "54%",
    height: "fit-content",
    fontWeight: "400",
    fontSize: "0.75rem",
    alignSelf: "center",
    lineHeight: "1 !important",
    color: yourTicket ? theme.palette.custom.accent.accent_1 : theme.palette.custom.secondary.secondary_1,
    [theme.breakpoints.down("md")]: {
      width: "45%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "clip",
    },
  }));

  export const AmountText = styled(AddressText, {
    shouldForwardProp: (prop) => prop !== "yourTicket",
  })(({ yourTicket }: { yourTicket?: Boolean }) => ({ theme }) => ({
    boxSizing: "border-box",
    width: "24%",
    fontWeight: "600",
    color: yourTicket ? theme.palette.custom.accent.accent_1 : theme.palette.custom.secondary.secondary_1,
    paddingLeft: theme.spacing(5),
    [theme.breakpoints.down("md")]: {
      width: "25%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "clip",
    },
  }));

  export const ParticipantNotFoundText = styled(Typography)(({ theme }) => ({
    fontWeight: "400",
    fontSize: "1rem",
    color: theme.palette.custom.secondary.secondary_1,
    margin: "auto",
  }));
}
export default S;
