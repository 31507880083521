import { styled } from "@mui/material";
import { Box } from "@mui/system";

namespace S {
  export const ConnectWrapper = styled(Box)({
    position: "relative",
  });
}

export default S;
