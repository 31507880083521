import { Box, Modal, styled } from "@mui/material";

namespace S {
  export const CustomModal = styled(Modal)(({ theme }) => ({
    backdropFilter: "blur(17px)",
  }));

  export const ModalContainer = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: theme.palette.custom.secondary.secondary_1,
    color: theme.palette.custom.secondary.secondary_2,
    width: "30.8125rem",
    height: "37.625rem",
    outline: "none",
    borderRadius: "1.25rem",
    // padding: "3.75rem 3.125rem",
    boxSizing: "border-box",
    hr: {
      position: "absolute",
      width: "100%",
      left: 0,
      bottom: "20%",
      opacity: 0.2,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0.75rem",
      width: "90%",
    },
  }));

  export const ModalCrossedContainer = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%) rotate(-10deg)",
    background: "transparent",
    border: `2px solid ${theme.palette.custom.secondary.secondary_1}`,
    width: "30.8125rem",
    height: "38.625rem",
    outline: "none",
    borderRadius: "1.25rem",
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      width: "75%",
    },
  }));
}

export default S;
