import { RootState } from "../../store";

//user
export const useCompletedPoolTickets = (s: RootState) => s.user.completedPoolTickets;
export const useDashboardPools = (s: RootState) => s.user.dashboardPools;
export const useEnteredDraw = (s: RootState) => s.user.enteredDraw;
export const useIsAppLoading = (s: RootState) => s.user.isAppLoading;
export const useIsListeningEventstream = (s: RootState) => s.user.isListeningEventstream;
export const useIsReady = (s: RootState) => s.user.isReady;
export const useIsSomethingWrong = (s: RootState) => s.user.isSomethingWrong;
export const useMyEarnings = (s: RootState) => s.user.myEarnings;
export const useMyPoolTickets = (s: RootState) => s.user.myPoolTickets;
export const useParticipantsByPool = (s: RootState) => s.user.participantsByPool;
export const usePoolInfo = (s: RootState) => s.user.poolInfo;
export const useSelectedMyPool = (s: RootState) => s.user.selectedMyPool;
export const useSelectedPool = (s: RootState) => s.user.selectedPool;
export const useTicketHeader = (s: RootState) => s.user.ticketHeader;
export const useTicketsSold = (s: RootState) => s.user.ticketsSold;
export const useAppStatus = (s: RootState) => s.user.appStatus;
export const useUserDetails = (s: RootState) => s.user.userDetails;
export const useWinnersByTicketId = (s: RootState) => s.user.winnersByTicketId;
export const useIsPoolSucceed = (s: RootState) => s.user.isPoolSucceed;

//draw
export const useDrawAmount = (s: RootState) => s.draw.drawAmount;
export const useIsDrawReady = (s: RootState) => s.draw.isDrawReady;
export const useDraw100kNumber = (s: RootState) => s.draw.draw100kNumber;
