import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { GlobalStyles } from "@mui/material";
import { useAccount } from "wagmi";
import S from "./layout.styled";
import { iercContract } from "../../services/ethereum/contract/iercContract";
import { lottoContract } from "../../services/ethereum/contract/web3Contract";
import { fetchWinners, getDashboardPools } from "../../store/slices/pools.actions";
import { setCurrentDraw, setIsPoolSucceed, updateBalance, updateWalletBalance } from "../../store/slices/user";
import { getWalletBalance } from "../../utils/helpers";
import useEventListener from "../../utils/hooks/eventListener";
import useWalletConnection from "../../utils/hooks/events";
import {
  useAppStatus,
  useCompletedPoolTickets,
  useIsAppLoading,
  useIsPoolSucceed,
  useIsReady,
  usePoolInfo,
  useTicketsSold,
  useUserDetails,
} from "../../utils/hooks/selector";
import { useAppDispatch } from "../../utils/hooks/state";
import { usePolygon } from "../../utils/hooks/switchNetwork";
import RectangularLoader from "../../utils/widgets/loader/RectangularLoader";
import PoolSucceedModal from "../modals/pool-succeed-modal";
import PoolManageMent from "../pool-management";
import Content from "./content";
import Header from "./header";

const Layout: FC = () => {
  //constructors
  const dispatch = useAppDispatch();

  //state values
  const userDetails = useSelector(useUserDetails);
  const ticketsSold = useSelector(useTicketsSold);
  const appStatus = useSelector(useAppStatus);
  const isAppLoading = useSelector(useIsAppLoading);
  const isReady = useSelector(useIsReady);
  const completedPoolTickets = useSelector(useCompletedPoolTickets);
  const poolInfo = useSelector(usePoolInfo);
  const isPoolSucceed = useSelector(useIsPoolSucceed);
  const { isConnected: isWalletConnected } = useAccount();

  useWalletConnection();

  usePolygon();

  //Event listeners for app
  useEventListener();

  useEffect(() => {
    lottoContract.currentDraw().then((currentDraw) => dispatch(setCurrentDraw(currentDraw)));
  }, [isReady, ticketsSold, appStatus]);

  useEffect(() => {
    dispatch(getDashboardPools());
  }, [poolInfo.activePool]);

  useEffect(() => {
    if (completedPoolTickets.payload.length) {
      dispatch(fetchWinners());
    }
  }, [completedPoolTickets.payload, appStatus]);

  //Update token balance.
  useEffect(() => {
    let tokenBalInterval: ReturnType<typeof setInterval>;
    if (isWalletConnected && userDetails.address) {
      tokenBalInterval = setInterval(async () => {
        let balance = await iercContract.getBalance(userDetails.address);
        let walletBalance = await getWalletBalance(userDetails.address);
        dispatch(updateWalletBalance(Number(walletBalance)));
        dispatch(updateBalance(balance));
      }, 5000);
    }

    return () => clearInterval(tokenBalInterval);
  }, [isWalletConnected, userDetails.address]);

  return (
    <S.Layout>
      <GlobalStyles
        styles={(theme) => ({
          "*": {
            "&::-webkit-scrollbar": {
              width: "5px", // for vertical scroll
              height: "5px", // for horizontal scroll
              backgroundColor: "transparent",
              borderRadius: "10px",
            },

            "&::-webkit-scrollbar-thumb": {
              borderRadius: "10px",
              backgroundColor: theme.palette.custom.bg.scroll,
              height: "20px",
            },

            "&::-webkit-scrollbar-track-piece:start": {
              backgroundColor: "transparent",
              marginTop: "20px",
            },

            "&::-webkit-scrollbar-track-piece:end": {
              backgroundColor: "transparent",
              marginBottom: "20px",
            },
            color: theme.palette.custom.secondary.secondary_1,
          },
          ".MuiPopover-root .MuiPopover-paper": {
            borderRadius: theme.spacing(2),
            backgroundColor: theme.palette.custom.secondary.secondary_3,
          },
          ".MuiLinearProgress-root .css-19c4lqn-MuiLinearProgress-root": {
            backgroundColor: `${theme.palette.custom.secondary.secondary_2} !important`,
          },
        })}
      />
      {isAppLoading || !isReady ? (
        <RectangularLoader />
      ) : (
        <>
          <Header />
          <Content>
            <PoolManageMent />
          </Content>
        </>
      )}
      {isPoolSucceed && <PoolSucceedModal open={isPoolSucceed} handleClose={() => dispatch(setIsPoolSucceed(false))} />}
    </S.Layout>
  );
};

export default Layout;
