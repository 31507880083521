import { Stack, styled } from "@mui/material";

namespace S {
  export const TicketDetailsWrapper = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    flexDirection: "row",
    width: "100%",
    gap: theme.spacing(8),
    maxWidth: "1440px",
    minHeight: "380px",
    maxHeight: "380px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 50px 30px",
    backgroundColor: theme.palette.custom.secondary.secondary_3,
    overflowX: "auto",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: theme.spacing(4),
      padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
      minHeight: "unset",
      maxHeight: "unset",
      minWidth: "100%",
      maxWidth: "100%",
    },
  }));
}

export default S;
