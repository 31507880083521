import Web3 from "web3";

export const SUPPORTED_NETWORKS = [80001, 56];

const CURRENT_CHAIN_ID: number = Number(process.env.REACT_APP_CURRENT_CHAINID);

const RPC: any = {
  80001: process.env.REACT_APP_MATIC_RPC_URL,
  56: process.env.REACT_APP_BSC_RPC,
};

export const web3 = new Web3(new Web3.providers.HttpProvider(RPC[CURRENT_CHAIN_ID]));

export const CONTRACT: { [id: number]: string | undefined } = {
  80001: process.env.REACT_APP_LOTTO_CONTRACT_ADDRESS,
  56: process.env.REACT_APP_BSC_LOTTO,
};

export const PREDICT_CONTRACT: { [id: number]: string | undefined } = {
  80001: process.env.REACT_APP_LOTTO_PREDICTION_CONTRACT_ADDRESS,
  56: process.env.REACT_APP_BSC_LOTTO_PREDICTION,
};

export const POOL_CONTRACT: { [id: number]: string | undefined } = {
  80001: process.env.REACT_APP_POOLS_CONTRACT_ADDRESS,
  56: process.env.REACT_APP_BSC_LOTTO_POOL,
};

