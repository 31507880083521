import { Dialog, styled, Stack } from "@mui/material";

namespace S {
  export const ModalContainer = styled(Dialog)(({ theme }) => ({
    width: "100%",
    height: "100%",
    boxSizing: "border-box",

    "& .MuiPaper-root": {
      borderRadius: "0.625rem",
      overflowY: "unset !important",
      maxWidth: "80rem !important",
    },

    ".css-1qxadfk-MuiPaper-root-MuiDialog-paper": {
      maxWidth: "calc(100vw - 2.5rem)",
      maxHeight: "calc(100vh - 2.5rem)",
      [theme.breakpoints.down("md")]: {
        maxWidth: "calc(100vw - 1.875rem)",
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: "calc(100vw - 0.625rem)",
      },
    },

    ".css-qs36h5-MuiTypography-root-MuiDialogTitle-root": {
      padding: 0,
    },
  }));

  export const ModalHeaderContainer = styled(Stack, { shouldForwardProp: (prop) => prop !== "isTransparent" })(
    ({ isTransparent }: { isTransparent: boolean }) =>
      ({ theme }) => ({
        backgroundColor: isTransparent ? "transparent" : theme.palette.custom.secondary.secondary_3,
        position: "relative",
        borderRadius: "0.625rem 0.625rem 0 0",
        padding: 0,
      }),
  );

  export const CustomcloseButton = styled("i")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    height: "2rem",
    width: "2rem",
    borderRadius: "50%",
    backgroundColor: theme.palette.custom.bg.scroll,
    position: "absolute",
    right: "-1rem",
    top: "-1rem",
    [theme.breakpoints.down("sm")]: {
      right: 0,
    },
  }));

  export const ModalBodyContainer = styled(Stack, { shouldForwardProp: (prop) => prop !== "isTransparent" })(
    ({ isTransparent }: { isTransparent: boolean }) =>
      ({ theme }) => ({
        backgroundColor: isTransparent ? "transparent" : theme.palette.custom.secondary.secondary_3,
        maxHeight: "calc(100vh - 17rem)",
        overflowY: "unset",
      }),
  );

  export const ModalFooterContainer = styled(Stack, { shouldForwardProp: (prop) => prop !== "isTransparent" })(
    ({ isTransparent }: { isTransparent: boolean }) =>
      ({ theme }) => ({
        backgroundColor: isTransparent ? "transparent" : theme.palette.custom.secondary.secondary_3,
        borderRadius: "0 0 0.625rem 0.625rem",
        padding: 0,
      }),
  );
}

export default S;
