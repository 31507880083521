import { FC, useState } from "react";
import { useSigner } from "wagmi";
import S from "./join-participant-modal.styled";
import { getDrawInfo } from "../../../services/api";
import { joinPool, withdrawFromPool } from "../../../store/slices/pools.actions";
import { joinModalDataObj, PRIMARY, SECONDARY } from "../../../utils/constants";
import { useIsMobileView } from "../../../utils/helpers";
import { useAppDispatch } from "../../../utils/hooks/state";
import { Button } from "../../../utils/widgets";
import { CustomModal, ModalBody, ModalFooter, ModalHeader } from "../../../utils/widgets/custom-modal";
import CircularLoader from "../../../utils/widgets/loader/CircularLoader";
import Toast from "../../../utils/widgets/toast";
import JoinParticipantModalBody from "./join-participant-modal-body";

type JoinParticipantModalType = {
  open: boolean;
  handleClose: () => void;
  modalDetail: {
    title: string;
    btnTxt: string;
  };
  remainingAmount: number;
  remainingAmountWei: number;
};

const JoinParticipantModal: FC<JoinParticipantModalType> = ({ open, handleClose, modalDetail, remainingAmount, remainingAmountWei }) => {
  //state values
  const [amount, setAmount] = useState(remainingAmount);
  const [isJoining, setIsJoining] = useState(false);
  const [maxSelected, setMaxSelected] = useState(false);

  //constant
  const sm = useIsMobileView("sm");
  const { data: signer } = useSigner();
  const dispatch = useAppDispatch();

  //function
  const onClick = async () => {
    if (!isJoining) {
      let amountToTransfer = maxSelected ? remainingAmountWei : amount;
      setIsJoining(true);
      if (modalDetail.title === joinModalDataObj[1].title) {
        if (maxSelected || amount === remainingAmount) {
          let drawInfo = await getDrawInfo(() => setIsJoining(false));
          if (drawInfo.appStatus !== "active" && drawInfo.appStatus !== "drawStarted") {
            setIsJoining(false);
            Toast({ message: "Draw is currently not active. Try after sometime!" });
            return;
          }
        }
        dispatch(
          joinPool(
            amountToTransfer,
            signer?.provider,
            maxSelected,
            () => setIsJoining(false),
            () => handleClose(),
          ),
        );
      } else
        dispatch(
          withdrawFromPool(
            amountToTransfer,
            signer?.provider,
            maxSelected,
            () => setIsJoining(false),
            () => handleClose(),
          ),
        );
    }
  };

  return (
    <CustomModal open={open} onClose={handleClose} isLoading={isJoining}>
      <ModalHeader handleClose={handleClose} disableCloseIcon={true}>
        <S.ModalHeader>{modalDetail.title}</S.ModalHeader>
      </ModalHeader>
      <ModalBody>
        <JoinParticipantModalBody
          amount={amount}
          setAmount={setAmount}
          remainingAmount={remainingAmount}
          setMaxSelected={setMaxSelected}
          maxSelected={maxSelected}
          isJoining={isJoining}
        />
      </ModalBody>
      <ModalFooter>
        <S.ModalFooter>
          <Button
            buttonType={PRIMARY}
            disabled={isJoining}
            onClick={() => {
              handleClose();
            }}
            customStyle={{
              background: "#2d2c31",
              border: "0.0625rem solid white",
              minWidth: sm ? "6.25rem" : "6.9375rem",
              height: "32px",
              borderRadius: "0.625rem",
              fontSize: "0.875rem",
              "&:hover": {
                background: "#2d2c31",
              },
            }}
          >
            Cancel
          </Button>

          <Button
            buttonType={PRIMARY}
            disabled={!Boolean(amount) || amount > remainingAmount || isJoining}
            onClick={onClick}
            customStyle={{
              minWidth: sm ? "6.25rem" : "6.9375rem",
              height: "2rem",
              borderRadius: "0.625rem",
              fontSize: "0.875rem",
            }}
          >
            {isJoining ? <CircularLoader size={20} type={SECONDARY} /> : modalDetail.btnTxt}
          </Button>
        </S.ModalFooter>
      </ModalFooter>
    </CustomModal>
  );
};
export default JoinParticipantModal;
