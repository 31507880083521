import { FC } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { SInvitedByPopover, SMyEarningsPopover } from "./popovers.styled";
import { getEllipsisString } from "../../utils/helpers";
import { useMyEarnings, useUserDetails } from "../../utils/hooks/selector";
import { CustomPopoverProps } from "../../utils/types";
import { Triangle } from "../../utils/widgets";
import CustomPopover from "../../utils/widgets/popover";

const MyEarningsPopover: FC<CustomPopoverProps> = (props) => {
  const { id, isOpen, onClose } = props;

  //state values
  const userDetails = useSelector(useUserDetails);
  const myEarnings = useSelector(useMyEarnings);

  //constants
  const totalEarnings = myEarnings.winning + myEarnings.affiliate;

  return (
    <>
      {Boolean(isOpen) && <Triangle customStyle={{ top: 20, left: -117, borderBottomColor: "#2D2C31" }} />}
      <CustomPopover id={id} isOpen={isOpen} onClose={onClose} customStyle={{ top: 24, left: -2 }}>
        <SMyEarningsPopover.Wrapper>
          <SMyEarningsPopover.Header>
            <SMyEarningsPopover.HeaderText>My Earnings</SMyEarningsPopover.HeaderText>
          </SMyEarningsPopover.Header>
          <SMyEarningsPopover.Body>
            <Box>
              <SMyEarningsPopover.Text1 variant="subtitle2">My Address</SMyEarningsPopover.Text1>
              <SInvitedByPopover.UserAddress variant="body1">{getEllipsisString(userDetails.address, 14, 13)}</SInvitedByPopover.UserAddress>
            </Box>
            <SMyEarningsPopover.Row>
              <SMyEarningsPopover.Text1 variant="subtitle2">Earned from Winnings</SMyEarningsPopover.Text1>
              <SMyEarningsPopover.Text3 variant="subtitle1">${myEarnings.winning.toLocaleString()}</SMyEarningsPopover.Text3>
            </SMyEarningsPopover.Row>
            <SMyEarningsPopover.Row>
              <SMyEarningsPopover.Text1 variant="subtitle2">Earned from Referrals</SMyEarningsPopover.Text1>
              <SMyEarningsPopover.Text3 variant="subtitle1">${myEarnings.affiliate.toLocaleString()}</SMyEarningsPopover.Text3>
            </SMyEarningsPopover.Row>
          </SMyEarningsPopover.Body>
          <SMyEarningsPopover.Footer>
            <SMyEarningsPopover.Row>
              <SMyEarningsPopover.Text2>TOTAL EARNED</SMyEarningsPopover.Text2>
              <SMyEarningsPopover.Text4>${totalEarnings.toLocaleString()}</SMyEarningsPopover.Text4>
            </SMyEarningsPopover.Row>
          </SMyEarningsPopover.Footer>
        </SMyEarningsPopover.Wrapper>
      </CustomPopover>
    </>
  );
};

export default MyEarningsPopover;
