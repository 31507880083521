import { FC, useState } from "react";
import S from "./ticket-details.styled";
import DetailsContainer from "../details-container";
import TicketIdContainer from "../ticket-id-container";

const TicketDetails: FC = () => {
  //state values
  const [searchValue, setSearchValue] = useState("");

  //functions
  const handleSearchFilter = (e: string) => {
    setSearchValue(e);
  };

  return (
    <S.TicketDetailsWrapper>
      <TicketIdContainer searchValue={searchValue} handleSearchFilter={handleSearchFilter} />
      <DetailsContainer searchValue={searchValue} handleSearchFilter={handleSearchFilter} />
    </S.TicketDetailsWrapper>
  );
};

export default TicketDetails;
