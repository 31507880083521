import { FC } from "react";
import S from "./header.styled";
import HeaderLogo from "../../../assets/images/HeaderLogo.png";
import { useIsMobileView } from "../../../utils/helpers";
import TicketSold from "../../ticket-sold";
import HeaderRightContainer from "./HeaderRightContainer";

const Header: FC = () => {
  //constants
  const md = useIsMobileView("md");

  return (
    <S.Header>
      <S.HeaderMainContainer>
        <S.HeaderLogo src={HeaderLogo} />
        {!md && <TicketSold />}
        <HeaderRightContainer />
      </S.HeaderMainContainer>
    </S.Header>
  );
};

export default Header;
