import { Stack, styled, Typography } from "@mui/material";

namespace S {
  export const PoolParticipantsModalHeader = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    marginLeft: theme.spacing(8),
    marginRight: theme.spacing(8),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    gap: theme.spacing(2),
    flexDirection: "row",
    justifyContent: "space-between",
    lineHeight: "0.8 !important",
    alignItems: "center",
    borderBottom: `0.0625rem solid ${theme.palette.custom.strokes.primary}`,
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(4),
      marginLeft: theme.spacing(4),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      flexDirection: "column",
    },
  }));

  export const HeaderLeftContainer = styled(Stack)(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      alignSelf: "flex-start",
    },
  }));

  export const HeaderText = styled(Typography)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    background: "linear-gradient(92.48deg, #1967FC 1.43%, #19FCB8 75%)",
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    fontSize: "52px",
    fontWeight: "600",
    [theme.breakpoints.down("md")]: {
      fontSize: "28px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  }));

  export const HeaderTextUnderLine = styled("span")(() => ({
    height: "0.375rem",
    borderRadius: "1.25rem",
    background: "linear-gradient(92.48deg, #1967FC 1.43%, #19FCB8 75%)",
  }));

  export const HeaderRightContainer = styled(Stack)(({ theme }) => ({
    gap: theme.spacing(1.5),
    alignItems: "flex-end",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      alignItems: "flex-start",
    },
  }));

  export const SearchIcon = styled("img")({
    width: "1.2rem",
    height: "1.2rem",
  });

  export const SearchContainer = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    alignItems: "center",
    gap: "1rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      div: {
        width: "100%",
      },
    },
  }));

  export const TicketTextBox = styled(Stack)(() => ({
    flexDirection: "row",
  }));

  export const TicketText = styled(Typography)(({ theme }) => ({
    fontSize: "40px",
    fontWeight: "600",
    color: theme.palette.custom.secondary.secondary_1,
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  }));

  export const GradientText = styled(Typography)(({ theme }) => ({
    fontSize: "2.625rem",
    fontWeight: "600",
    background: "linear-gradient(337.35deg, #EB00FF 7.34%, #E9B80C 91.96%)",
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  }));

  export const PoolParticipantsModalFooter = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    marginLeft: theme.spacing(8),
    marginRight: theme.spacing(8),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    borderTop: `0.0625rem solid ${theme.palette.custom.strokes.primary}`,
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(4),
      marginLeft: theme.spacing(4),
    },
  }));
}
export default S;
