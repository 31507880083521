import { FC } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { EthereumClient, w3mProvider, w3mConnectors } from "@web3modal/ethereum";
import { GraphQLClient } from "graphql-request";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { polygonMumbai, bsc } from "wagmi/chains";
import store from "../../store";
import { darkTheme } from "../theme";

type Props = {
  children: JSX.Element | JSX.Element[];
};
const CURRENT_CHAIN_ID: number = Number(process.env.REACT_APP_CURRENT_CHAINID);

/** Web3modal config**/
const chains: any = CURRENT_CHAIN_ID === 56 ? [bsc] : [polygonMumbai];
const { provider } = configureChains(chains, [w3mProvider({ projectId: `${process.env.REACT_APP_PROJECT_ID}` })]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ projectId: `${process.env.REACT_APP_PROJECT_ID}`, chains }),
  provider,
});

const platformUrl: any = {
  80001: `${process.env.REACT_APP_SUBGRAPH_ENDPOINT}`,
  56: `${process.env.REACT_APP_BSC_SUBGRAPH_LOTTO_ENDPOINT}`,
};

const platformPoolUrl: any = {
  80001: `${process.env.REACT_APP_POOLS_SUBGRAPH_ENDPOINT}`,
  56: `${process.env.REACT_APP_BSC_SUBGRAPH_LOTTO_POOL_ENDPOINT}`,
};

export const ethereumClient = new EthereumClient(wagmiClient, chains);
export const graphQlClient = new GraphQLClient(platformUrl[CURRENT_CHAIN_ID]);
export const poolClient = new GraphQLClient(platformPoolUrl[CURRENT_CHAIN_ID]);

const CustomProvider: FC<Props> = ({ children }) => {
  return (
    <WagmiConfig client={wagmiClient}>
      <Provider store={store}>
        <ThemeProvider theme={darkTheme}>
          <Router>{children}</Router>
        </ThemeProvider>
      </Provider>
    </WagmiConfig>
  );
};

export default CustomProvider;
