import { FC } from "react";
import { useSelector } from "react-redux";
import S from "./pool-card.styled";
import LiveIcon from "../../../assets/images/live-icon.png";
import LOAD_DIAL from "../../../assets/images/load-dial.svg";
import piggyBankIcon from "../../../assets/images/piggyBankIcon.png";
import SAND_TIME from "../../../assets/images/sandtime.svg";
import { useParticipantsByPool } from "../../../utils/hooks/selector";
import { UserSlicetypes } from "../../../utils/types/state";
import GradientTicket from "../gradient-ticket";

type PoolCardProps = {
  status: string;
  isLoading?: boolean;
  className?: string;
  poolCardDetails?: UserSlicetypes.Pool;
  ownerDetails?: UserSlicetypes.Participants | undefined;
};

const PoolCard: FC<PoolCardProps> = ({ status = "Live", className, isLoading, poolCardDetails, ownerDetails }) => {
  //state values
  const participantsByPool = useSelector(useParticipantsByPool);

  return (
    <S.PoolCardWrapper className={className}>
      <S.PoolCardHeader type={status}>
        <S.StatusContainer>
          <S.CustomLiveIcon src={LiveIcon} alt="live-icon" />
          <S.CurrentStatusText>{status === "Live" ? "LIVE" : status === "Waiting" ? "WAITING" : "OVER"}</S.CurrentStatusText>
        </S.StatusContainer>
        {status === "Live" && <S.CustomPigIcon src={piggyBankIcon} alt="piggy-bank-icon" />}
      </S.PoolCardHeader>
      <S.PoolCardBody type={status}>
        {status !== "Waiting" ? (
          <>
            <S.PoolTitle>
              <S.TitleText>POOLING</S.TitleText>
              <S.HeadingGradientText>#pool2yearn</S.HeadingGradientText>
            </S.PoolTitle>
            <GradientTicket
              status={status}
              isLoading={isLoading}
              poolDetails={poolCardDetails}
              poolParticipants={participantsByPool[`${poolCardDetails?.id}`]?.participants}
              ownerDetails={ownerDetails}
            />
          </>
        ) : (
          <S.CustomWaitingIconContainer>
            <S.CustomWaitingIcon src={LOAD_DIAL} alt="waiting-icon" />
            <S.CustomSandIconContainer>
              <S.CustomSandTimeIcon src={SAND_TIME} alt="sand-time-icon" />
            </S.CustomSandIconContainer>
          </S.CustomWaitingIconContainer>
        )}
      </S.PoolCardBody>
    </S.PoolCardWrapper>
  );
};

export default PoolCard;
