import { FC } from "react";
import { Web3Button } from "@web3modal/react";
import { useAccount } from "wagmi";
import S from "./connect-button.styled";

const ConnectButton: FC = () => {
  //state values
  const { isConnected: isWalletConnected } = useAccount();

  return <S.ConnectWrapper>{!isWalletConnected && <Web3Button />}</S.ConnectWrapper>;
};

export default ConnectButton;
