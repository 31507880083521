import { Dispatch, FC, SetStateAction } from "react";
import S from "./join-participant-modal-body.styled";
import { ONEK_POOL_SHARE, TENK_POOL_SHARE, HUNDREDK_POOL_SHARE } from "../../../../utils/constants";

type JoinParticipantModalBodyProps = {
  amount: number;
  setAmount: Dispatch<SetStateAction<number>>;
  remainingAmount: number;
  maxSelected: boolean;
  setMaxSelected: Dispatch<SetStateAction<boolean>>;
  isJoining: boolean;
};

const JoinParticipantModalBody: FC<JoinParticipantModalBodyProps> = ({
  amount,
  setAmount,
  remainingAmount,
  maxSelected,
  setMaxSelected,
  isJoining,
}) => {
  //constant
  let oneKPool = amount * ONEK_POOL_SHARE;
  let tenKPool = amount * TENK_POOL_SHARE;
  let hundredKPool = amount * HUNDREDK_POOL_SHARE;
  let totalPoolAmount = (oneKPool + tenKPool + hundredKPool).toLocaleString();

  return (
    <S.JoinParticipantModalBody>
      <S.AmountContainer>
        <S.AmountBox>
          <S.AmountInput
            type="number"
            value={amount}
            min={0}
            onChange={(e) => {
              if (maxSelected) {
                setMaxSelected(false);
              }
              setAmount(parseInt(e.target.value));
            }}
            disabled={isJoining}
          />
          <S.AdornmentsWrapper direction={"row"}>
            <S.AmountTypeText>USDT</S.AmountTypeText>
            <S.MaxWrapper
              onClick={() => {
                setMaxSelected(true);
                setAmount(remainingAmount);
              }}
              selected={amount === remainingAmount || isJoining}
            >
              <S.AmountMaxText selected={amount === remainingAmount || isJoining}>Max</S.AmountMaxText>
            </S.MaxWrapper>
          </S.AdornmentsWrapper>
        </S.AmountBox>
        <S.Mathbutton
          select={amount === 0 || isJoining}
          onClick={() => {
            if (amount > 0) {
              setAmount(amount - 1);
            }
          }}
        >
          -
        </S.Mathbutton>
        <S.Mathbutton
          select={amount >= remainingAmount || isJoining}
          onClick={() => {
            if (isNaN(amount)) {
              setAmount(1);
            } else {
              amount < remainingAmount && setAmount(amount + 1);
            }
          }}
        >
          +
        </S.Mathbutton>
      </S.AmountContainer>
      <S.MaxAmountWarningText isVisible={amount === remainingAmount}>You have reached the maximum value</S.MaxAmountWarningText>
      <S.AmountExceededWarningText isVisible={amount > remainingAmount}>You have exceeded the maximum value</S.AmountExceededWarningText>
      <S.ChanceOfWinning>
        <S.HeadingText>
          YOUR CHANCE OF WINNING FOR <S.BoldHeadingText> {amount > 0 ? `$${amount}` : "0"}</S.BoldHeadingText>
        </S.HeadingText>
        <S.Row>
          <S.LightText>1K Draw</S.LightText>
          <S.LightText>${amount > 0 ? oneKPool.toLocaleString(undefined, { minimumFractionDigits: 2 }) : 0}</S.LightText>
        </S.Row>
        <S.Row>
          <S.LightText>10K Draw</S.LightText>
          <S.LightText>${amount > 0 ? tenKPool.toLocaleString() : 0}</S.LightText>
        </S.Row>
        <S.Row>
          <S.LightText>100K Draw</S.LightText>
          <S.LightText>${amount > 0 ? hundredKPool.toLocaleString() : 0}</S.LightText>
        </S.Row>
        <S.Row>
          <S.BoldHeadingText>Total Winning</S.BoldHeadingText>
          <S.BoldGreenText>${amount > 0 ? totalPoolAmount : 0}</S.BoldGreenText>
        </S.Row>
        <S.Row>
          <S.MiniText>Added</S.MiniText>
          <S.MiniGreenText>+TOKENS</S.MiniGreenText>
        </S.Row>
        <S.Row>
          <S.MiniText>Added</S.MiniText>
          <S.MiniGreenText>+REFERRALS</S.MiniGreenText>
        </S.Row>
      </S.ChanceOfWinning>
    </S.JoinParticipantModalBody>
  );
};
export default JoinParticipantModalBody;
