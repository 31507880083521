import { Box, Stack, styled, Typography } from "@mui/material";

namespace S {
  export const PoolCardContainer = styled(Stack)(({ theme }) => ({
    color: "#000",
    fontWeight: 600,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "1.875rem",
    [theme.breakpoints.down("sm")]: {
      gap: "1.675rem",
    },
  }));

  export const PoolHeader = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.75rem",
  }));

  export const PoolCardTitle = styled(Typography)(({ theme }) => ({
    color: "#000",
    fontWeight: 600,
    fontSize: "1.185rem",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.175rem",
    },
    marginLeft: "3.5rem",
  }));

  export const PoolBodyContent = styled(Typography)(({ theme }) => ({
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: "1.5rem",
    color: theme.palette.custom.secondary.secondary_2,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
      lineHeight: "1.3rem",
    },
  }));

  export const ThanksCardTitle = styled(Typography)(() => ({
    color: "#000",
    fontWeight: 600,
    marginBottom: "0.5rem",
  }));

  export const ThanksCardContent = styled(Typography)(({ theme }) => ({
    color: theme.palette.custom.secondary.secondary_2,
    "#special-text": {
      color: `${theme.palette.custom.secondary.secondary_2} !important`,
      fontWeight: 600,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  }));

  export const ThanksCardsPointWrapper = styled(Stack)(({ theme }) => ({
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(3),
  }));

  export const ThanksCardPointsContainer = styled(Box)(({ theme }) => ({
    width: "95%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: theme.spacing(2),
  }));

  export const UpgradeTextContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    textAlign: "center",
    gap: theme.spacing(2),
    position: "absolute",
    backgroundColor: "#CBF6FF",
    top: "0",
    left: "-2rem",
    width: "100%",
    padding: "0.5rem 2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0.5rem 1.5rem",
      left: "-1.5rem",
    },
  }));

  export const UpgradeTextWrapper = styled(Box)(({ theme }) => ({
    position: "relative",
    width: "100%",
  }));

  export const PoolUrl = styled("a")(({ theme }) => ({
    color: theme.palette.custom.primary.main,
    textDecoration: "none",
  }));

  export const CheckBoxImage = styled("img")(() => ({
    width: "1.2rem",
    height: "auto",
  }));
}

export default S;
