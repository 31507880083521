import { Promise } from "bluebird";
import Web3 from "web3";
import store from "../../../store";
import { fetchParticipantsByPool, getDashboardPools } from "../../../store/slices/pools.actions";
import { setIsPoolSucceed } from "../../../store/slices/user";
import Toast from "../../../utils/widgets/toast";
import poolToEarnABI from "../build/poolToEarn_abi.json";
import { poolContract } from "./poolContract";
import { web3 } from "./web3";

export namespace poolToEarnContract {
  let deployed, web3Contract: any;

  export const setup = async (contractAddress: string, library?: any) => {
    deployed = false;
    if (library) {
      web3.setProvider(library.provider);
    }
    let poolToEarn_abi = poolToEarnABI.abi as any;
    web3Contract = new web3.eth.Contract(poolToEarn_abi, contractAddress);
    Promise.promisifyAll(web3Contract, { suffix: "Promise" });
    deployed = true;
    return deployed;
  };

  export const getTotal = async () => {
    let activePoolAddress = await poolContract.getActivePool();
    let contractDeployed = await setup(activePoolAddress);
    if (contractDeployed) {
      let total = await web3Contract.methods.total().call();
      return total;
    }
  };

  export const getPastLogs = async () => {
    let latestBlockNumber = await web3.eth.getBlockNumber();
    let pastLogs: any = await web3Contract.getPastEventsPromise("JoinedDraw", {
      fromBlock: Number(latestBlockNumber) - 2000000,
      toBlock: "latest",
    });
    console.log("pastLogs", pastLogs);
    return pastLogs;
  };

  export const getUnclaimedAmount = async (userAddress: string, poolAddress: string, library: any) => {
    let contractDeployed = await setup(poolAddress, library);
    if (contractDeployed) {
      let unclaimed = await web3Contract.methods.unclaimedAmount(userAddress).call();
      return unclaimed;
    }
  };

  export const deposit = async (
    amount: string,
    senderAddress: string,
    contractAddress: string,
    library: any,
    isMax: boolean,
    resetLoaders: () => void,
    onSuccess: () => void,
  ) => {
    let amountWei = isMax ? amount : Web3.utils.toWei(amount, "ether");
    let contractDeployed = await setup(contractAddress, library);
    if (contractDeployed) {
      web3Contract.methods
        .deposit(amountWei)
        .send({ from: senderAddress, gaslimit: 1100000 })
        .on("transactionHash", (hash: any) => {
          console.log("hash", hash);
        })
        .on("receipt", (receipt: any) => {
          console.log(receipt, "receipt");
          resetLoaders();
          if (receipt.status) {
            Toast({ message: "Amount deposited successfully!", type: "success" });
            onSuccess();
            store.dispatch(setIsPoolSucceed(true));
            setTimeout(() => store.dispatch(getDashboardPools()), 3500);
          } else Toast({ message: "Deposit was not successful!", type: "error" });
        })
        .on("error", (error: any, receipt: any) => {
          console.log("error", error.message, receipt);
          resetLoaders();
        });
    }
  };

  export const withdrawAmount = async (
    amount: string,
    senderAddress: string,
    contractAddress: string,
    library: any,
    isMax: boolean,
    resetLoaders: () => void,
    onSuccess: () => void,
  ) => {
    let amountWei = isMax ? amount : Web3.utils.toWei(amount, "ether");
    let contractDeployed = await setup(contractAddress, library);
    if (contractDeployed) {
      web3Contract.methods
        .withdraw(amountWei)
        .send({ from: senderAddress, gaslimit: 1100000 })
        .on("transactionHash", (hash: any) => {
          console.log("hash", hash);
        })
        .on("receipt", (receipt: any) => {
          console.log(receipt, "receipt");
          resetLoaders();
          if (receipt.status) {
            Toast({ message: "Amount withdrawn successfully!", type: "success" });
            onSuccess();
            setTimeout(() => {
              store.dispatch(fetchParticipantsByPool(contractAddress));
              store.dispatch(getDashboardPools());
            }, 3500);
          } else Toast({ message: "Withdrawal was not successful!", type: "error" });
        })
        .on("error", (error: any, receipt: any) => {
          resetLoaders();
          console.log("error", error.message, receipt);
        });
    }
  };

  export const claimAmount = async (senderAddress: string, poolAddress: string, library: any, resetLoaders: () => void, onSuccess: () => void) => {
    let contractDeployed = await setup(poolAddress, library);
    if (contractDeployed) {
      web3Contract.methods
        .claimAmountFromDraw()
        .send({ from: senderAddress, gaslimit: 1100000 })
        .on("transactionHash", (hash: any) => {
          console.log("hash", hash);
        })
        .on("receipt", (receipt: any) => {
          console.log(receipt, "receipt");
          onSuccess();
          resetLoaders();
          if (receipt.status) {
            Toast({ message: "Amount claimed successfully!", type: "success" });
          } else Toast({ message: "Claiming amount was not successful!", type: "error" });
        })
        .on("error", (error: any, receipt: any) => {
          resetLoaders();
          console.log("error", error.message, receipt);
        });
    }
  };

  export const subscribeJoinedDraw = async (library: any) => {
    let activePoolAddress = await poolContract.getActivePool();
    let contractDeployed = await setup(activePoolAddress, library);
    let latestBlockNumber = await web3.eth.getBlockNumber();
    if (contractDeployed) {
      // console.log(web3Contract.events.allEvents());
      // web3Contract.events
      //   .allEvents({ from: "0" })
      //   .on("connected", async (subscriptionId: string) => {
      //     console.log(subscriptionId, "subscription");
      //   })
      //   .on("data", async (event: any) => {
      //     console.log("event", event.event);
      //     if (event.event === "JoinedDraw") {
      //       console.log(event);
      //     }
      //   })
      //   .on("error", (error: any) => {
      //     console.log("error", error);
      //   });
      // let events = web3Contract.events.allEvents({ fromBlock: 0, toBlock: "latest" });
      // events.get((error: any, events: any) => {
      //   console.log(error, events);
      // });
    }
  };
}
