import { FC } from "react";
import S from "./buttons.styled";
import { PRIMARY } from "../../constants";
import { customButtonType } from "../../types/button";

export const Button: FC<customButtonType> = ({ buttonType = PRIMARY, onClick, children, customStyle, disabled = false }) => {
  return (
    <S.DefaultButtonContainer disabled={disabled}>
      <S.CustomButton buttonType={buttonType} onClick={onClick} sx={customStyle} disabled={disabled}>
        {children}
      </S.CustomButton>
    </S.DefaultButtonContainer>
  );
};

export const GradientButton: FC<customButtonType> = ({ buttonType = PRIMARY, onClick, children, customStyle, disabled = false }) => {
  return (
    <S.ButtonContainer disabled={disabled}>
      <S.CustomButton buttonType={buttonType} onClick={onClick} sx={customStyle} disabled={disabled}>
        {children}
      </S.CustomButton>
    </S.ButtonContainer>
  );
};
