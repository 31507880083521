import { FC } from "react";
import { useSelector } from "react-redux";
import { SReferPopover } from "./popovers.styled";
import { REFERRAL_PATH_NAME } from "../../utils/constants";
import { useIsMobileView } from "../../utils/helpers";
import { useSelectedMyPool, useSelectedPool, useTicketHeader, useUserDetails } from "../../utils/hooks/selector";
import { CustomPopoverProps, TicketHeaderType } from "../../utils/types";
import { Triangle } from "../../utils/widgets";
import CustomPopover from "../../utils/widgets/popover";
import InputWithCopyAndShare from "../input-with-copy-and-share";

const CURRENT_CHAIN_ID: number = Number(process.env.REACT_APP_CURRENT_CHAINID);

const APP_URL: {
  [key: number]: string;
} = {
  80001: process.env.REACT_APP_DEV_DASHBOARD_LINK || "",
  56: process.env.REACT_APP_DASHBOARD_LINK || "",
};

const ReferPopover: FC<CustomPopoverProps> = (props) => {
  const { id, isOpen, onClose } = props;

  //constants
  const sm = useIsMobileView("sm");

  //state values
  const selectedPool = useSelector(useSelectedPool);
  const ticketHeader = useSelector(useTicketHeader);
  const selectedMyPool = useSelector(useSelectedMyPool);
  const address = useSelector(useUserDetails).address;

  const { userId } = ticketHeader === TicketHeaderType.history ? selectedPool : selectedMyPool;

  return (
    <>
      {Boolean(isOpen) && <Triangle customStyle={{ top: sm ? 28 : 16, right: sm ? 4 : 45 }} />}
      <CustomPopover id={id} isOpen={isOpen} onClose={onClose} customStyle={{ top: 0, left: -5 }}>
        <SReferPopover.Wrapper>
          <SReferPopover.Highlight variant="body1">Share this unique URL with your friends:</SReferPopover.Highlight>
          <InputWithCopyAndShare Url={`${APP_URL[CURRENT_CHAIN_ID]}/${REFERRAL_PATH_NAME}/${userId}/${address}`} />
        </SReferPopover.Wrapper>
      </CustomPopover>
    </>
  );
};

export default ReferPopover;
