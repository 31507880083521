import { CSSProperties, MouseEvent, ReactNode } from "react";

declare module "@mui/material" {
  // createTheme uses ThemeOptions as argument type which uses PaletteOptions as type for palette property
  interface PaletteOptions {
    custom: {
      primary: {
        main: string;
      };
      secondary: {
        secondary_1: string;
        secondary_2: string;
        secondary_3: string;
        secondary_4: string;
        secondary_5: string;
      };
      accent: {
        accent_1: string;
        accent_2: string;
        accent_3: string;
        accent_4: string;
      };
      strokes: {
        primary: string;
        transparent: string;
      };
      bg: {
        app: string;
        scroll: string;
        backdrop: string;
        level: string;
        live: string;
      };
      toast: {
        success: string;
        error: string;
        info: string;
        warning: string;
      };
    };
  }

  // The 'Theme' type which uses Palette as type for palette property
  // so we have to modify both PaletteOptions and Palette for better linting and code completion
  interface Palette {
    custom: {
      primary: {
        main: string;
      };
      secondary: {
        secondary_1: string;
        secondary_2: string;
        secondary_3: string;
        secondary_4: string;
        secondary_5: string;
      };
      accent: {
        accent_1: string;
        accent_2: string;
        accent_3: string;
        accent_4: string;
      };
      strokes: {
        primary: string;
        transparent: string;
      };
      bg: {
        app: string;
        scroll: string;
        backdrop: string;
        level: string;
        live: string;
      };
      toast: {
        success: string;
        error: string;
        info: string;
        warning: string;
      };
    };
  }
}

//custom popover
export type CustomPopoverProps = {
  id: string | undefined;
  isOpen: HTMLElement | null;
  onClose: (e: MouseEvent<HTMLButtonElement>) => void;
  children?: ReactNode;
  customStyle?: CSSProperties;
  customPopoverStyle?: CSSProperties;
};

export enum PredictType {
  premium = "premium",
  free = "free",
}

export enum TicketHeaderType {
  history = "History",
  myElitepass = "My Elite Pass",
}

export type UserGuideType = {
  id: string;
  label: string;
  link: string;
};
