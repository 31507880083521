import { FC } from "react";
import { Popover } from "@mui/material";
import { CustomPopoverProps } from "../../../utils/types";

const CustomPopover: FC<CustomPopoverProps> = (props) => {
  const { id, isOpen, onClose, children, customStyle } = props;

  //use customStyle to adjust the popover location
  return (
    <Popover
      id={id}
      open={Boolean(isOpen)}
      anchorEl={isOpen}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      sx={{ ...customStyle, ".MuiPopover-paper": { borderRadius: "0" } }}
    >
      {children}
    </Popover>
  );
};

export default CustomPopover;
