import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "../../store";

// Use throughout our app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
// export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// //We can directly access the specific slice/reducer by passing the slice name as arg
// export const usePreferredState = (reducer: string) => useAppSelector((state) => state[reducer as keyof RootState]);

// export const useUser = () => useAppSelector((state) => state.);

// export const useDraw = () => useAppSelector((state) => state.draw);
