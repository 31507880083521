import { Promise } from "bluebird";
import Web3 from "web3";
import { getOneKDrawWinnerDetails, getTenKDrawWinnerDetails } from "../../../store/slices/user.actions";
import { UserSlicetypes } from "../../../utils/types/state";
import Toast from "../../../utils/widgets/toast";
import ABI from "../build/abi.json";
import { CONTRACT, web3 } from "./web3";

export namespace lottoContract {
  let deployed, web3Contract: any;

  export const setup = async (library?: any) => {
    deployed = false;
    const CURRENT_CHAIN_ID: number = Number(process.env.REACT_APP_CURRENT_CHAINID);

    if (library) {
      web3.setProvider(library.provider);
    }
    const myContractAbi = ABI.abi as any; // ABI definitions
    web3Contract = new web3.eth.Contract(myContractAbi, CONTRACT[CURRENT_CHAIN_ID]);
    Promise.promisifyAll(web3Contract, { suffix: "Promise" });
    deployed = true;
    return deployed;
  };

  export const getDraw1k = async () => await web3Contract.methods.DRAW_1K().call();

  export const getDraw10k = async () => await web3Contract.methods.DRAW_10K().call();

  export const getDraw100k = async () => await web3Contract.methods.DRAW_100K().call();

  export const getDrawAmount = async () => await web3Contract.methods.DRAW_AMOUNT().call();

  export const getDrawFee = async () => await web3Contract.methods.DRAW_FEE().call();

  export const getMaxTickets = async () => await web3Contract.methods.MAX_TICKETS().call();

  export const participantByAddress = async (address: string) => {
    let participant = await web3Contract.methods.participants(address).call();
    return participant;
  };

  export const participantById = async (id: string) => {
    let participant = await web3Contract.methods.participantsById(id).call();
    return participant;
  };

  export const getLottoOwner = async () => await web3Contract.methods.owner().call();

  export const subscribeNewDeposits = (cb?: any) => {
    web3Contract.events
      .NewDeposit()
      .on("connected", async (subscriptionId: string) => {
        let ticketNumber = await getTicketNumber();
        if (cb && ticketNumber) cb(ticketNumber);
      })
      .on("data", async (event: any) => {
        console.log("event", event);
        let ticketNumber = await getTicketNumber();
        if (cb && ticketNumber) cb(ticketNumber);
      })
      .on("error", (error: any) => {
        console.log("error", error);
      });
  };

  export const subscribeDrawWinner = (cb?: (res: UserSlicetypes.IWinner) => void) => {
    web3Contract.events
      .DrawWinner()
      .on("connected", (subscriptionId: string) => {
        // console.log(subscriptionId, "draw-winner-connected");
      })
      .on("data", async (event: any) => {
        console.log(event, "drawWinner");
        if (cb) {
          let drawNumber = await currentDraw();
          let winnerDetails = await getOneKDrawWinnerDetails(drawNumber);
          if (winnerDetails) cb(winnerDetails);
        }
      })
      .on("error", (error: any) => {
        console.log("error", error);
      });
  };

  export const subscribeDraw10KWinner = (cb?: (res: UserSlicetypes.IWinner) => void) => {
    web3Contract.events
      .Draw10KWinner()
      .on("connected", (subscriptionId: string) => {
        // console.log(subscriptionId, "draw-10k-winner-connected");
      })
      .on("data", async (event: any) => {
        console.log(event, "draw10KWinner");
        if (cb) {
          let draw10KNumber = await current10KDraw();
          let winnerDetails = await getTenKDrawWinnerDetails(draw10KNumber);
          if (winnerDetails) cb(winnerDetails);
        }
      })
      .on("error", (error: any) => {
        console.log("error", error);
      });
  };

  export const subscribeDraw100KWinner = (cb?: (res: UserSlicetypes.IWinner) => void) => {
    web3Contract.events
      .Draw100KWinner()
      .on("connected", (subscriptionId: string) => {
        // console.log(subscriptionId, "draw-10k-winner-connected");
      })
      .on("data", async (event: any) => {
        let winner = event.returnValues;
        console.log(event, "draw100KWinner");
        if (cb && winner.userId && winner.draw100KNumber && winner.ticketNumber) {
          let participantDetail = await participantById(winner.userId);

          let user = {
            userId: winner.userId,
            drawNumber: winner.draw100KNumber,
            ticketNumber: participantDetail.ticketNumber,
          };
          cb(user);
        }
      })
      .on("error", (error: any) => {
        console.log("error", error);
      });
  };

  export const subscribeDrawStarted = (cb?: (res: string) => void) => {
    web3Contract.events
      .DrawStarted()
      .on("connected", (subscriptionId: string) => {
        // console.log(subscriptionId, "draw-started-connected");
      })
      .on("data", (event: any) => {
        let currentDraw = event.returnValues.drawNumber;
        console.log(event, "drawStarted");
        if (cb) cb(currentDraw);
      })
      .on("error", (error: any) => {
        console.log("error", error);
      });
  };

  export const subscribeReferrer = (cb?: () => void) => {
    web3Contract.events
      .Referrer()
      .on("connected", (subscriptionId: string) => {})
      .on("data", (event: any) => {
        console.log(event, "Referrer");
        if (cb) cb();
      })
      .on("error", (error: any) => {
        console.log("error", error);
      });
  };

  export const getPastLogs = async () => {
    let latestBlockNumber = await web3.eth.getBlockNumber().then((res: any) => {
      return Number(res);
    });
    let pastLogs: any = await web3Contract.getPastEventsPromise("NewDeposit", {
      fromBlock: latestBlockNumber - 3400,
      toBlock: "latest",
    });
    console.log("pastLogs", pastLogs);
    return pastLogs;
  };

  export const currentDraw = async () => {
    let currentDraw: string = await web3Contract.methods.currentDraw().call();
    return currentDraw;
  };

  export const current10KDraw = async () => {
    let current10KDraw: string = await web3Contract.methods.current10KDraw().call();
    return current10KDraw;
  };

  export const getTicketNumber = async () => {
    let ticketNumber: string = await web3Contract.methods.ticketNumber().call();
    return ticketNumber;
  };

  export const getDrawDetails = async (drawNo: number) => {
    let drawDetails: any = await web3Contract.methods.draws(drawNo).call();
    return drawDetails;
  };

  export const getPredictionDrawFee = async () => {
    let predictionFee: string = await web3Contract.methods.predictionDrawFee().call();
    return predictionFee;
  };

  export const getPredictionDraw100KFee = async () => {
    let prediction100KFee: string = await web3Contract.methods.predictionDraw100kFee().call();
    return prediction100KFee;
  };

  export const getFreePredictedDraw = async (drawNumber: string, address: string) => {
    let predictedDraw: string = await web3Contract.methods.freePredictedDraw(drawNumber, address).call();
    return predictedDraw;
  };

  export const getFreePredictedDraw100K = async (address: string) => {
    let predictedDraw100K: string = await web3Contract.methods.freePredictedDraw100k(address).call();
    return predictedDraw100K;
  };

  export const getPremiumPredictedDraw = async (drawNumber: string, address: string) => {
    let predictedDraw: string = await web3Contract.methods.premiumPredictedDraw(drawNumber, address).call();
    return predictedDraw;
  };

  export const getPremiumPredictedDraw100K = async (address: string) => {
    let premiumPredictedDraw100K: string = await web3Contract.methods.premiumPredictedDraw100k(address).call();
    return premiumPredictedDraw100K;
  };

  export const getIsFreeUserWithdrawalRequestEnabled = async () => {
    let withrawalEnabled: boolean = await web3Contract.methods.isFreeUserWithdrawalRequestEnabled().call();
    return withrawalEnabled;
  };

  export const getIsPremiumUserWithdrawalRequestEnabled = async () => {
    let withrawalEnabled: boolean = await web3Contract.methods.isPremiumUserWithdrawalRequestEnabled().call();
    return withrawalEnabled;
  };

  export const getWithdrawalTransactionFee = async () => {
    let withdrawalFee: string = await web3Contract.methods.withdrawalTxnFee().call();
    return withdrawalFee;
  };

  export const getFreeUserWithdrawalRequest = async (address: string) => {
    let withdrawn: boolean = await web3Contract.methods.freeUserWithdrawalRequest(address).call();
    return withdrawn;
  };

  export const getPremiumUserWithdrawalRequest = async (address: string) => {
    let withdrawn: boolean = await web3Contract.methods.premiumUserWithdrawalRequest(address).call();
    return withdrawn;
  };

  export const getReferralBonusesPercentage = async () => {
    let bonusPercentages = await web3Contract.methods.getReferralBonusesPercentage().call();
    return bonusPercentages;
  };

  export const getFreeUserReferralBonusesPercentage = async () => {
    let bonusPercentages = await web3Contract.methods.getFreeUserReferralBonusesPercentage().call();
    return bonusPercentages;
  };

  export const deposit = async (amount: string, referrerId: number, address: string, handleConnecting: () => void, cb: (res: any) => void) => {
    let depositAmount = Web3.utils.toWei(amount, "ether");

    web3Contract.methods
      .deposit(referrerId, depositAmount)
      .send({ from: address, gaslimit: 1100000 })
      .on("transactionHash", (hash: any) => {
        console.log("hash", hash);
      })
      .on("receipt", (receipt: any) => {
        console.log(receipt, "receipt");
        if (receipt.status) {
          Toast({ message: "Amount deposited successfully!", type: "success" });
          if (cb) cb(receipt.events.NewDeposit.returnValues);
        } else Toast({ message: "Deposit was not successful!", type: "error" });
      })
      .on("error", (error: any, receipt: any) => {
        console.log("error", error.message, receipt);
        if (error.message.includes(":")) {
          let msg = error.message.split(":")[0];
          Toast({ message: msg, type: "error" });
        } else Toast({ message: error.message, type: "error" });
        handleConnecting();
      });
  };

  export const predictDrawWinner = async (ticketNumber: string, address: string, cb: (ticketNumber: string) => void, resetLoader: () => void) => {
    web3Contract.methods
      .freePredictDrawWinner(ticketNumber)
      .send({ from: address, gaslimit: 1100000 })
      .on("transactionHash", (hash: any) => {
        console.log("hash", hash);
      })
      .on("receipt", (receipt: any) => {
        console.log(receipt, "receipt");
        if (receipt.status) {
          let ticket_number = receipt.events.FreePredictedDrawWinner.returnValues.ticketNumber;
          cb(ticket_number);
          Toast({ message: "Prediction transaction was successful!", type: "success" });
        } else {
          resetLoader();
          Toast({ message: "Prediction transaction was not successful!", type: "error" });
        }
      })
      .on("error", (error: any, receipt: any) => {
        resetLoader();
        console.log("error", error.message, receipt);
        if (error.message.includes(":")) {
          let msg = error.message.split(":")[0];
          Toast({ message: msg, type: "error" });
        } else Toast({ message: error.message, type: "error" });
      });
  };

  export const predictDraw100KWinner = async (ticketNumber: string, address: string, cb: (ticketNumber: string) => void, resetLoader: () => void) => {
    web3Contract.methods
      .freePredictDraw100kWinner(ticketNumber)
      .send({ from: address, gaslimit: 1100000 })
      .on("transactionHash", (hash: any) => {
        console.log("hash", hash);
      })
      .on("receipt", (receipt: any) => {
        console.log(receipt, "receipt");
        if (receipt.status) {
          let ticket_number = receipt.events.FreePredictedDraw100KWinner.returnValues.ticketNumber;
          cb(ticket_number);
          Toast({ message: "Prediction transaction was successful!", type: "success" });
        } else {
          resetLoader();
          Toast({ message: "Prediction transaction was not successful!", type: "error" });
        }
      })
      .on("error", (error: any, receipt: any) => {
        resetLoader();
        console.log("error", error.message, receipt);
        if (error.message.includes(":")) {
          let msg = error.message.split(":")[0];
          Toast({ message: msg, type: "error" });
        } else Toast({ message: error.message, type: "error" });
      });
  };

  export const premiumPredictDrawWinner = async (
    ticketNumber: string,
    address: string,
    cb: (ticketNumber: string) => void,
    resetLoader: () => void,
  ) => {
    let amount = await getPredictionDrawFee();

    web3Contract.methods
      .premiumPredictDrawWinner(ticketNumber, amount)
      .send({ from: address, gaslimit: 1100000 })
      .on("transactionHash", (hash: any) => {
        console.log("hash", hash);
      })
      .on("receipt", (receipt: any) => {
        console.log(receipt, "receipt");
        if (receipt.status) {
          let ticket_number = receipt.events.PremiumPredictedDrawWinner.returnValues.ticketNumber;
          cb(ticket_number);
          Toast({ message: "Prediction transaction was successful!", type: "success" });
        } else {
          resetLoader();
          Toast({ message: "Prediction transaction was not successful!", type: "error" });
        }
      })
      .on("error", (error: any, receipt: any) => {
        resetLoader();
        console.log("error", error.message, receipt);
        if (error.message.includes(":")) {
          let msg = error.message.split(":")[0];
          Toast({ message: msg, type: "error" });
        } else Toast({ message: error.message, type: "error" });
      });
  };

  export const premiumPredictDraw100kWinner = async (
    ticketNumber: string,
    address: string,
    cb: (ticketNumber: string) => void,
    resetLoader: () => void,
  ) => {
    let amount = await getPredictionDraw100KFee();

    web3Contract.methods
      .premiumPredictDraw100kWinner(ticketNumber, amount)
      .send({ from: address, gaslimit: 1100000 })
      .on("transactionHash", (hash: any) => {
        console.log("hash", hash);
      })
      .on("receipt", (receipt: any) => {
        console.log(receipt, "receipt");
        if (receipt.status) {
          let ticket_number = receipt.events.PremiumPredictedDraw100KWinner.returnValues.ticketNumber;
          cb(ticket_number);
          Toast({ message: "Prediction transaction was successful!", type: "success" });
        } else {
          resetLoader();
          Toast({ message: "Prediction transaction was not successful!", type: "error" });
        }
      })
      .on("error", (error: any, receipt: any) => {
        resetLoader();
        console.log("error", error.message, receipt);
        if (error.message.includes(":")) {
          let msg = error.message.split(":")[0];
          Toast({ message: msg, type: "error" });
        } else Toast({ message: error.message, type: "error" });
      });
  };

  export const premiumUserWithdraw = async (address: string, resetLoaders: () => void) => {
    web3Contract.methods
      .makeWithdrawalRequestByPremiumUser()
      .send({ from: address, gaslimit: 1100000 })
      .on("transactionHash", (hash: any) => {
        console.log("hash", hash);
      })
      .on("receipt", (receipt: any) => {
        console.log(receipt, "receipt");
        resetLoaders();
        if (receipt.status) {
          Toast({ message: "Claimed tokens successfully!", type: "success" });
        } else {
          Toast({ message: "Claiming tokens was not successful!", type: "error" });
        }
      })
      .on("error", (error: any, receipt: any) => {
        resetLoaders();
        console.log("error", error.message, receipt);
        if (error.message.includes(":")) {
          let msg = error.message.split(":")[0];
          Toast({ message: msg, type: "error" });
        } else Toast({ message: error.message, type: "error" });
      });
  };

  export const freeUserWithdraw = async (address: string, resetLoaders: () => void) => {
    let withDrawFee = await getWithdrawalTransactionFee();
    let withDrawFeeWei = Web3.utils.toWei(withDrawFee, "ether");

    web3Contract.methods
      .makeWithdrawalRequestByFreeUser()
      .send({ from: address, gaslimit: 1100000, value: withDrawFeeWei })
      .on("transactionHash", (hash: any) => {
        console.log("hash", hash);
      })
      .on("receipt", (receipt: any) => {
        console.log(receipt, "receipt");
        resetLoaders();
        if (receipt.status) {
          Toast({ message: "Claimed tokens successfully!", type: "success" });
        } else {
          Toast({ message: "Claiming tokens was not successful!", type: "error" });
        }
      })
      .on("error", (error: any, receipt: any) => {
        resetLoaders();
        console.log("error", error.message, receipt);
        if (error.message.includes(":")) {
          let msg = error.message.split(":")[0];
          Toast({ message: msg, type: "error" });
        } else Toast({ message: error.message, type: "error" });
      });
  };
}
