import { FC } from "react";
import S from "./pool-participants-modal-body.styled";
import { convertWeiToEth, getChecksumAddress, isMyself } from "../../../../utils/helpers";
import { UserSlicetypes } from "../../../../utils/types/state";

type PoolParticipantsModalBodyType = {
  searchValue: string;
  participants: UserSlicetypes.Participants[] | [] | undefined;
};

const PoolParticipantsModalBody: FC<PoolParticipantsModalBodyType> = ({ searchValue, participants }) => {
  return (
    <S.PoolParticipantsModalBodyContainer>
      <S.ContentTitle>
        <S.AddressHeadingText>ADDRESS</S.AddressHeadingText>
        <S.AmountHeadingText>AMOUNT</S.AmountHeadingText>
      </S.ContentTitle>
      <S.Contents>
        {Boolean(participants?.filter((data) => getChecksumAddress(data.owner.id).includes(searchValue)).length) ? (
          <>
            {participants
              ?.filter((data) => getChecksumAddress(data.owner.id).includes(searchValue))
              .map((item, i) => (
                <S.ContentRow key={i}>
                  <S.AddressText yourTicket={isMyself(item.owner.id)}> {getChecksumAddress(item.owner.id)}</S.AddressText>
                  <S.AmountText yourTicket={isMyself(item.owner.id)}>${convertWeiToEth(item.total)}</S.AmountText>
                </S.ContentRow>
              ))}
          </>
        ) : (
          <S.ParticipantNotFoundText>Participant not found.</S.ParticipantNotFoundText>
        )}
      </S.Contents>
    </S.PoolParticipantsModalBodyContainer>
  );
};
export default PoolParticipantsModalBody;
