import { FC, useState } from "react";
import S from "./pool-succeed-modal.styled";
import guideImg from "../../../assets/images/greetings-assets/guide.svg";
import playIcon from "../../../assets/images/greetings-assets/play.svg";
import targetImg from "../../../assets/images/greetings-assets/target.svg";
import { participationGuide } from "../../../utils/constants";
import { Button } from "../../../utils/widgets";
import ThanksCardFooter from "../../greetings/footer";
import PoolSucceedCard from "../../greetings/pool-succeed-card";
import UserGuide from "../../greetings/user-guide";
import GreetingsModal from "../greetings-modal";

type GreetingsModalType = {
  open: boolean;
  handleClose: () => void;
};

const PoolSucceedModal: FC<GreetingsModalType> = (props) => {
  //props
  const { open, handleClose } = props;

  //state values
  // const [page, setPage] = useState(0);

  // functions
  // const getModalContent = () => {
  //   switch (page) {
  //     case 0:
  //       return {
  //         component: <PoolSucceedCard />,
  //         img: targetImg,
  //       };
  //     case 1:
  //       return {
  //         component: <UserGuide guide={participationGuide} />,
  //         img: guideImg,
  //       };
  //     default:
  //       return {
  //         component: <PoolSucceedCard />,
  //         img: targetImg,
  //       };
  //   }
  // };

  // const handlePage = (value?: number) => setPage(value ?? page + 1);

  const openLink = () => {
    window.open(`${process.env.REACT_APP_POOL_HOW_TO_CLAIM_POOL_EARNINGS_VIDEO_URL}`, "_blank");
  };

  return (
    <GreetingsModal open={open} handleClose={handleClose}>
      <S.CornerImg src={targetImg} draggable={false} alt="target-image" />
      <S.ContentWrapper>
        <S.GreetingsModalBody>
          <PoolSucceedCard />
        </S.GreetingsModalBody>
        <S.FooterButtonContainer>
          <S.SkipButton variant="outlined" onClick={openLink}>
            <img src={playIcon} alt="play icon" />
            Know more
          </S.SkipButton>
          <Button customStyle={{ fontSize: "1rem", padding: "0.32rem 1.875rem" }} buttonType="primary" onClick={handleClose}>
            Done
          </Button>
        </S.FooterButtonContainer>
        {/* <ThanksCardFooter totalPages={3} currentPage={page + 1} page={page} onClick={handlePage} handleClose={handleClose} /> */}
      </S.ContentWrapper>
    </GreetingsModal>
  );
};

export default PoolSucceedModal;
