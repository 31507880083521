import { FC } from "react";
import { useSelector } from "react-redux";
import S from "./ticket-id.styled";
import SEARCH_ICON from "../../../../assets/images/search.svg";
import { fetchParticipantsByPool } from "../../../../store/slices/pools.actions";
import { setSelectedMyPool, setSelectedPool } from "../../../../store/slices/user";
import { useIsMobileView } from "../../../../utils/helpers";
import {
  useCompletedPoolTickets,
  useMyPoolTickets,
  useParticipantsByPool,
  useSelectedMyPool,
  useSelectedPool,
  useTicketHeader,
} from "../../../../utils/hooks/selector";
import { useAppDispatch } from "../../../../utils/hooks/state";
import { TicketHeaderType } from "../../../../utils/types";
import DotLoader from "../../../../utils/widgets/loader/DotLoader";
import SearchBar from "../../../../utils/widgets/search-bar";

type TicketIdContainerProps = {
  searchValue: string;
  handleSearchFilter: (e: string) => void;
};

const TicketIdContainer: FC<TicketIdContainerProps> = ({ searchValue, handleSearchFilter }) => {
  //constructors
  const dispatch = useAppDispatch();

  //state values
  const myPoolTickets = useSelector(useMyPoolTickets);
  const isRequesting = useSelector(useCompletedPoolTickets).isRequesting;
  const payload = useSelector(useCompletedPoolTickets).payload;
  const selectedPool = useSelector(useSelectedPool);
  const selectedMyPool = useSelector(useSelectedMyPool);
  const participantsByPool = useSelector(useParticipantsByPool);
  const ticketHeader = useSelector(useTicketHeader);

  const sm = useIsMobileView("sm");
  const { ticketNumber } = ticketHeader === TicketHeaderType.history ? selectedPool : selectedMyPool;
  const completedTickets = ticketHeader === TicketHeaderType.history ? payload : myPoolTickets;
  const filteredCompletedTickets = completedTickets.filter((cp) => cp.elitePass.includes(searchValue));

  return (
    <S.TicketIdContainer>
      <S.TicketIdHeader>
        <S.TicketIdHeaderText>ELITE PASS</S.TicketIdHeaderText>
        {sm && (
          <S.SearchContainer direction={"row"}>
            <S.SearchIcon src={SEARCH_ICON} alt="search-icon" />
            <SearchBar placeholder="Search Elite Pass" searchHandler={handleSearchFilter} value={searchValue} />
          </S.SearchContainer>
        )}
      </S.TicketIdHeader>
      <S.TicketIdContent>
        {filteredCompletedTickets.length ? (
          <S.TicketIdInnerContent>
            {filteredCompletedTickets.map((cp, i) => (
              <S.TicketNo
                yourTicket={cp.ticketNumber === ticketNumber}
                key={i}
                onClick={() => {
                  ticketHeader === TicketHeaderType.history ? dispatch(setSelectedPool(cp)) : dispatch(setSelectedMyPool(cp));
                  if (!participantsByPool[cp.poolAddress]) dispatch(fetchParticipantsByPool(cp.poolAddress));
                }}
              >
                {cp.elitePass}
              </S.TicketNo>
            ))}
          </S.TicketIdInnerContent>
        ) : (
          <S.LoaderContainer>{isRequesting ? <DotLoader /> : "No Elite Passes found."}</S.LoaderContainer>
        )}
      </S.TicketIdContent>
    </S.TicketIdContainer>
  );
};

export default TicketIdContainer;
