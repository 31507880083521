import { FC } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Web3Modal } from "@web3modal/react";
import { polygonMumbai, bsc } from "wagmi/chains";
import AppRouters from "../routes/AppRouters";
import CustomProvider, { ethereumClient } from "../utils/providers";

const currentChain = Number(process.env.REACT_APP_CURRENT_CHAINID);
const App: FC = () => {
  return (
    <CustomProvider>
      <AppRouters />
      <Web3Modal
        projectId={process.env.REACT_APP_PROJECT_ID || ""}
        ethereumClient={ethereumClient}
        themeVariables={{
          "--w3m-accent-color": "#1967FC",
        }}
        themeMode={"dark"}
        defaultChain={currentChain === 56 ? bsc : polygonMumbai}
      />
    </CustomProvider>
  );
};

export default App;
