import { gql } from "graphql-request";

//users
export const getUserDetails = gql`
  query getUserDetails($where: NewDeposit_filter) {
    newDeposits(orderBy: drawNumber, orderDirection: asc, where: $where) {
      addr
      amount
      blockNumber
      blockTimestamp
      drawNumber
      id
      transactionHash
      userId
    }
  }
`;

export const getUsersByAddress = gql`
  query getUsersByAddress($where: NewDeposit_filter) {
    newDeposits(orderBy: drawNumber, orderDirection: asc, where: $where) {
      addr
      amount
      blockTimestamp
      drawNumber
      id
      userId
      ticketNumber
      transactionHash
    }
  }
`;

export const getUsersById = gql`
  query getUsersById($where: NewDeposit_filter) {
    newDeposits(orderBy: drawNumber, orderDirection: asc, where: $where) {
      addr
      amount
      blockTimestamp
      drawNumber
      id
      userId
      transactionHash
    }
  }
`;

//Draw winners
export const getDrawWinnersAddress = gql`
  query drawWinners($where: DrawWinner_filter) {
    drawWinners(orderBy: drawNumber, orderDirection: asc, where: $where) {
      id
      addr
      amount
      drawNumber
      userId
      ticketNumber
      transactionHash
      blockTimestamp
    }
  }
`;

export const getDraw10KWinnersAddress = gql`
  query draw10KWinners($where: Draw10KWinner_filter) {
    draw10KWinners(orderBy: draw10KNumber, orderDirection: asc, where: $where) {
      id
      addr
      amount
      draw10KNumber
      userId
      ticketNumber
      transactionHash
      blockTimestamp
    }
  }
`;

export const getDraw100KWinnersAddress = gql`
  query draw100KWinners {
    draw100KWinners(orderBy: blockTimestamp, orderDirection: asc) {
      id
      addr
      userId
      ticketNumber
      amount
      blockNumber
      blockTimestamp
      transactionHash
    }
  }
`;

export const get100KWinnersById = gql`
  query draw100KWinners($where: Draw100KWinner_filter) {
    draw100KWinners(where: $where) {
      amount
    }
  }
`;

export const getMultipleDrawWinners = gql`
  query winners($where: DrawWinner_filter) {
    drawWinners(where: $where) {
      ticketNumber
    }
    draw10KWinners(where: $where) {
      ticketNumber
    }
    draw100KWinners(where: $where) {
      ticketNumber
    }
  }
`;

//Distributed
export const getDistributedAmount = gql`
  query platformStatistics {
    platformStatistics {
      completed10kDraw
      completedDraw
      currentDraw
      id
      totalDepsitAmount
      totalPaidUser
      totalReferralAmount
      totalUser
      totalWinningAmount
    }
  }
`;

//Earnings
export const getDrawWinnerReferralEarnings = gql`
  query platformStatistics($where: DrawWinnerReferrerBonus_filter) {
    drawWinnerReferrerBonuses(where: $where) {
      amount
    }
    drawWinnerReferralPayouts(where: $where) {
      amount
    }
  }
`;

export const getReferralPayouts = gql`
  query referralPayouts($where: ReferralPayout_filter) {
    referralPayouts(where: $where) {
      amount
    }
  }
`;

//Pools
export const getPoolByAddress = gql`
  query pools($where: Pool_filter) {
    pools(where: $where) {
      claimedAmount
      deposited
      id
      joinedDraw
      maxAmount
      total
      totalOwners
      withdrawn
    }
  }
`;
export const getAllPools = gql`
  query pools {
    pools {
      claimedAmount
      deposited
      id
      joinedDraw
      maxAmount
      total
      totalOwners
      withdrawn
    }
  }
`;

export const getAllCompletedPoolAddress = gql`
  query pools($where: Pool_filter) {
    pools(where: $where) {
      id
    }
  }
`;

export const poolCreatedByAddress = gql`
  query poolCreateds($where: PoolCreated_filter) {
    poolCreateds(where: $where) {
      poolAddress
      blockTimestamp
      transactionHash
    }
  }
`;

export const poolCreated = gql`
  query poolCreateds {
    poolCreateds(orderBy: blockTimestamp, orderDirection: desc, first: 2) {
      poolAddress
      blockTimestamp
      transactionHash
    }
  }
`;

export const getDeposit = gql`
  query newDeposits($where: NewDeposit_filter) {
    newDeposits(where: $where) {
      ticketNumber
      addr
      blockTimestamp
      userId
      drawNumber
    }
  }
`;

export const getOwners = gql`
  query owners($where: Owner_filter, $poolsWhere2: OwnerPool_filter) {
    owners(where: $where) {
      pools(where: $poolsWhere2) {
        deposited
        id
        owner {
          id
        }
        total
        withdrawn
      }
    }
  }
`;

export const getOwnerPools = gql`
  query ownerPools($where: OwnerPool_filter) {
    ownerPools(where: $where) {
      id
      pool {
        id
      }
    }
  }
`;
