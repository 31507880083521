import { FC } from "react";
import { useSelector } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";
import { Tooltip } from "@mui/material";
import { useAccount, useDisconnect } from "wagmi";
import S from "./header.styled";
import { disconnectWallet } from "../../../store/slices/wallet.actions";
import { useEnteredDraw } from "../../../utils/hooks/selector";
import { useAppDispatch } from "../../../utils/hooks/state";
import ConnectButton from "../../connect/ConnectButton";
import MyEarnings from "./MyEarnings";
import MyUSDTBalance from "./MyUSDTBalance";

const HeaderRightContainer: FC = () => {
  //constructors
  const dispatch = useAppDispatch();

  //constants
  const { isConnected: isWalletConnected } = useAccount();
  const { disconnect } = useDisconnect();

  //state values
  const enteredDraw = useSelector(useEnteredDraw);

  return (
    <S.HeaderRightContainer>
      {enteredDraw && <MyEarnings />}
      {isWalletConnected && <MyUSDTBalance />}
      {isWalletConnected ? (
        <Tooltip title="Disconnect" arrow>
          <S.LogoutButton
            onClick={() => {
              dispatch(disconnectWallet());
              disconnect();
            }}
          >
            <LogoutIcon />
          </S.LogoutButton>
        </Tooltip>
      ) : (
        <ConnectButton />
      )}
    </S.HeaderRightContainer>
  );
};

export default HeaderRightContainer;
