import { FC } from "react";
import { CSSProperties } from "@mui/styled-engine";
import { S } from "./loader.styled";

interface ICircleLoaderProps {
  size: number;
  type?: string;
}

const CircularLoader: FC<ICircleLoaderProps> = ({ size, type }) => {
  return <S.CircleLoader size={size} type={type} />;
};

export default CircularLoader;
