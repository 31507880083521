import SensorsIcon from "@mui/icons-material/Sensors";
import { Box, Stack, styled, Typography } from "@mui/material";

namespace S {
  export const CardWrapper = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    borderRadius: "0.625rem",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.custom.secondary.secondary_3,
  }));

  export const TicketSold = styled(Stack)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(2),
  }));

  export const HeaderAlignment = styled("span")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(2),
  }));

  export const HeadingText = styled(Typography)(({ theme }) => ({
    fontSize: "0.75rem",
    fontWeight: 600,
    color: theme.palette.custom.secondary.secondary_1,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.625rem",
    },
  }));

  export const LiveContainer = styled(Box)(({ theme }) => ({
    height: "16px",
    width: "3rem",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    background: theme.palette.custom.bg.live,
    borderRadius: "0.25rem",
    [theme.breakpoints.down("md")]: {
      height: " 0.9375rem",
      width: "2.8125rem",
    },
  }));

  export const LiveText = styled(Typography)(({ theme }) => ({
    color: theme.palette.custom.secondary.secondary_1,
    fontWeight: "600",
    fontSize: "0.625rem",
    lineHeight: "1",
  }));

  export const CustomSensorsIcon = styled(SensorsIcon)(() => ({
    fontSize: "0.75rem",
    animation: "spin 2s linear infinite",
    "@keyframes spin": {
      "0%": {
        transform: "rotate(0deg)",
      },
      "100%": {
        transform: "rotate(360deg)",
      },
    },
  }));

  const changeFlipValueColor = (length: number | undefined, color: string) => {
    let css: { [key: string]: {} } = { gap: "2.5px" };
    for (var i = 6; i > 6 - (length as number); i--) {
      css[`span:nth-of-type(${i})`] = {
        span: {
          span: {
            color: `${color} !important`,
          },
        },
      };
    }
    return css;
  };

  export const FlipCounterWrapper = styled(Box, {
    shouldForwardProp: (prop) => prop !== "countLength",
  })(({ countLength }: { countLength?: number }) => ({ theme }) => ({
    display: "flex",
    section: changeFlipValueColor(countLength, theme.palette.custom.accent.accent_2),
    [theme.breakpoints.down("md")]: {
      section: {
        width: "100%",
        justifyContent: "space-between !important",
        span: {
          width: "1.9rem !important",
          height: "2.5rem !important",
          textAlign: "center",
          span: {
            span: {
              textAlign: "center",
              fontSize: "1.7rem !important",
            },
          },
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      section: {
        gap: "0.125rem",
        span: {
          width: "1.4rem !important",
          height: "2.1rem !important",
          span: {
            span: {
              fontSize: "1.1rem !important",
            },
          },
        },
      },
    },
  }));
}

export default S;
