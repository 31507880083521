import { CSSProperties } from "react";
import { Box, styled } from "@mui/material";

namespace S {
  export const ContentContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== "customStyle",
  })(({ customStyle }: { customStyle?: CSSProperties }) => ({ theme }) => ({
    overflowY: "auto",
    padding: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      overflowX: "hidden",
    },
    ...customStyle,
  }));
}

export default S;
