import ReplyIcon from "@mui/icons-material/Reply";
import { Stack, styled, Typography, Box } from "@mui/material";

namespace S {
  export const DetailsContainer = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    minWidth: "1100px",
    maxWidth: "1100px",
    minHeight: "100%",
    maxHeight: "100%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      minWidth: "100%",
      minHeight: "fit-content",
      maxHeight: "fit-content",
    },
  }));

  export const DetailsHeader = styled(Stack)(({ theme }) => ({
    minWidth: "1100px",
    maxWidth: "1100px",
    minHeight: "71px",
    maxHeight: "71px",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    color: "rgba(255, 255, 255, 0.4)",
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      minWidth: "100%",
    },
  }));

  export const DetailsHeaderText = styled(Typography)(() => ({
    color: "rgba(255, 255, 255, 0.4)",
    fontWeight: "600",
    fontSize: "14px",
  }));

  export const SearchIcon = styled("img")({
    width: "1.2rem",
    height: "1.2rem",
  });

  export const SearchContainer = styled(Stack)({
    alignItems: "center",
    gap: "1rem",
  });

  export const DetailsContent = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    minWidth: "1100px",
    maxWidth: "1100px",
    minHeight: "279px",
    maxHeight: "279px",
    alignItems: "center",
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.custom.strokes.primary}`,
    backgroundColor: "rgba(22, 21, 26, 0.14)",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      minHeight: "fit-content",
      maxHeight: "fit-content",
      minWidth: "100%",
      maxWidth: "100%",
    },
    position: "relative",
  }));

  export const LoaderContainer = styled("span")(() => ({
    margin: "auto",
  }));

  export const ContentHeader = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-evenly",
      flexWrap: "wrap",
      gap: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  }));

  export const TextBox = styled(Stack, { shouldForwardProp: (prop) => prop !== "isMyTicket" })(({ isMyTicket }: { isMyTicket: boolean }) => ({
    flexDirection: "row",
    lineHeight: 1,
    alignItems: "center",
    minWidth: isMyTicket ? "auto" : "270px",
    button: {
      svg: {
        width: "0.9rem",
        height: "0.9rem",
      },
    },
  }));

  export const PrimaryText = styled(Typography)(({ theme }) => ({
    fontSize: "18px",
    fontWeight: "600",
    color: theme.palette.custom.secondary.secondary_1,
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  }));

  export const GradientText = styled(Typography)(({ theme }) => ({
    fontSize: "18px",
    fontWeight: "600",
    background: "linear-gradient( #E9B80C 9.43%, #EB00FF 75%)",
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  }));

  export const YellowText = styled(Stack)(({ theme }) => ({
    fontSize: "18px",
    fontWeight: "600",
    color: theme.palette.custom.accent.accent_2,
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  }));

  export const ParticipantsImg = styled("img")(({ theme }) => ({
    width: "32px",
    height: "32px",
    [theme.breakpoints.down("sm")]: {
      width: "20px",
      height: "20px",
    },
  }));

  export const GreenText = styled(Typography)(({ theme }) => ({
    fontSize: "18px",
    fontWeight: "600",
    color: theme.palette.custom.accent.accent_1,
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  }));

  export const ContentContainer = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    width: "100%",
    height: "180px",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      height: "fit-content",
    },
  }));

  export const ContentLeft = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    // width: "851px",
    flex: 2.1,
    height: "180px",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }));

  export const ContentTitle = styled(Stack)(() => ({
    width: "100%",
    height: "30px",
    flexDirection: "row",
  }));

  export const Contents = styled(Stack)(() => ({
    boxSizing: "border-box",
    width: "100%",
    height: "150px",
    overflowY: "auto",
    gap: "0.2rem",
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#3E3E3E",
    },
  }));

  export const AddressHeadingText = styled(Typography)(({ theme }) => ({
    width: "54%",
    color: "rgba(255, 255, 255, 0.4)",
    fontWeight: "600",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "40%",
    },
  }));

  export const AmountHeadingText = styled(AddressHeadingText)(({ theme }) => ({
    width: "24%",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      width: "30%",
    },
  }));

  export const ContentRow = styled(Stack)(() => ({
    width: "100%",
    minHeight: "30px",
    flexDirection: "row",
    alignItems: "center",
  }));

  export const AddressText = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "yourTicket",
  })(({ yourTicket }: { yourTicket?: Boolean }) => ({ theme }) => ({
    width: "54%",
    height: "fit-content",
    fontWeight: "400",
    fontSize: "12px",
    alignSelf: "center",
    lineHeight: "1 !important",
    color: yourTicket ? theme.palette.custom.accent.accent_1 : theme.palette.custom.secondary.secondary_1,
    [theme.breakpoints.down("sm")]: {
      width: "40%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "clip",
    },
  }));

  export const AmountText = styled(AddressText, {
    shouldForwardProp: (prop) => prop !== "yourTicket",
  })(({ yourTicket }: { yourTicket?: Boolean }) => ({ theme }) => ({
    boxSizing: "border-box",
    width: "24%",
    fontWeight: "600",
    color: yourTicket ? theme.palette.custom.accent.accent_1 : theme.palette.custom.secondary.secondary_1,
    paddingRight: theme.spacing(3.8),
    letterSpacing: "1px",
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      width: "30%",
    },
  }));

  export const ContentRight = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    flex: 1.2,
    height: "180px",
    alignItems: "center",
    justifyContent: "space-around",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      alignSelf: "center",
    },
    ".MuiBox-root": {
      borderRadius: "10px !important",
    },
  }));

  export const StampWrapper = styled(Box, { shouldForwardProp: (prop) => prop !== "isMyTicket" })(
    ({ isMyTicket = false }: { isMyTicket: boolean }) =>
      () => ({
        height: isMyTicket ? "9.375rem" : "11.25rem",
        width: isMyTicket ? "9.375rem" : "11.25rem",
      }),
  );

  export const CustomTenKStamp = styled("img", { shouldForwardProp: (prop) => prop !== "isMyTicket" })(
    ({ isMyTicket = false }: { isMyTicket: boolean }) => ({
      width: isMyTicket ? "9.375rem" : "11.25rem",
      height: "auto",
    }),
  );

  export const BlueText = styled(Typography)(({ theme }) => ({
    fontSize: "18px",
    fontWeight: "600",
    color: theme.palette.custom.primary.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
    a: {
      color: theme.palette.custom.primary.main,
      textDecoration: "none",
    },
  }));

  export const UnclaimedContainer = styled(Stack)(({ theme }) => ({
    gap: theme.spacing(2),
    alignItems: "center",
  }));

  export const UnclaimedText = styled(Typography)(({ theme }) => ({
    fontWeight: 400,
    span: {
      color: theme.palette.custom.accent.accent_1,
    },
  }));

  export const CustomReplyIcon = styled(ReplyIcon)(() => ({
    transform: "scaleX(-1)",
    fontSize: "1.5rem",
  }));

  export const NoParticipantText = styled(Typography)(({ theme }) => ({
    position: "absolute",
    top: "40%",
    fontSize: "1rem",
    margin: "1rem 0",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      lineHeight: 0.5,
    },
  }));
}

export default S;
