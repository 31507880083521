import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type DrawState = {
  draw1kNumber: number;
  draw10kNumber: number;
  draw100kNumber: number;
  drawAmount: number;
  drawFee: number;
  maxTickets: number;
  isDrawReady: boolean;
};

const initialState: DrawState = {
  draw1kNumber: 1000,
  draw10kNumber: 10000,
  draw100kNumber: 100000,
  drawAmount: 55,
  drawFee: 5,
  maxTickets: 100,
  isDrawReady: false,
};

const drawSlice = createSlice({
  name: "draw",
  initialState,
  reducers: {
    setDrawState: (state, action: PayloadAction<DrawState>) => {
      state.draw1kNumber = action.payload.draw1kNumber;
      state.draw10kNumber = action.payload.draw10kNumber;
      state.draw100kNumber = action.payload.draw100kNumber;
      state.drawAmount = action.payload.drawAmount;
      state.drawFee = action.payload.drawFee;
      state.maxTickets = action.payload.maxTickets;
      state.isDrawReady = action.payload.isDrawReady;
    },
  },
});

export const { setDrawState } = drawSlice.actions;

export default drawSlice.reducer;
