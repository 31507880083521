import { Stack, styled } from "@mui/material";

namespace S {
  export const PoolManageMentWrapper = styled(Stack)(({ theme }) => ({
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    height: "calc(100vh - 5rem)",
    backgroundColor: theme.palette.custom.bg.app,
  }));

  export const PoolManagementAlignMentContainer = styled(Stack)(() => ({
    maxWidth: "1440px",
    width: "100%",
    height: "fit-content",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "20px",
  }));

  export const PoolCarouselContainer = styled(Stack)(({ theme }) => ({
    width: "100%",
    minHeight: "458px",
    maxHeight: "458px",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      paddingBottom: theme.spacing(20),
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "565px",
      maxHeight: "565px",
    },
  }));
}

export default S;
