import { CSSProperties } from "react";
import { Box, IconButton, Stack, styled, Typography } from "@mui/material";

export namespace SInvitedByPopover {
  export const Wrapper = styled(Stack)(({ theme }) => ({
    gap: theme.spacing(4),
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.custom.secondary.secondary_3,
  }));

  export const Header = styled(Box, {
    shouldForwardProp: (prop) => prop !== "customStyle",
  })(({ customStyle }: { customStyle: CSSProperties }) => ({ theme }) => ({
    display: "flex",
    gap: theme.spacing(7),
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.custom.strokes.primary}`,
    ...customStyle,
    [theme.breakpoints.down("sm")]: {
      minWidth: "320px !important",
      display: "flex",
      gap: theme.spacing(2),
      justifyContent: "flex-start",
      padding: theme.spacing(2),
    },
  }));

  export const Body = styled(Stack, {
    shouldForwardProp: (prop) => prop !== "customStyle",
  })(({ customStyle }: { customStyle: CSSProperties }) => ({ theme }) => ({
    gap: theme.spacing(4),
    padding: "0 1.25rem 1.25rem 1.25rem",
    "img+p": {
      fontWeight: "bold",
    },
    ...customStyle,
  }));

  export const Highlight = styled(Typography)(({ theme }) => ({
    fontWeight: "bold",
    color: theme.palette.custom.accent.accent_2,
  }));

  export const UserAddress = styled(Typography)(({ theme }) => ({
    textOverflow: "ellipsis",
    overflow: "hidden",
    color: theme.palette.custom.primary.main,
  }));
}

export namespace SReferPopover {
  export const Wrapper = styled(SInvitedByPopover.Wrapper)(({ theme }) => ({
    padding: theme.spacing(4),
  }));

  export const Highlight = SInvitedByPopover.Highlight;
}

export namespace SMenuPopover {
  export const Wrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    borderRadius: "0.625rem",
    cursor: "pointer",
    padding: "2rem 1rem",
    gap: "1.5rem",
    alignItems: "center",
    backgroundColor: theme.palette.custom.secondary.secondary_3,
  }));

  export const ReferContainer = styled(Box)(() => ({
    position: "relative",
    width: "100%",
  }));
}

export namespace SInfoPopover {
  export const PopoverText = styled(Typography)(() => ({
    cursor: "pointer",
  }));

  export const PopoverTextBox = styled(Box)(({ theme }) => ({
    padding: "0.125rem 0.5rem",
    textAlign: "justify",
    maxWidth: "10rem",
    backgroundColor: theme.palette.custom.secondary.secondary_2,
  }));
}

//Earnings popover
export namespace SMyEarningsPopover {
  export const Wrapper = styled(Box)(({ theme }) => ({
    minHeight: "17.6875rem",
    minWidth: "21.1875rem",
    maxWidth: "21.1875rem",
    boxSizing: "border-box",
    lineHeight: "1",
    backgroundColor: theme.palette.custom.secondary.secondary_3,
  }));

  export const Header = styled(Box)(({ theme }) => ({
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    borderBottom: `0.0625rem solid ${theme.palette.custom.strokes.primary}`,
  }));

  export const Body = styled(Box)(({ theme }) => ({
    padding: theme.spacing(4),
    display: "flex",
    gap: "1.4375rem",
    flexDirection: "column",
  }));

  export const Footer = styled(Box)(({ theme }) => ({
    padding: "1.625rem 1.25rem",
    borderTop: `0.0625rem solid ${theme.palette.custom.strokes.primary}`,
  }));

  export const HeaderText = styled(Typography)(() => ({
    fontSize: "1rem",
    fontWeight: "600",
  }));

  export const Text1 = styled(Typography)(() => ({
    fontWeight: "bold",
    // color: theme.palette.custom.accent.accent_2,
  }));

  export const Text2 = styled(Text1)(() => ({
    fontSize: "1rem",
  }));

  export const Text3 = styled(Typography)(({ theme }) => ({
    fontWeight: "bold",
    color: theme.palette.custom.accent.accent_1,
  }));

  export const Text4 = styled(Text3)(() => ({
    fontSize: "1.5rem",
  }));

  export const Row = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }));
}

export namespace SShareLinkPopover {
  export const Wrapper = styled(Box)(({ theme }) => ({
    minHeight: "8.313rem",
    minWidth: "22.1875rem",
    maxWidth: "22.1875rem",
    boxSizing: "border-box",
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-around",
  }));

  export const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette.custom.accent.accent_2,
  }));

  export const IconContainer = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(1),
  }));

  export const IconWrapper = styled(Box)(({ theme }) => ({
    width: "35px",
    height: "35px",
    border: `1px solid ${theme.palette.custom.secondary.secondary_1}`,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ".MuiSvgIcon-root": {
      width: "1.3rem",
      heigth: "1.3rem",
    },
  }));
}
