import { FC } from "react";
import FlipNumbers from "react-flip-numbers";
import { getWholeNumber } from "../../../utils/helpers";

type FlipCounterPropsType = {
  width?: number;
  height?: number;
  numbers?: string;
  isTimer?: boolean;
  flipNumber?: string;
};

const FlipCounter: FC<FlipCounterPropsType> = (props) => {
  //constants
  const { height = 50, width = 40, numbers = "0", isTimer = false, flipNumber } = props;

  return (
    <FlipNumbers
      height={height}
      width={width}
      color="#FFFFFF"
      play
      numbers={isTimer ? numbers : getWholeNumber(flipNumber ?? "0")}
      // numberStyles={{
      //   fontSize: "10px",
      // }}
      // nonNumberStyles={{
      //   padding: "1rem",
      // }}
      perspective={700}
    />
  );
};

export default FlipCounter;
