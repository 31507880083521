import { CSSProperties } from "react";
import { Box, styled, Divider } from "@mui/material";
import { Button, GradientButton } from "./buttons";

//triangle tilde --> use customStyle to adjust the triangle location
const Triangle = styled(Box, {
  shouldForwardProp: (prop) => prop !== "customStyle",
})(({ customStyle }: { customStyle: CSSProperties }) => ({ theme }) => ({
  width: 0,
  height: 0,
  borderLeft: "10px solid transparent",
  borderRight: "10px solid transparent",
  borderBottom: `15px solid ${theme.palette.custom.secondary.secondary_3}`,
  position: "absolute",
  ...customStyle,
}));

//In customStyles you should add width,height and position alignments
const SGradientContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "customStyle",
})(({ customStyle }: { customStyle: CSSProperties }) => () => ({
  padding: "0.625rem",
  borderRadius: "0.625rem",
  position: "relative",
  overflow: "hidden",

  "::after": {
    content: `""`,
    position: "absolute",
    zIndex: -1,
    background: "linear-gradient(0deg, #EB00FF 37%, #E9B80C 80%)",
    animation: "gradientRotate 2s infinite linear",
    ...customStyle,
    "@keyframes gradientRotate": {
      to: {
        transform: "rotate(360deg)",
      },
    },
  },
}));

const SRoundImage = styled("img")({
  borderRadius: "50%",
});

const CustomDivider = styled(Divider, {
  shouldForwardProp: (prop) => prop !== "customstyle",
})(({ customstyle }: { customstyle?: CSSProperties }) => ({ theme }) => ({
  backgroundColor: theme.palette.custom.strokes.primary,
  ...customstyle,
}));

export { Button, GradientButton, Triangle, SGradientContainer, SRoundImage, CustomDivider };
