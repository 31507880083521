import { FC, useState } from "react";
import { Typography } from "@mui/material";
import S from "./multiwin-info-card.styled";
import {
  hundredKDrawWinningAmount,
  HUNDRED_K_DRAW,
  oneKDrawWinningAmount,
  ONE_K_DRAW,
  tenKDrawWinningAmount,
  TEN_K_DRAW,
} from "../../../../utils/constants";
import InfoPopover from "../../../popovers/InfoPopover";

type MultiwinInfoCardProps = {
  winnersByTicketId: {
    isRequesting: boolean;
    payload: {
      [ticketNumber: string]: {
        [ONE_K_DRAW]: boolean;
        [TEN_K_DRAW]: boolean;
        [HUNDRED_K_DRAW]: boolean;
      };
    };
  };
  ticketNumber: string;
  isMyTicket: boolean;
};

const MultiwinInfoCard: FC<MultiwinInfoCardProps> = ({ winnersByTicketId, ticketNumber, isMyTicket }) => {
  //state values
  const [infoPopover, setInfoPopover] = useState<HTMLButtonElement | null>(null);

  //functions
  const handleInfoCard = (info: HTMLButtonElement | null) => {
    setInfoPopover(info);
    setTimeout(() => {
      setInfoPopover(null);
    }, 5000);
  };

  return (
    <S.IBtnContainer isMyTicket={isMyTicket} onClick={(e) => handleInfoCard(e.currentTarget)}>
      <Typography>i</Typography>
      <InfoPopover
        id={Boolean(infoPopover) ? "info-icon" : undefined}
        isOpen={infoPopover}
        onClose={(e) => {
          e.stopPropagation();
          handleInfoCard(null);
        }}
        customStyle={{
          left: -1.5,
          borderBottomColor: "rgba(55, 55, 59, 1)",
        }}
        customPopoverStyle={{ left: 30 }}
        children={
          <S.InfoPopoverContainer>
            {winnersByTicketId?.payload[ticketNumber]?.ONE_K_DRAW && (
              <S.PopoverRow>
                <S.DrawType>1K Draw</S.DrawType>
                <S.OneKDraw>${oneKDrawWinningAmount}</S.OneKDraw>
              </S.PopoverRow>
            )}
            {winnersByTicketId?.payload[ticketNumber]?.TEN_K_DRAW && (
              <S.PopoverRow>
                <S.DrawType>10K DRAW</S.DrawType>
                <S.TenKDraw>${tenKDrawWinningAmount}</S.TenKDraw>
              </S.PopoverRow>
            )}
            {winnersByTicketId?.payload[ticketNumber]?.HUNDRED_K_DRAW && (
              <S.PopoverRow>
                <S.DrawType>100K Draw</S.DrawType>
                <S.HundredKDraw>${hundredKDrawWinningAmount}</S.HundredKDraw>
              </S.PopoverRow>
            )}
          </S.InfoPopoverContainer>
        }
      />
    </S.IBtnContainer>
  );
};

export default MultiwinInfoCard;
