import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { useAccount } from "wagmi";
import S from "./gradient-ticket.styled";
import ParticipantsIcon from "../../../assets/images/participants.png";
import { joinModalDataObj, PRIMARY } from "../../../utils/constants";
import { convertWeiToEth, FindPercentage, getWholeNumber } from "../../../utils/helpers";
import { useDashboardPools, useDraw100kNumber, useDrawAmount, useIsDrawReady, usePoolInfo, useTicketsSold } from "../../../utils/hooks/selector";
import { UserSlicetypes } from "../../../utils/types/state";
import { Button } from "../../../utils/widgets";
import GradientContainer from "../../../utils/widgets/GradientContainer";
import DotLoader from "../../../utils/widgets/loader/DotLoader";
import JoinParticipantModal from "../../modals/join-participant-modal";
import PoolParticipantsModal from "../../modals/pool-participants-modal";

type GradientTicketProps = {
  status: string;
  poolDetails: UserSlicetypes.Pool | undefined;
  isLoading: boolean | undefined;
  poolParticipants?: UserSlicetypes.Participants[] | [];
  ownerDetails: UserSlicetypes.Participants | undefined;
};

const GradientTicket: FC<GradientTicketProps> = (props) => {
  //props
  const { status, isLoading, poolDetails, poolParticipants, ownerDetails } = props;

  //state values
  const [showLiveModal, setShowLiveModal] = useState(false);
  const [showOverModal, setShowOverModal] = useState(false);
  const [showJoinParticipantModal, setShowJoinParticipantModal] = useState(false);
  const [joinModalData, setJoinModalData] = useState(joinModalDataObj[1]);
  const dashboardPools = useSelector(useDashboardPools);
  const poolInfo = useSelector(usePoolInfo);
  const drawAmount = useSelector(useDrawAmount);
  const ticketsSold = useSelector(useTicketsSold);
  const draw100kNumber = useSelector(useDraw100kNumber);
  const isDrawReady = useSelector(useIsDrawReady);
  const { isConnected } = useAccount();

  //constants
  const hundredKReached = Boolean(isDrawReady && ticketsSold !== 0 && ticketsSold % draw100kNumber === 0);

  //functions
  const handleJoinParticipantModal = (type?: "withdraw" | "pooling") => {
    type === "withdraw" ? setJoinModalData(joinModalDataObj[0]) : setJoinModalData(joinModalDataObj[1]);
    setShowJoinParticipantModal(true);
  };

  return (
    <>
      {isLoading ? (
        <S.GradientTicketLoaderWrapper>
          <DotLoader />
        </S.GradientTicketLoaderWrapper>
      ) : status === "Over" && dashboardPools.payload.length === 1 && !dashboardPools.payload[0].joinedDraw ? (
        <S.NoDataText>No Data!</S.NoDataText>
      ) : (
        <GradientContainer customStyle={{ zIndex: "0", width: "1000px", height: "1000px", top: "-271px", left: "-268px" }} padding={"4px !important"}>
          <S.GradientTicketContainer>
            <S.TopContainer>
              <S.PoolProgressContainer>
                <S.PoolProgressRow>
                  <S.ProgressText>POOL PROGRESS</S.ProgressText>
                  <S.ProgressText>
                    {FindPercentage(
                      drawAmount,
                      Number(convertWeiToEth(`${poolDetails?.maxAmount}`)) -
                        Number(status === "Over" ? convertWeiToEth(`${poolDetails?.total}`) : poolInfo.total),
                    ).toFixed(0)}{" "}
                    %
                  </S.ProgressText>
                </S.PoolProgressRow>
                <S.PoolProgress>
                  <S.ProgressBar
                    progress={parseInt(
                      FindPercentage(
                        drawAmount,
                        Number(convertWeiToEth(`${poolDetails?.maxAmount}`)) -
                          Number(status === "Over" ? convertWeiToEth(`${poolDetails?.total}`) : poolInfo.total),
                      ).toFixed(0),
                    )}
                  />
                </S.PoolProgress>
              </S.PoolProgressContainer>
              <S.TicketInfo>
                <S.InfoColumn>
                  <S.InfoText type={""}>PARTICIPANTS</S.InfoText>
                  <S.YellowText>
                    <S.ParticipantsImg src={ParticipantsIcon} alt="participants-icon" />x{poolParticipants?.length}
                    <S.IBtnContainer
                      onClick={() => {
                        if (status === "Live") {
                          setShowLiveModal(true);
                        }
                        if (status === "Over") {
                          setShowOverModal(true);
                        }
                      }}
                    >
                      <Typography>i</Typography>
                    </S.IBtnContainer>
                  </S.YellowText>
                </S.InfoColumn>
                <S.InfoColumn>
                  <S.InfoText type={status}>ELITE PASS</S.InfoText>
                  <S.InfoGradientText type={status}>${convertWeiToEth(`${poolDetails?.maxAmount}`)}</S.InfoGradientText>
                </S.InfoColumn>
                <S.InfoColumn>
                  <S.InfoText type={status}>REMAINING</S.InfoText>
                  <S.InfoGreenText type={status}>
                    $
                    {Number(convertWeiToEth(`${poolDetails?.maxAmount}`)) -
                      Number(status === "Over" ? convertWeiToEth(`${poolDetails?.total}`) : poolInfo.total)}
                  </S.InfoGreenText>
                </S.InfoColumn>
              </S.TicketInfo>
            </S.TopContainer>
            <S.MiddleContainer>
              <S.MiddleInnerContainer>
                <S.LeftCircle />
                ---------------------------------------------
                <S.RightCircle type={status} />
              </S.MiddleInnerContainer>
            </S.MiddleContainer>
            <S.BottomContainer>
              {status !== "Over" ? (
                <S.ButtonContainer>
                  <Button
                    buttonType={PRIMARY}
                    disabled={
                      !isConnected ||
                      !ownerDetails ||
                      poolDetails?.joinedDraw ||
                      Number(convertWeiToEth(`${poolDetails?.deposited}`)) - Number(convertWeiToEth(`${poolDetails?.withdrawn}`)) === 0
                    }
                    onClick={() => handleJoinParticipantModal("withdraw")}
                    customStyle={{
                      background: "#2d2c31",
                      border: "1px solid white",
                      minWidth: "6.9375rem",
                      height: "2rem",
                      borderRadius: "0.625rem",
                      fontSize: "0.875rem",
                      "&:hover": {
                        background: "#2d2c31",
                      },
                    }}
                  >
                    Withdraw
                  </Button>

                  <Button
                    buttonType={PRIMARY}
                    disabled={!isConnected || isLoading || poolDetails?.joinedDraw || hundredKReached}
                    onClick={() => handleJoinParticipantModal()}
                    customStyle={{
                      minWidth: "6.9375rem",
                      height: "2rem",
                      borderRadius: "0.625rem",
                      fontSize: "0.875rem",
                    }}
                  >
                    Join
                  </Button>
                  <S.CancellationNote>*cancellation fee applied</S.CancellationNote>
                </S.ButtonContainer>
              ) : (
                <>
                  <S.TicketIdContainer>
                    <S.TicketText>ELITE PASS:</S.TicketText>&nbsp;&nbsp;&nbsp;
                    <S.TicketTextGradient>
                      {poolDetails?.ticketNumber?.trim().length ? `YT${getWholeNumber(`${poolDetails?.ticketNumber}`)}` : "WAITING..."}
                    </S.TicketTextGradient>
                  </S.TicketIdContainer>
                </>
              )}
            </S.BottomContainer>
          </S.GradientTicketContainer>
        </GradientContainer>
      )}
      {showLiveModal && <PoolParticipantsModal open={true} handleClose={() => setShowLiveModal(false)} poolParticipants={poolParticipants} />}
      {showOverModal && (
        <PoolParticipantsModal
          ticketId={`YT${getWholeNumber(`${poolDetails?.ticketNumber}`)}`}
          open={true}
          handleClose={() => setShowOverModal(false)}
          poolParticipants={poolParticipants}
        />
      )}
      {showJoinParticipantModal && (
        <JoinParticipantModal
          open={true}
          modalDetail={joinModalData}
          handleClose={() => setShowJoinParticipantModal(false)}
          remainingAmount={
            joinModalData.title === joinModalDataObj[0].title
              ? Number(convertWeiToEth(`${ownerDetails?.deposited}`)) - Number(convertWeiToEth(`${ownerDetails?.withdrawn}`))
              : Number(convertWeiToEth(`${poolDetails?.maxAmount}`)) -
                Number(status === "Over" ? convertWeiToEth(`${poolDetails?.total}`) : poolInfo.total)
          }
          remainingAmountWei={
            joinModalData.title === joinModalDataObj[0].title
              ? Number(`${ownerDetails?.deposited}`) - Number(`${ownerDetails?.withdrawn}`)
              : Number(`${poolDetails?.maxAmount}`) - Number(status === "Over" ? convertWeiToEth(`${poolDetails?.total}`) : poolInfo.total)
          }
        />
      )}
    </>
  );
};

export default GradientTicket;
