import { Stack, styled } from "@mui/material";

namespace S {
  export const ModalHeader = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.custom.secondary.secondary_1,
    fontSize: "22px",
    fontWeight: "600",
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      width: "94vw",
    },
  }));

  export const ModalFooter = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(6),
    paddingTop: theme.spacing(2),
    gap: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(2)} ${theme.spacing(4)} ${theme.spacing(4)}`,
    },
  }));
}
export default S;
