import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useAccount } from "wagmi";
import S from "./pool-management.styled";
import "../../assets/css/carousel.css";
import { poolContract } from "../../services/ethereum/contract/poolContract";
import { fetchOwnerPools, fetchParticipantsByPool } from "../../store/slices/pools.actions";
import { PRIMARY } from "../../utils/constants";
import { getChecksumAddress, useIsMobileView } from "../../utils/helpers";
import { useDashboardPools, useIsDrawReady, useParticipantsByPool, usePoolInfo } from "../../utils/hooks/selector";
import { useAppDispatch } from "../../utils/hooks/state";
import { UserSlicetypes } from "../../utils/types/state";
import { Button } from "../../utils/widgets";
import TicketSold from "../ticket-sold";
import PoolCard from "./pool-card";
import Tickets from "./tickets";

const PoolManageMent: FC = () => {
  //state values
  const dashboardPools = useSelector(useDashboardPools);
  const participantsByPool = useSelector(useParticipantsByPool);
  const poolInfo = useSelector(usePoolInfo);
  const isDrawReady = useSelector(useIsDrawReady);
  const [index, setIndex] = useState(1);
  const [ownerDetails, setOwnerDetails] = useState<UserSlicetypes.Participants | undefined>();

  //functions
  const getCompletedPool = () => {
    const filteredCompletedPools = dashboardPools.payload.filter((p) => p.joinedDraw);
    return filteredCompletedPools[0];
  };

  //constants
  const cards = [
    {
      id: "3",
      component: (
        <PoolCard
          status={(!dashboardPools.isRequesting && !dashboardPools.payload.length) || !isDrawReady ? "Waiting" : "Over"}
          poolCardDetails={dashboardPools.payload.length > 1 ? getCompletedPool() : dashboardPools.payload[0]}
          isLoading={dashboardPools.isRequesting}
        />
      ),
    },
    {
      id: "1",
      component: (
        <PoolCard
          status={!poolInfo.activePool || !isDrawReady || (!dashboardPools.isRequesting && !dashboardPools.payload.length) ? "Waiting" : "Live"}
          poolCardDetails={dashboardPools.payload[0]}
          isLoading={dashboardPools.isRequesting}
          ownerDetails={ownerDetails}
        />
      ),
    },
    { id: "2", component: <PoolCard status={"Waiting"} /> },
  ];
  const md = useIsMobileView("md");
  const dispatch = useAppDispatch();
  const { address, isConnected } = useAccount();
  const mod = (n: number, m: number) => {
    let result = n % m;
    // Return a positive value
    return result >= 0 ? result : result + m;
  };
  //fetch participant details based on pool status
  useEffect(() => {
    if (dashboardPools.payload.length > 0 && address) {
      if (dashboardPools.payload[0].joinedDraw) {
        dispatch(fetchOwnerPools(`${address}`));
      }
      dispatch(fetchParticipantsByPool(dashboardPools.payload[0].id));
    }
    if (dashboardPools.payload.length > 1) {
      if (!participantsByPool[dashboardPools.payload[1].id]) dispatch(fetchParticipantsByPool(dashboardPools.payload[1].id));
    }
  }, [dashboardPools, address]);

  useEffect(() => {
    if (poolInfo.activePool?.trim().length) dispatch(fetchParticipantsByPool(poolInfo.activePool));
  }, [poolInfo.total]);

  //fetch partcipant details of active pool
  useEffect(() => {
    let fetchOwner = async () => {
      if (isConnected) {
        let activePool = await poolContract.getActivePool();
        let ownerDetail = participantsByPool[activePool.toLowerCase()]?.participants.filter(
          (data: UserSlicetypes.Participants) => getChecksumAddress(data.owner.id) === getChecksumAddress(`${address}`),
        )[0];
        if (ownerDetail?.id) setOwnerDetails(ownerDetail);
        else setOwnerDetails(undefined);
      }
    };
    fetchOwner();
  }, [dashboardPools, participantsByPool]);

  return (
    <S.PoolManageMentWrapper>
      <S.PoolManagementAlignMentContainer>
        {md && <TicketSold />}
        <S.PoolCarouselContainer>
          {cards.map((item, i) => {
            const indexLeft = mod(index - 1, cards.length);
            const indexRight = mod(index + 1, cards.length);

            let className = "card";

            if (i === index) {
              className = "card card--active";
            } else if (i === indexRight) {
              className = "card card--right";
            } else if (i === indexLeft) {
              className = "card card--left";
            } else className = "card";

            return (
              <span key={item.id} className={className}>
                {item.component}
              </span>
            );
          })}

          <Button
            buttonType={PRIMARY}
            onClick={() => {
              setIndex((index + 1) % cards.length);
            }}
            disabled={false}
            customStyle={{
              minWidth: md ? "44px" : "56px",
              maxWidth: md ? "44px" : "56px",
              minHeight: md ? "44px" : "56px",
              maxHeight: md ? "44px" : "56px",
              padding: "0",
              borderRadius: "50%",
              position: "absolute",
              top: !md ? "220px" : "unset",
              left: md ? "30%" : "0",
              bottom: md ? "20px" : "unset",
              zIndex: "5",
            }}
          >
            <KeyboardArrowLeftIcon fontSize={"large"} />
          </Button>
          <Button
            buttonType={PRIMARY}
            onClick={() => {
              setIndex((index - 1) % cards.length);
            }}
            disabled={false}
            customStyle={{
              minWidth: md ? "44px" : "56px",
              maxWidth: md ? "44px" : "56px",
              minHeight: md ? "44px" : "56px",
              maxHeight: md ? "44px" : "56px",
              padding: "0",
              borderRadius: "50%",
              position: "absolute",
              top: !md ? "220px" : "unset",
              right: md ? "30%" : "0",
              bottom: md ? "20px" : "unset",
              zIndex: "5",
            }}
          >
            <KeyboardArrowRightIcon fontSize={"large"} />
          </Button>
        </S.PoolCarouselContainer>
        <Tickets />
      </S.PoolManagementAlignMentContainer>
    </S.PoolManageMentWrapper>
  );
};

export default PoolManageMent;
