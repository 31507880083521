import { FC } from "react";
import { CustomPopoverProps } from "../../utils/types";
import { Triangle } from "../../utils/widgets";
import CustomPopover from "../../utils/widgets/popover";

const InfoPopover: FC<CustomPopoverProps> = (props) => {
  const { id, isOpen, onClose, children: popoverContent, customPopoverStyle, customStyle } = props;

  return (
    <>
      {Boolean(isOpen) && <Triangle customStyle={{ top: 14, left: -3.2, borderBottomColor: "#595959", ...customStyle }} />}
      <CustomPopover id={id} isOpen={isOpen} onClose={onClose} customStyle={{ top: "10px", left: "0", ...customPopoverStyle }}>
        {popoverContent}
      </CustomPopover>
    </>
  );
};

export default InfoPopover;
