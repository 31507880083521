import { useEffect } from "react";
import { setIsSomethingWrong, setIsListeningEventstream, setEventData, setAppStatus } from "../../store/slices/user";
import { BACKEND_URL } from "../constants";
import { useAppDispatch } from "./state";

const CURRENT_CHAIN_ID: number = Number(process.env.REACT_APP_CURRENT_CHAINID);

const useEventListener = () => {
  //constructor
  const dispatch = useAppDispatch();

  //constants
  let retryCount = 1;
  let source: EventSource;

  useEffect(() => {
    let eventStreamTimeout = setTimeout(() => callEventstream(), 1500);
    return () => {
      clearTimeout(eventStreamTimeout);
      if (source) source.close();
    };
  }, []);

  const callEventstream = () => {
    source = new EventSource(`${BACKEND_URL[CURRENT_CHAIN_ID]}/events`);

    source.addEventListener("open", () => {
      console.log("SSE opened!");
    });

    source.addEventListener("message", (e) => {
      const data = JSON.parse(e.data);
      if (data.data) {
        const { ticketNumber, poolInfo, appStatus } = data.data;
        retryCount = 1;
        dispatch(setIsSomethingWrong(false));
        dispatch(setIsListeningEventstream(false));
        dispatch(setAppStatus(appStatus));
        const parsedPoolInfo = JSON.parse(poolInfo);
        dispatch(setEventData({ ticketsSold: +ticketNumber, poolInfo: parsedPoolInfo }));
        const cachedTicketNumber = localStorage.getItem("ticketNumber");
        cachedTicketNumber !== ticketNumber && localStorage.setItem("ticketNumber", ticketNumber);
      }
    });

    source.addEventListener("error", (e) => {
      console.error("Error: ", e);
      dispatch(setIsListeningEventstream(true));
      if (retryCount < 6) {
        retryCount += 1;
        setTimeout(() => callEventstream(), 1500);
      } else {
        dispatch(setIsListeningEventstream(false));
        retryCount = 1;
        dispatch(setIsSomethingWrong(true));
      }
    });
  };
};

export default useEventListener;
