import { FC } from "react";
import CloseIcon from "@mui/icons-material/Close";
import S from "./custom-modal.styled";
import { CustomModalTypes } from "../../../utils/types/custom-modal";

export const CustomModal: FC<CustomModalTypes.Container> = ({ children, open, customStyle, onClose, isLoading }) => {
  const handleClose = (reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick" && !isLoading) {
      if (onClose) onClose();
    }
  };

  return (
    <S.ModalContainer open={open} sx={customStyle} onClose={handleClose}>
      {children}
    </S.ModalContainer>
  );
};

export const ModalHeader: FC<CustomModalTypes.Header> = ({ children, handleClose, isTransparent = false, disableCloseIcon = false }) => {
  return (
    <S.ModalHeaderContainer isTransparent={isTransparent}>
      {!disableCloseIcon && (
        <S.CustomcloseButton onClick={handleClose}>
          <CloseIcon />
        </S.CustomcloseButton>
      )}
      {children}
    </S.ModalHeaderContainer>
  );
};

export const ModalBody: FC<CustomModalTypes.Body> = ({ children, isTransparent = false, customStyle }) => {
  return (
    <S.ModalBodyContainer isTransparent={isTransparent} sx={customStyle}>
      {children}
    </S.ModalBodyContainer>
  );
};

export const ModalFooter: FC<CustomModalTypes.Footer> = ({ children, isTransparent = false }) => {
  return <S.ModalFooterContainer isTransparent={isTransparent}>{children}</S.ModalFooterContainer>;
};
