import { Box, Stack, styled, Typography } from "@mui/material";

namespace S {
  export const JoinParticipantModalBody = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    padding: `${theme.spacing(3)} ${theme.spacing(8)} ${theme.spacing(4)}`,
    gap: theme.spacing(4),
    justifyContent: "space-between",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(3)} ${theme.spacing(4)} ${theme.spacing(4)}`,
    },
  }));

  export const AmountContainer = styled(Stack)(() => ({
    widht: "100%",
    height: "44px",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  }));

  export const AmountBox = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    minHeight: "44px",
    maxHeight: "44px",
    width: "calc(100% - 99px)",
    flexDirection: "row",
    borderRadius: "8px",
    background: theme.palette.custom.secondary.secondary_3,
    border: `2px solid ${theme.palette.custom.primary.main}`,
    justifyContent: "space-between",
    alignItems: "center",
    padding: `0 ${theme.spacing(4)}`,
  }));

  export const AmountInput = styled("input")(({ theme }) => ({
    border: "none",
    outline: "none",
    background: "transparent",
    fontSize: "16px",
    fontWeight: "600",
    color: theme.palette.custom.secondary.secondary_1,
    width: "70%",

    "&::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
  }));

  export const AdornmentsWrapper = styled(Stack)(() => ({
    gap: "1rem",
    alignItems: "center",
  }));

  export const AmountTypeText = styled(Typography)(({ theme }) => ({
    fontSize: "12px",
    fontWeight: "500",
    color: theme.palette.custom.secondary.secondary_1,
    opacity: "0.4",
  }));

  export const MaxWrapper = styled(Box, {
    shouldForwardProp: (prop) => prop !== "selected",
  })(({ selected }: { selected?: boolean }) => ({ theme }) => ({
    border: selected ? `1px solid ${theme.palette.custom.secondary.secondary_2}` : `1px solid ${theme.palette.custom.secondary.secondary_1}`,
    borderRadius: "0.75rem",
    padding: "0.1rem 0.75rem",
    cursor: selected ? "not-allowed" : "pointer",
    pointerEvents: selected ? "none" : "auto",
  }));

  export const AmountMaxText = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "selected",
  })(({ selected }: { selected?: boolean }) => ({ theme }) => ({
    fontSize: "12px",
    fontWeight: "500",
    color: theme.palette.custom.secondary.secondary_1,
    opacity: selected ? 0.4 : 0.8,
    cursor: selected ? "not-allowed" : "pointer",
    pointerEvents: selected ? "none" : "auto",
  }));

  export const Mathbutton = styled(Box, {
    shouldForwardProp: (prop) => prop !== "select",
  })(({ select }: { select?: Boolean }) => ({ theme }) => ({
    color: select ? "rgba(255, 255, 255, 0.6)" : theme.palette.custom.secondary.secondary_1,
    fontSize: "26px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: select ? "not-allowed" : "pointer",
    pointerEvents: select ? "none" : "auto",
    userSelect: "none",
  }));

  export const ChanceOfWinning = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    minHeight: "241px",
    maxHeight: "241px",
    borderRadius: "10px",
    background: "rgba(22, 21, 26, 0.14)",
    border: `1px solid ${theme.palette.custom.strokes.primary}`,
    justifyContent: "space-between",
    alignItems: "center",
    padding: `${theme.spacing(3)} ${theme.spacing(6)}`,
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
    },
  }));

  export const Row = styled(Stack)(() => ({
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  }));

  export const HeadingText = styled(Typography)(({ theme }) => ({
    color: theme.palette.custom.secondary.secondary_1,
    fontSize: "12px",
    fontWeight: "500",
    marginBottom: "5px",
  }));

  export const BoldHeadingText = styled("span")(({ theme }) => ({
    color: theme.palette.custom.secondary.secondary_1,
    fontSize: "18px",
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  }));

  export const BoldGreenText = styled(BoldHeadingText)(({ theme }) => ({
    color: theme.palette.custom.accent.accent_1,
  }));

  export const LightText = styled(Typography)(() => ({
    color: "rgba(255, 255, 255, 0.6)",
    fontSize: "12px",
    fontWeight: "400",
  }));

  export const MiniText = styled(Typography)(() => ({
    fontSize: "10px",
    fontWeight: "400",
    color: "rgba(255, 255, 255, 0.6)",
    lineHeight: "0.5",
  }));

  export const MiniGreenText = styled(MiniText)(({ theme }) => ({
    color: theme.palette.custom.accent.accent_1,
  }));

  export const AmountExceededWarningText = styled(MiniText, { shouldForwardProp: (prop) => prop !== "isVisible" })(
    ({ isVisible }: { isVisible: boolean }) =>
      () => ({
        color: "red",
        visibility: isVisible ? "visible" : "hidden",
      }),
  );

  export const MaxAmountWarningText = styled(MiniText, { shouldForwardProp: (prop) => prop !== "isVisible" })(
    ({ isVisible }: { isVisible: boolean }) =>
      ({ theme }) => ({
        color: "#3ab23a",
        visibility: isVisible ? "visible" : "hidden",
      }),
  );
}
export default S;
