import { CSSProperties } from "react";
import { Box, styled, CircularProgress } from "@mui/material";
import { SECONDARY } from "../../constants";

export namespace S {
  export const ButtonLoader = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "4.0625rem",
  }));

  export const DotLoader = styled("span", {
    shouldForwardProp: (prop) => prop !== "customStyle",
  })(({ customStyle }: { customStyle?: CSSProperties }) => ({ theme }) => ({
    height: "3.4375rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    span: {
      width: "0.625rem",
      height: "0.625rem",
      margin: "0 0.1875rem",
      backgroundColor: theme.palette.custom.primary.main,
      borderRadius: "50%",
      display: "inline-block",
      animation: "DotLoadAnim 1s ease-in-out infinite",

      "@keyframes DotLoadAnim": {
        "100%": {
          opacity: 0,
          transform: "scale(0.1) translateY(3px)",
        },
      },

      "&:nth-of-type(2)": {
        width: "0.5625rem",
        height: "0.5625rem",
        animationDelay: "0.2s",
      },

      "&:nth-of-type(3)": {
        width: "0.5rem",
        height: "0.5rem",
        animationDelay: "0.4s",
      },
    },
    ...customStyle,
  }));

  export const CircleLoader = styled(CircularProgress, {
    shouldForwardProp: (prop) => prop !== "customStyle",
  })(({ type }: { type?: string }) => () => ({
    ".css-176wh8e-MuiCircularProgress-circle": {
      stroke: type === SECONDARY ? "#FFFFFF" : "#1967FC",
    },
  }));
}
