import { configureStore } from "@reduxjs/toolkit";
import drawReducer from "./slices/draw";
import { userReducer } from "./slices/user";

const store = configureStore({
  reducer: {
    user: userReducer,
    draw: drawReducer,
  },
});

// state and dispatch types
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
