import { FC } from "react";
import S from "./pool-succeed-card.styled";
import CHECKBOX from "../../../assets/images/greetings-assets/square-checkbox.svg";

const PoolSucceedCard: FC = () => {
  return (
    <S.PoolCardContainer>
      <S.PoolHeader>
        <S.PoolCardTitle>Thanks for your Pool Participation</S.PoolCardTitle>
        {/* <img src={OpenLinkImg} alt="" /> */}
      </S.PoolHeader>
      <S.ThanksCardsPointWrapper>
        <S.ThanksCardTitle alignSelf={"flex-start"}>What happens next?</S.ThanksCardTitle>
        <S.ThanksCardPointsContainer>
          <S.CheckBoxImage src={CHECKBOX} alt="square-checkbox" />
          <S.ThanksCardContent>
            Once your contributed Elite Pass reaches <br />
            <span id="special-text">55 USDT</span>, you will receive your own Elite Pass.
          </S.ThanksCardContent>
        </S.ThanksCardPointsContainer>
        <S.ThanksCardPointsContainer>
          <S.CheckBoxImage src={CHECKBOX} alt="square-checkbox" />
          <S.ThanksCardContent>If your contributed Elite Pass wins, you'll receive rewards based on your contribution.</S.ThanksCardContent>
        </S.ThanksCardPointsContainer>
        <S.ThanksCardPointsContainer>
          <S.CheckBoxImage src={CHECKBOX} alt="square-checkbox" />
          <S.ThanksCardContent>Earn rewards by referring your contributed Elite Pass.</S.ThanksCardContent>
        </S.ThanksCardPointsContainer>
        <S.UpgradeTextWrapper>
          <S.UpgradeTextContainer>
            <S.ThanksCardContent sx={{ fontSize: "0.9rem" }}>
              *Consider participating in more pools to increase your token earnings.
            </S.ThanksCardContent>
          </S.UpgradeTextContainer>
        </S.UpgradeTextWrapper>
      </S.ThanksCardsPointWrapper>
    </S.PoolCardContainer>
  );
};

export default PoolSucceedCard;
