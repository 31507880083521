import { ToastContainer } from "react-toastify";
import { styled } from "@mui/material";
import { darkTheme } from "../../theme";

namespace S {
  export const Toast = styled("div")(() => ({
    fontWeight: "600",
    letterSpacing: "0.5px",
  }));

  export const ToastContainerStyled = styled(ToastContainer)(() => ({
    ".Toastify__toast": {
      color: darkTheme.palette.custom.secondary.secondary_1,

      ".Toastify__close-button": {
        color: darkTheme.palette.custom.secondary.secondary_1,
      },

      ".Toastify__toast-icon > svg": {
        fill: darkTheme.palette.custom.secondary.secondary_1,
      },

      "&--success": {
        backgroundColor: darkTheme.palette.custom.toast.success,
      },
      "&--error": {
        backgroundColor: darkTheme.palette.custom.toast.error,
      },
      "&--warning": {
        backgroundColor: darkTheme.palette.custom.toast.warning,
      },
      "&--info": {
        "&, .Toastify__close-button": {
          backgroundColor: darkTheme.palette.custom.toast.info,
        },

        ".Toastify__toast-icon > svg": {
          fill: darkTheme.palette.custom.secondary.secondary_1,
        },
      },
      "&, .Toastify__close-button": {
        opacity: "1 !important",
      },
    },
  }));
}

export default S;
