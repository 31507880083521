import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import { Stack } from "@mui/material";
import { Tooltip } from "@mui/material";
import { Box } from "@mui/material";
import moment from "moment";
import { useAccount, useSigner } from "wagmi";
import S from "./details-container.styled";
import HUNDRED_K_WINNER_STAMP from "../../../../assets/images/hundred_k_winner_stamp.svg";
import MISSED_STAMP from "../../../../assets/images/missed_stamp.svg";
import MULTI_WIN_STAMP from "../../../../assets/images/multi_win_stamp.svg";
import ONE_K_WINNER_STAMP from "../../../../assets/images/one_k_winner_stamp.svg";
import ParticipantsIcon from "../../../../assets/images/participants.png";
import SEARCH_ICON from "../../../../assets/images/search.svg";
import TEN_K_WINNER_STAMP from "../../../../assets/images/ten_k_winner_stamp.svg";
import { poolToEarnContract } from "../../../../services/ethereum/contract/poolToEarnContract";
import { lottoContract } from "../../../../services/ethereum/contract/web3Contract";
import { verifyTicketInDraw } from "../../../../store/slices/pools.actions";
import { EXPLORER_URL, GRADIENT, PRIMARY } from "../../../../utils/constants";
import {
  getRoundAmount,
  getWholeNumber,
  isMyself,
  useIsMobileView,
  getEllipsisString,
  convertWeiToEth,
  getChecksumAddress,
} from "../../../../utils/helpers";
import {
  useCompletedPoolTickets,
  useParticipantsByPool,
  useSelectedMyPool,
  useSelectedPool,
  useTicketHeader,
  useWinnersByTicketId,
} from "../../../../utils/hooks/selector";
import { TicketHeaderType } from "../../../../utils/types";
import { Button, GradientButton } from "../../../../utils/widgets";
import DotLoader from "../../../../utils/widgets/loader/DotLoader";
import SearchBar from "../../../../utils/widgets/search-bar";
import ReferPopover from "../../../popovers/ReferPopover";
import MultiwinInfoCard from "../multiwin-info-card";

type DetailsContainerProps = {
  searchValue: string;
  handleSearchFilter: (e: string) => void;
};

const DetailsContainer: FC<DetailsContainerProps> = ({ searchValue, handleSearchFilter }) => {
  //constructors
  const sm = useIsMobileView("sm");
  const { address } = useAccount();
  const { data: signer } = useSigner();

  //state values
  const [referPopover, setReferPopover] = useState<HTMLButtonElement | null>(null);
  const [toolTipTime, setToolTipTime] = useState(false);
  const [claimAmount, setClaimAmount] = useState(0);
  const [isClaiming, setIsClaiming] = useState(false);
  const [ticketInOneKDraw, setTicketInOneKDraw] = useState(false);
  const selectedPool = useSelector(useSelectedPool);
  const selectedMyPool = useSelector(useSelectedMyPool);
  const participantsByPool = useSelector(useParticipantsByPool);
  const isCompletedPoolRequesting = useSelector(useCompletedPoolTickets).isRequesting;
  const ticketHeader = useSelector(useTicketHeader);
  const winnersByTicketId = useSelector(useWinnersByTicketId);

  const { poolAddress, ticketNumber, blockTimestamp } = ticketHeader === TicketHeaderType.history ? selectedPool : selectedMyPool;
  const created = blockTimestamp ? moment(parseInt(blockTimestamp) * 1000).format("DD MMM YYYY") : "";

  useEffect(() => {
    if (poolAddress.trim().length && address) fetchClaimAmount();
  }, [poolAddress, address]);

  useEffect(() => {
    let checkTicketInDraw = async () => {
      let currDraw = await lottoContract.currentDraw();
      let checkTicket = verifyTicketInDraw(ticketNumber, currDraw);
      setTicketInOneKDraw(checkTicket);
    };

    if (ticketNumber.trim().length) {
      checkTicketInDraw();
    }
  }, [ticketNumber]);

  //constants
  const isMyTicket = ticketHeader === TicketHeaderType.myElitepass;
  const explorerUrl = EXPLORER_URL[process.env.REACT_APP_CURRENT_CHAINID as string];

  //functions
  const fetchClaimAmount = async () => {
    let claimAmount = await poolToEarnContract.getUnclaimedAmount(`${address}`, poolAddress, signer?.provider);
    let claimAmountETH = convertWeiToEth(claimAmount);
    setClaimAmount(Number(claimAmountETH));
  };

  const handleCopyIcon = () => {
    if (poolAddress.length) {
      setToolTipTime(true);
      navigator.clipboard.writeText(poolAddress);
      setTimeout(() => {
        setToolTipTime(false);
      }, 3000);
    }
  };

  const handleShareIcon = (event: React.MouseEvent<HTMLButtonElement>) => {
    setReferPopover(event.currentTarget);
  };

  const handleClaimRewards = () => {
    if (!isClaiming) {
      setIsClaiming(true);
      poolToEarnContract.claimAmount(
        `${address}`,
        poolAddress,
        signer?.provider,
        () => setIsClaiming(false),
        () => fetchClaimAmount(),
      );
    }
  };

  const getWinnerStamp = () => {
    if (!winnersByTicketId.isRequesting && winnersByTicketId.payload[ticketNumber] !== undefined) {
      if (
        winnersByTicketId?.payload[ticketNumber].ONE_K_DRAW &&
        winnersByTicketId?.payload[ticketNumber].TEN_K_DRAW &&
        winnersByTicketId?.payload[ticketNumber].HUNDRED_K_DRAW
      ) {
        return <S.CustomTenKStamp src={MULTI_WIN_STAMP} isMyTicket={isMyTicket} alt="multi-win-stamp" />;
      } else if (
        (winnersByTicketId?.payload[ticketNumber].ONE_K_DRAW && winnersByTicketId?.payload[ticketNumber].TEN_K_DRAW) ||
        (winnersByTicketId?.payload[ticketNumber].TEN_K_DRAW && winnersByTicketId?.payload[ticketNumber].HUNDRED_K_DRAW) ||
        (winnersByTicketId?.payload[ticketNumber].HUNDRED_K_DRAW && winnersByTicketId?.payload[ticketNumber].ONE_K_DRAW)
      ) {
        return <S.CustomTenKStamp src={MULTI_WIN_STAMP} isMyTicket={isMyTicket} alt="multi-win-stamp" />;
      } else if (
        winnersByTicketId?.payload[ticketNumber].ONE_K_DRAW &&
        !winnersByTicketId?.payload[ticketNumber].HUNDRED_K_DRAW &&
        !winnersByTicketId?.payload[ticketNumber].TEN_K_DRAW
      ) {
        return <S.CustomTenKStamp src={ONE_K_WINNER_STAMP} isMyTicket={isMyTicket} alt="one-K-stamp" />;
      } else if (
        !winnersByTicketId?.payload[ticketNumber].ONE_K_DRAW &&
        winnersByTicketId?.payload[ticketNumber].TEN_K_DRAW &&
        !winnersByTicketId?.payload[ticketNumber].HUNDRED_K_DRAW
      ) {
        return <S.CustomTenKStamp src={TEN_K_WINNER_STAMP} isMyTicket={isMyTicket} alt="ten-K-stamp" />;
      } else if (
        !winnersByTicketId?.payload[ticketNumber].ONE_K_DRAW &&
        !winnersByTicketId?.payload[ticketNumber].TEN_K_DRAW &&
        winnersByTicketId?.payload[ticketNumber].HUNDRED_K_DRAW
      ) {
        return <S.CustomTenKStamp src={HUNDRED_K_WINNER_STAMP} isMyTicket={isMyTicket} alt="hundred-K-stamp" />;
      } else {
        if (ticketInOneKDraw) return "";
        else return <S.CustomTenKStamp src={MISSED_STAMP} isMyTicket={isMyTicket} alt="missed-stamp" />;
      }
    } else return "";
  };

  return (
    <S.DetailsContainer>
      <S.DetailsHeader>
        <S.DetailsHeaderText>DETAILS</S.DetailsHeaderText>
        {!sm && (
          <S.SearchContainer direction={"row"}>
            <S.SearchIcon src={SEARCH_ICON} alt="search-icon" />
            <SearchBar placeholder="Search Elite Pass" searchHandler={handleSearchFilter} value={searchValue} />
          </S.SearchContainer>
        )}
      </S.DetailsHeader>
      <S.DetailsContent>
        {poolAddress || participantsByPool[poolAddress]?.participants.length ? (
          <>
            <S.ContentHeader>
              <S.TextBox isMyTicket={isMyTicket}>
                <S.PrimaryText>ELITE PASS:</S.PrimaryText>&nbsp;
                <S.GradientText>
                  {isCompletedPoolRequesting ? <DotLoader customStyle={{ height: "none" }} /> : `YT${getWholeNumber(ticketNumber)}`}
                </S.GradientText>
              </S.TextBox>
              <S.TextBox isMyTicket={isMyTicket}>
                <S.PrimaryText>PARTICIPANTS :</S.PrimaryText>&nbsp;
                <S.YellowText>
                  <S.ParticipantsImg src={ParticipantsIcon} alt="participants-icon" />
                  &nbsp; x&nbsp;
                  {isCompletedPoolRequesting || participantsByPool[poolAddress]?.isRequesting ? (
                    <DotLoader customStyle={{ height: "none" }} />
                  ) : participantsByPool[poolAddress] ? (
                    participantsByPool[poolAddress].participants.length
                  ) : (
                    0
                  )}
                </S.YellowText>
              </S.TextBox>
              <S.TextBox isMyTicket={isMyTicket}>
                <S.PrimaryText>CREATED :</S.PrimaryText>&nbsp;
                <S.GreenText>{isCompletedPoolRequesting ? <DotLoader customStyle={{ height: "none" }} /> : created}</S.GreenText>
              </S.TextBox>
              <S.TextBox isMyTicket={isMyTicket}>
                <S.PrimaryText>POOL :</S.PrimaryText>&nbsp;
                <S.BlueText>
                  {isCompletedPoolRequesting ? (
                    <DotLoader customStyle={{ height: "none" }} />
                  ) : (
                    <Tooltip title="Click to explore account">
                      <a href={`${explorerUrl}/address/${poolAddress}`} target="_blank">
                        {getEllipsisString(getChecksumAddress(poolAddress), 8, 6)}
                      </a>
                    </Tooltip>
                  )}
                </S.BlueText>
                {isMyTicket && Boolean(poolAddress.length) && (
                  <Stack gap={"0.5rem"} direction={"row"}>
                    <Tooltip title={toolTipTime ? "copied to clipboard" : "copy to clipboard"}>
                      <Box>
                        <Button buttonType={PRIMARY} customStyle={{ minWidth: "24px", marginLeft: "1rem" }} onClick={handleCopyIcon}>
                          <ContentCopyRoundedIcon />
                        </Button>
                      </Box>
                    </Tooltip>
                    <Button buttonType={PRIMARY} customStyle={{ minWidth: "24px" }} onClick={(e) => handleShareIcon(e)}>
                      <S.CustomReplyIcon />
                    </Button>
                  </Stack>
                )}
              </S.TextBox>
            </S.ContentHeader>
            <S.ContentContainer>
              <S.ContentLeft>
                <S.ContentTitle>
                  <S.AddressHeadingText>ADDRESS</S.AddressHeadingText>
                  <S.AmountHeadingText>AMOUNT</S.AmountHeadingText>
                </S.ContentTitle>
                <S.Contents>
                  {poolAddress && participantsByPool[poolAddress]?.participants.length ? (
                    participantsByPool[poolAddress]?.participants.map((p) => {
                      const mySelf = isMyself(p.owner.id);
                      return (
                        <S.ContentRow key={p.id}>
                          <S.AddressText yourTicket={mySelf}>{getChecksumAddress(p.owner.id)}</S.AddressText>
                          <S.AmountText yourTicket={mySelf}>${+getRoundAmount(p.total)}</S.AmountText>
                        </S.ContentRow>
                      );
                    })
                  ) : (
                    <S.LoaderContainer>
                      {(poolAddress && participantsByPool[poolAddress]?.isRequesting) || isCompletedPoolRequesting ? (
                        <DotLoader />
                      ) : (
                        "No participants found."
                      )}
                    </S.LoaderContainer>
                  )}
                </S.Contents>
              </S.ContentLeft>
              <S.ContentRight direction={"row"}>
                <S.StampWrapper isMyTicket={isMyTicket}>
                  {getWinnerStamp()}
                  {!winnersByTicketId.isRequesting &&
                    winnersByTicketId.payload[ticketNumber] !== undefined &&
                    (winnersByTicketId?.payload[ticketNumber]?.ONE_K_DRAW ||
                      winnersByTicketId?.payload[ticketNumber]?.TEN_K_DRAW ||
                      winnersByTicketId?.payload[ticketNumber]?.HUNDRED_K_DRAW) && (
                      <MultiwinInfoCard winnersByTicketId={winnersByTicketId} ticketNumber={ticketNumber} isMyTicket={isMyTicket} />
                    )}
                </S.StampWrapper>
                {isMyTicket && (
                  <S.UnclaimedContainer>
                    <S.UnclaimedText variant="subtitle2">
                      Unclaimed : <span>${claimAmount}</span>
                    </S.UnclaimedText>
                    <GradientButton
                      buttonType={GRADIENT}
                      onClick={handleClaimRewards}
                      customStyle={{
                        height: "2rem",
                        minWidth: "148px",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                      disabled={!ticketNumber || claimAmount === 0}
                    >
                      {isClaiming ? "Claiming..." : "Claim Rewards"}
                    </GradientButton>
                  </S.UnclaimedContainer>
                )}
              </S.ContentRight>
            </S.ContentContainer>
          </>
        ) : (
          <S.NoParticipantText>No participants found.</S.NoParticipantText>
        )}
      </S.DetailsContent>
      {referPopover && (
        <ReferPopover id={Boolean(referPopover) ? "refer-by" : undefined} isOpen={referPopover} onClose={() => setReferPopover(null)} />
      )}
    </S.DetailsContainer>
  );
};

export default DetailsContainer;
