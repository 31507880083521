import { Theme, Box, styled, Typography, IconButton } from "@mui/material";

namespace S {
  export const Header = styled(Box)(({ theme }: { theme: Theme }) => ({
    display: "flex",
    justifyContent: "center",
    height: "5rem",
    padding: "0 2.5rem",
    backgroundColor: theme.palette.custom.bg.app,
    boxSizing: "border-box",
    borderBottom: `1px solid ${theme.palette.custom.secondary.secondary_3}`,
    boxShadow: "0px 4px 4px rgba(89, 89, 89, 0.05)",
    [theme.breakpoints.down("md")]: {
      padding: "0 0.5rem",
    },
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      justifyContent: "space-between",
    },
  }));

  export const HeaderMainContainer = styled(Box)(({ theme }: { theme: Theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    width: "100%",
    maxWidth: "90rem",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
    },
  }));

  export const HeaderLogo = styled("img")(({ theme }) => ({
    height: "1.5rem",
    alignSelf: "center",
    [theme.breakpoints.down("sm")]: {
      width: "5rem",
      height: "auto",
    },
  }));

  export const LogoutButton = styled(IconButton)(({ theme }) => ({
    borderRadius: "50%",
    background: theme.palette.custom.primary.main,
    height: "2rem",
    width: "2rem",
    "&:hover": {
      background: "#1846dd",
    },
    svg: {
      height: "1.125rem",
      width: "1.125rem",
    },
    [theme.breakpoints.down("sm")]: {
      height: "1.75rem",
      width: "1.75rem",
      svg: {
        height: "0.9375rem",
        width: "0.9375rem",
      },
    },
  }));

  export const HeaderRightContainer = styled(Box)(({ theme }: { theme: Theme }) => ({
    display: "flex",
    gap: "1.25rem",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      gap: "0.3rem",
    },
  }));

  export const ReferContainer = styled(Box)(() => ({
    position: "relative",
  }));

  export const MyEarningsWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: "0.3rem",
    cursor: "pointer",
    alignItems: "center",
    svg: {
      color: "#FFFFFF",
    },
    p: {
      color: "#ffffff",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.62rem",
      },
    },
  }));

  export const BalanceAmount = styled(Typography)(({ theme }: { theme: Theme }) => ({
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  }));
  export const EarningsAmount = styled(Typography)(({ theme }: { theme: Theme }) => ({
    color: `${theme.palette.custom.accent.accent_1} !important`,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  }));

  export const MyEarnedTokensWrapper = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    svg: {
      cursor: "pointer",
    },
  });

  export const MyEarnedTokensLogo = styled("img")({
    width: "14px",
    height: "14px",
  });

  export const MyEarnedTokenAmount = styled(Typography)(({ theme }) => ({
    color: theme.palette.custom.accent.accent_2,
  }));
}

export default S;
