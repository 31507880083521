import { Box, Button, styled } from "@mui/material";

namespace S {
  export const CornerImg = styled("img")(({ theme }) => ({
    position: "absolute",
    top: "-53px",
    left: "-51px",
    [theme.breakpoints.down("sm")]: {
      width: "100px",
      height: "auto",
      top: "-33px",
      left: "-26px",
    },
  }));

  export const ContentWrapper = styled(Box)(({ theme }) => ({
    height: "100%",
    display: "grid",
    gridTemplateRows: "80% 20%",
  }));

  export const GreetingsModalBody = styled(Box)(({ theme }) => ({
    padding: "3rem 2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0.75rem",
    },
  }));

  export const FooterButtonContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(3),
    borderTop: `1px solid rgba(89, 89, 89, 0.2)`,
  }));

  export const SkipButton = styled(Button)(({ theme }) => ({
    borderColor: theme.palette.custom.primary.main,
    color: theme.palette.custom.primary.main,
    textTransform: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.625rem",
    padding: "0.25rem 1.5rem",
    fontSize: "16px",
    fontWeight: "600",
    borderRadius: "0.5rem",
    //MuiButton-root
    "&:hover": {
      borderColor: theme.palette.custom.primary.main,
      color: theme.palette.custom.primary.main,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "5px 10px",
    },
  }));
}

export default S;
