import { Theme } from "@mui/material";
import { useMediaQuery, Breakpoint } from "@mui/material";
import { ethers } from "ethers";
import Web3 from "web3";
import { predictionContract } from "../../services/ethereum/contract/predictionContract";
import { web3 } from "../../services/ethereum/contract/web3";
import { lottoContract } from "../../services/ethereum/contract/web3Contract";
import store from "../../store";

export const verifyOwners = async (address: string) => {
  let isOwner = false;
  const owners = await Promise.all([lottoContract.getLottoOwner(), predictionContract.getPredictionOwner()]);
  isOwner = owners.includes(getChecksumAddress(address));

  return isOwner;
};

export const getWholeNumber = (count: string) => {
  let len = count.length;
  switch (len) {
    case 1:
      return "00000" + count;
    case 2:
      return "0000" + count;
    case 3:
      return "000" + count;
    case 4:
      return "00" + count;
    case 5:
      return "0" + count;
    default:
      return count;
  }
};

export function toFixed(num: number, fixed: number) {
  fixed = fixed || 0;
  fixed = Math.pow(10, fixed);
  return Math.floor(num * fixed) / fixed;
}

export const isMyself = (userId: string) => {
  const { userDetails } = store.getState().user;
  return userDetails.address === getChecksumAddress(userId);
};

export const getWalletBalance = async (account: string) => {
  let walletBalance = await web3.eth.getBalance(account);
  let balEther = parseFloat(web3.utils.fromWei(walletBalance, "ether")).toFixed(4);
  return balEther;
};

export const getRoundAmount = (amount: string, fixed?: number) => {
  return parseFloat(web3.utils.fromWei(amount, "ether")).toFixed(fixed ? fixed : 2);
};

export const getEllipsisString = (text: string, start: number, end: number) => {
  if (text.trim().length < start + end) return text;
  const startWith = text.slice(0, start);
  const endWith = text.slice(-end);
  return `${startWith}....${endWith}`;
};

export const getChecksumAddress = (address: string) => {
  return ethers.utils.getAddress(address);
};

export const useIsMobileView = (dim?: Breakpoint) => useMediaQuery((theme: Theme) => theme.breakpoints.down(dim ?? "sm"));

export const FindPercentage = (totalpercent = 100, currentpercent: number) => {
  const result = ((totalpercent - currentpercent) * 100) / totalpercent;
  return result;
};

export const convertWeiToEth = (weiAmount: string | undefined) => {
  if (weiAmount) return Web3.utils.fromWei(weiAmount, "ether");
  else return 0;
};
