import { Stack, styled } from "@mui/material";

namespace S {
  export const TicketsWrapper = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    width: "100%",
    maxWidth: "1440px",
    height: "422px",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      height: "unset",
    },
  }));

  export const TicketHeader = styled(Stack)(() => ({
    width: "100%",
    height: "42px",
    flexDirection: "row",
  }));

  export const HeaderTab = styled(Stack, {
    shouldForwardProp: (prop) => prop !== "active" && prop !== "disable",
  })(({ active, disable = false }: { active: Boolean; disable: Boolean }) => ({ theme }) => ({
    cursor: disable ? "not-allowed !important" : "pointer",
    width: "139px",
    height: "42px",
    borderRadius: "10px 10px 0 0",
    textAlign: "center",
    justifyContent: "center",
    fontSize: "14px",
    fontWeight: "600",
    backgroundColor: theme.palette.custom.secondary.secondary_3,
    color: theme.palette.custom.secondary.secondary_1,
    opacity: !active ? "0.7" : "1",
  }));
}

export default S;
